//import { SwitchToggle } from "../../../../Shared/Components";
//import Input from "../../../../Shared/Components/Input/Input";
// import Select from "../../../../Shared/Components/Select/Select";
// import { formatDate } from "../../../../Shared/Utilities";
// import Switch from "@mui/material/Switch";
// import { Cell } from "../../../../Shared/Components/Table/Table.Style";
// import useEsopModals from "../../useEsopModals";
// import Title from "../../../../Shared/Components/Layout/Title";
// import { ICompany } from "../../../../Models/API/Company";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { CompanyDepartment, CompanySite, EquityPlan, IEquityPlan, IVestingSchedule } from "../../../../Models/App/EquityPlans/Company";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import { Cell } from "../../../../Shared/Components/Table/Table.Style";
import useModal from "../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import useEsopModals from "../../useEsopModals";
import EntitiesFactory from "./EntitiesFactory/EntitiesFactory";
import EditEquityPlan from "./Plan/EditEquityPlan/EditEquityPlan";
import { IC_EDIT_GRAY, IC_EQUITY_SETTINGS, IC_TRASH2 } from "../../../../Assets";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import { formatDate, isNullOrUndefined } from "../../../../Shared/Utilities";
import { useHistory } from "react-router-dom";
import VestingScheduleTable from "./VestingSchedule/VestingScheduleTable";
import RadioButton from "../../../../Shared/Components/RadioButton/RadioButton";
import Login from "../../../CapTable/Components/General/OptionsSync/Pivotal/Login";
import { IPivotalCompany } from "../../../../Models/App";
import { ReqTypePivotal } from "../../../../Services/TrusteeService";
import Image from "../../../../Shared/Components/Image";
import { useEffectOnce } from "react-use";
import NewEquityPlan from "./Plan/NewEquityPlan/NewEquityPlan";

// import EquitySectionStyle from "../EquitySection.style";
import TrusteeAdditionalStyles from "../../../TrusteeEquity/Dashboard/AdditionalViews/AdditionalViews.module.css";
import NumberInput from "../../../../Shared/Components/Input/NumberInput";
import SelectDataSource from "./SelectDataSource/SelectDataSource";

interface SettingsProps {
    rtd: () => void;
}

const CompanySettings = (props: SettingsProps) => {
    const history = useHistory();
    const { showModal, removeCurrentModal } = useModal();
    const { equityPlansStore, companyStore, trusteeStore, auth, appState } = useRootStore();
    const { showDeletePlanModal, showSuccessModal, showErrorModal, showChangeDataSource } = useEsopModals();
    // const [fromTrustee, setFromTrustee] = useState<boolean>(companyStore.company.pivotalEquityPlans)

    // const [vestIds, setVestIds] = useState<{ [key: string]: boolean }>({});
    // const [render, setRender] = useState(0);

    // const setActiveIds = () => {
    // 	if (isNullOrUndefined(equityPlansStore.companyData?.vestings)) return;
    // 	let obj: { [key: string]: boolean } = {};

    // 	equityPlansStore.companyData?.vestings.forEach((vst) => {
    // 		obj[vst.vestingId] = vst.active;
    // 	});
    // 	setVestIds(obj);
    // };

    // useEffect(() => {
    // 	setActiveIds();
    // }, [equityPlansStore.companyActiveVests]);

    // const init = async () => {
    //     await equityPlansStore.GetEquitySettings();
    //     // await setActiveIds();
    //     // setRender(render + 1);
    // };

    // useEffect(() => {
    //     init();
    // }, []);

    const openPlanSettingsModal = (plan: EquityPlan) => {
        equityPlansStore.GetEquityPlan(plan.planId);
        equityPlansStore.GetPoolSteps(plan.planId);
        showModal({
            // title: "Plans Settings",
            body: <EditEquityPlan planId={plan.planId} />,
            width: "80%",
        });
        // handleModal()
    };

    // const openEditModal = () => {
    // 	showModal({
    // 		title: "Plans Settings",
    // 		body: <PlanSettings />,
    // 		height: "60%",
    // 		width: "80%",
    // 	});
    // };

    // const handleVestingModal = (vesting: IVestingSchedule) => {
    // 	showModal({
    // 		title: "Vesting Schedule",
    // 		body: <NewVestingSchedule vestingSchedule={vesting} />,
    // 		width: "60%",
    // 	});
    // };

    // const handleCompanyVestConnection = (id: number, connect: boolean) => {
    // 	if (connect) {
    // 		console.log(`connecting vest ${id}`);
    // 	} else {
    // 		console.log(`DIS-connecting vest ${id}`);
    // 	}

    // 	equityPlansStore.AddRemoveVestFromCompany(connect, id);

    // 	setVestIds((prevVal) => {
    // 		prevVal[id] = connect;
    // 		console.log(prevVal);
    // 		return prevVal;
    // 	});
    // };

    const tableColumn: TableColumn<EquityPlan>[] = [
        { label: "Plan Name", name: "name" },
        {
            label: "Approval Date",
            name: "planApprovalDateByBoard",
            format(val, obj) {
                return formatDate(val);
            },
        },
        {
            label: "RSU",
            name: "allowRsu",
            render(obj, value) {
                return value ? <Cell>V</Cell> : <></>;
            },
        },
        {
            label: "Options",
            name: "allowSop",
            render(obj, value) {
                return value ? <Cell>V</Cell> : <></>;
            },
        },
        {
            label: "",
            name: "menu",
            menuItems: [
                {
                    onClick: (pln) => showDeletePlanModal(pln.planId),
                    element: (
                        <img
                            src={IC_TRASH2}
                            alt="delete plan"
                        />
                    ),
                    qaid: "CompanySettings.Button.DeletePlan",
                },
            ],
        },
    ];

    const newPlanHandler = (data?: EquityPlan) => {
        showModal({
            body: (
                <NewEquityPlan
                    data={data}
                    onSave={async () => {
                        const res = await equityPlansStore.AddEquityPlan();
                        if (res?.data?.planId) {
                            removeCurrentModal();
                            showSuccessModal("The plan was added successfully");
                        } else {
                            console.log("plan res", res);
                            showErrorModal(res);
                        }
                    }}
                />
            ),
            width: "60%",
        });
    };

    // const registerUserToPivotal = async (rcpToken: string, cmp: IPivotalCompany): Promise<boolean> => {

    //     if (!auth.pivotalToken) {
    //         return false
    //     }

    //     const obj: ReqTypePivotal = {
    //         companyId: trusteeStore.companyId,
    //         productsSchema: 1,
    //         reCaptchaToken: rcpToken,
    //         token: auth.pivotalToken,
    //         trusteeCompanyId: cmp.companyId,
    //     };

    //     const res = await trusteeStore.SetUserCompanyToken(obj)

    //     if (res.data?.companyId) {
    //         await trusteeStore.GetPoolSummary()
    //         return true
    //     }
    //     return false
    // }

    // const pivotalRegistrationHandler = () => {
    //     // Pivotal managment API:
    //     // 1. get GUID
    //     // 2. sent OTP + GUID --> GET Token
    //     // 3. PUT Company

    //     showModal({
    //         body: <Login onCompanySelect={registerUserToPivotal} />,
    //         isMust: true,
    //     });
    // };

    // const equityTxt = 'Create & manage your data actively using Equity Plans product'
    // const trusteeTxt = 'All the equity plans data is synchronized from altshare trustee service'

    // const poolSummaryAndAutorization = async () => {
    //     const summary = await trusteeStore.GetPoolSummary();

    //     if (!summary.data) {
    //         pivotalRegistrationHandler();
    //     }
    // };

    // const equitySourceSelectorHandler = (fromPivotal: boolean) => {
    //     if (fromPivotal === companyStore.company.pivotalEquityPlans) return;

    //     showChangeDataSource(fromPivotal);

    //     if (companyStore.company.pivotalEquityPlans) {
    //         poolSummaryAndAutorization();
    //     }
    // };

    const backBtnHandler = () => {
        companyStore.company.pivotalEquityPlans ? history.push("/trustee") : history.push("/ESOP/dashboard");
    };

    // useEffectOnce(() => {
    //     if (companyStore.company.pivotalEquityPlans) {
    //         poolSummaryAndAutorization();
    //     } else {
    //         equityPlansStore.GetEquitySettings();
    //     }
    // });



    // const asyncFunc = async () => {
    //     if (companyStore.company.pivotalEquityPlans) {
    //         const summary = await trusteeStore.GetPoolSummary();

    //         if (!summary.data) {
    //             pivotalRegistrationHandler();
    //         }
    //     } else {
    //         equityPlansStore.GetEquitySettings();
    //     }
    // }

    useEffect(() => {
        companyStore.company.pivotalEquityPlans
            ? trusteeStore.GetPoolSummary()
            : equityPlansStore.GetEquitySettings();
    }, [companyStore.company.pivotalEquityPlans]);

    // TODO: Add registration when token is OOD (420)

    return (
        <div
            style={TrusteeAdditionalStyles}
            className={TrusteeAdditionalStyles.mainContainer}
        >
            <div className={TrusteeAdditionalStyles.btnHeader}>
                <Button
                    qaid=""
                    label="Back"
                    inverse
                    cancel
                    onClick={backBtnHandler}
                />
            </div>

            <div className={TrusteeAdditionalStyles.pageBody}>
                <div className={TrusteeAdditionalStyles.pageHeader}>
                    <div className={TrusteeAdditionalStyles.headerMain}>
                        <img src={IC_EQUITY_SETTINGS} />
                        <h3>General Equity Settings</h3>
                    </div>
                </div>

                {
                    equityPlansStore.showTrustee && (
                        <div className={TrusteeAdditionalStyles.settingSection}>
                            <SelectDataSource />
                        </div>
                    )
                }

                {
                    companyStore.company.pivotalEquityPlans ? (
                        <>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <p><span style={{ fontWeight: 600 }}>Summary:</span> as of {formatDate(trusteeStore.dataAsOfDate)}</p>
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.totalPool ?? 0} label="Pool" isViewMode />
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.unallocated ?? 0} label="Unallocated" isViewMode />
                                    <NumberInput qaid="" value={trusteeStore.poolSummary.allocated ?? 0} label="Allocated" isViewMode />
                                    {/* <NumberInput qaid="" value={trusteeStore.poolSummary.exercised ?? 0} label="Exercised" isViewMode /> */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Company Equity Plans</h2>
                                <Table
                                    columns={tableColumn}
                                    rows={equityPlansStore.companyData?.plans}
                                    scrollAfterRows={5}
                                    className={""}
                                    renderBottom={
                                        <Flex
                                            align="center"
                                            justify="start"
                                        >
                                            <AddButton
                                                onClick={newPlanHandler}
                                                qaid=""
                                                label="Create new equity plan"
                                            />
                                        </Flex>
                                    }
                                    onRowClick={(row) => newPlanHandler(row)}
                                />
                            </div>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Vesting Schedule</h2>
                                <VestingScheduleTable />
                            </div>
                            <div className={TrusteeAdditionalStyles.settingSection}>
                                <h2>Company data</h2>
                                <EntitiesFactory className={""} />
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};

export default observer(CompanySettings);

{
    /* <Table
                    columns={vestsColumns}
                    rows={equityPlansStore.companyData?.vestings}
                    border={{ bottom: true }}
                    className={`${EquitySectionStyle}__table`}
                    scrollAfterRows={5}
                    // onRowClick={handleVestingModal}
                    // onRowRender={handleVestingModal}
                    renderBottom={
                        <Flex align="center" justify="start">
                            <AddButton onClick={newVestSHandler} qaid="" label="Create New Vesting Schedule" />
                        </Flex>
                    }
                /> */
}

//  Vests

// const vestsColumns: TableColumn<IVestingSchedule>[] = [
// 	{ label: "Name", name: "vestingName", style: { flex: 2 } },
// 	{ label: "Description", name: "description", style: { flex: 7 } },
// 	{
// 		label: "Active",
// 		name: "active",
// 		style: { flex: 1 },
// 		render(obj) {
// 			return (
// 				<>
// 					<Switch
// 						id={obj.vestingId.toString()}
// 						value={vestIds[obj.vestingId]}
// 						checked={vestIds[obj.vestingId]}
// 						defaultChecked={vestIds[obj.vestingId]}
// 						onChange={(e) => {
// 							handleCompanyVestConnection(obj.vestingId, e.target.checked);
// 							setRender(render + 1);
// 						}}
// 						color="secondary"
// 						size="small"
// 					/>
// 				</>
// 			);
// 		},
// 	},
// 	// {
// 	// 	label: "",
// 	// 	name: "menu",
// 	// 	menuItems: [
// 	// 		{
// 	// 			onClick: (obj) => { },
// 	// 			element: <img src={IC_EDIT_GRAY} alt="edit" />,
// 	// 			qaid: "CompanySettings.Button.EditVesting",
// 	// 		},
// 	// 		{
// 	// 			onClick: (obj) => { },
// 	// 			element: <img src={IC_TRASH2} alt="delete" />,
// 	// 			qaid: "CompanySettings.Button.DeleteVesting",
// 	// 		},
// 	// 	],
// 	// },
// ];
