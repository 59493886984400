import { useEffect, useState } from "react";
import CircularSwitchToggle from "../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import DoughnutChart from "../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import Widget from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/Widget";
import { WidgetBody } from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/Widget.style";
import WidgetTitle from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/WidgetTitle/WidgetTitle";
import WidgetDashboard from "../../../Shared/Components/WidgetDashboard/shared/components/WidgetDashboard/WidgetDashboard";
//import GrantsTable from "./GrantsTable/GrantsTable";
//import BeneficiariesTable from "./BeneficiariesTable/BeneficiariesTable";

import useRootStore from "../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import ChartLegend from "../../../Shared/Components/WidgetDashboard/shared/components/ChartLegend/ChartLegend";
import Card from "./Card";
import { IC_BENEFICIARIES, IC_EXERCISED, IC_GRANTS, IC_USER } from "../../../Assets";
import { formatDecimal, formatNumber, getSum } from "../../../Shared/Utilities";
import WithToggle from "../../../Shared/Components/WidgetDashboard/shared/components/WithToggle/WithToggle";
import NoData from "../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/NoData/NoData";
import FutureVestingTable from "./FutureVestingTable/FutureVestingTable";
import TrusteeBeneficiariesMain from "./TrusteeBeneficiaries/TrusteeBeneficiariesMain";
import TrusteeGrantsMain from "./TrusteeGrants/TrusteeGrantsMain";
import PagedTable from "../../../Shared/Components/Table/PagedTable/PagedTable";
import { upcomingVestingsKeys } from "../StaticData/tableTypes";
import { useEffectOnce } from "react-use";

import trusteeDashboardStyle from "./TrusteeDashboard.module.css";

import prplSquare from './pieQuare.svg'
import ChartLegendDivided from "../../../Shared/Components/WidgetDashboard/shared/components/ChartLegendDivided/ChartLegendDivided";

const DashboardText = ({ title, number }: { title: string; number: number }) => {
	return (
		<div className={`${trusteeDashboardStyle}__texts`}>
			<span className="title text-ellipsis">{title}</span>
			<span className="number">{formatNumber(number)}</span>
		</div>
	);
};

const TrusteeDashboard = () => {
	const { trusteeStore } = useRootStore();
	// const [data, setData] = useState<IDashboardData>();

	const [renderPie, setRenderPie] = useState<boolean>();
	const [isDashboardOpen, setIsDashboardOpen] = useState<boolean>(true);

	// const data = trusteeStore.poolSummary

	const [byBeneficiaries, setByBeneficiaries] = useState(true);
	const [pieByPool, setPieByPool] = useState(true)

	// const labels = ["Allocated", "Unallocated", "d"];
	// const total = data.granted + data.unAllocated;
	// const total = getSum(data?.outstanding, data?.exercised, data?.sold, data?.canceled);

	// const figures = data ? [data.allocated, data.unallocated, 100] : [];

	const grantsTotal = (trusteeStore.grantsSummary.unvested +
		trusteeStore.grantsSummary.exercisable +
		trusteeStore.grantsSummary.saleable +
		trusteeStore.grantsSummary.exercised +
		trusteeStore.grantsSummary.sold +
		trusteeStore.grantsSummary.canceled);

	const grantsPercentage = (number: number) => {
		const per = (number / grantsTotal) * 100
		return per.toFixed(2)
	}

	const grantsPie = {
		percentage: [
			grantsPercentage(trusteeStore.grantsSummary.unvested ?? 0),
			grantsPercentage(trusteeStore.grantsSummary.exercisable ?? 0),
			grantsPercentage(trusteeStore.grantsSummary.saleable ?? 0),
			grantsPercentage(trusteeStore.grantsSummary.exercised ?? 0),
			grantsPercentage(trusteeStore.grantsSummary.sold ?? 0),
			grantsPercentage(trusteeStore.grantsSummary.canceled ?? 0),
		],
		labels: [
			'Unvested',
			'Exercisable',
			'Saleable',
			'Exercised',
			'Sold',
			'Canceled',
		]
	}

	const poolPie = {
		figures: [
			trusteeStore.poolSummary.allocated ?? 0,
			trusteeStore.poolSummary.unallocated ?? 0
		],
		percentage: [
			((trusteeStore.poolSummary.allocated / trusteeStore.poolSummary.totalPool) * 100).toFixed(2),
			((trusteeStore.poolSummary.unallocated / trusteeStore.poolSummary.totalPool) * 100).toFixed(2)
		],
		labels: [
			'Allocated',
			'Unallocated'
		]
	}

	const tableHeader = (
		<CircularSwitchToggle
			value={!byBeneficiaries}
			actions={[
				{ value: true, label: "Grants" },
				{ value: false, label: "Beneficiaries" },
			]}
			onChange={(value) => setByBeneficiaries(value)}
			type="secondary"
			size="md"
		/>
	);

	const dashboardToggleHandler = () => {
		setIsDashboardOpen(open => {
			const items = open ? 10 : 5
			trusteeStore.mainTableItemsInPage = items

			return !open
		})
	}

	const renderPieChecker = () => {
		// const { totalPool } = data;

		// if (totalPool > 0) {
		// 	setRenderPie(true);
		// } else {
		// 	setRenderPie(false);
		// }
	};

	// useEffect(() => {
	// 	renderPieChecker();
	// }, [data]);

	useEffectOnce(() => {
		trusteeStore.GetDahboradData()
		trusteeStore.GetTrusteeGrants()
		trusteeStore.GetUpcomingVestingsSummaryByParams({})
	});

	useEffect(() => {
		trusteeStore.GetDahboradData()
	}, [trusteeStore.dataAsOfDate])


	return (
		<div style={trusteeDashboardStyle} >
			<div className={trusteeDashboardStyle.dashboardContainer}>

				{/* {!!data && ( */}
				<div className={trusteeDashboardStyle.toggleContainer}>
					<WithToggle onToggle={dashboardToggleHandler} >
						<div className={trusteeDashboardStyle.gridContainer}>

							<div className={trusteeDashboardStyle.grid}>

								<Card className={trusteeDashboardStyle.card1}>

									<div className={trusteeDashboardStyle.pieCard}>
										<CircularSwitchToggle
											value={!pieByPool}
											actions={[
												{ value: true, label: "Pool status" },
												{ value: false, label: "Grants status" },
											]}
											onChange={(value) => setPieByPool(value)}
											type="secondary"
											size="sm"
										/>

										<div className={trusteeDashboardStyle.pieCardPie}>
											{pieByPool ? (
												<>
													<DoughnutChart
														// ap={{	figures, 	labels, }}
														ap={{ figures: poolPie.figures, labels: poolPie.labels }}
													/>

													<div className={trusteeDashboardStyle.chartLegend}>
														<div className={trusteeDashboardStyle.legendHeader}>
															<h3>Total pool:</h3><h3> {formatNumber(trusteeStore.poolSummary.totalPool)}</h3>
														</div>
														<div className={trusteeDashboardStyle.chartLegendLower}>
															<div className="" style={{ maxWidth: '20%' }}>
																{/* <ChartLegend labels={labels} figures={figures} /> */}
																<ChartLegendDivided labels={poolPie.labels} figures={poolPie.figures} percenteges={poolPie.percentage} />
															</div>
														</div>
													</div>
												</>
											) : (
												<>
													<DoughnutChart
														ap={{ figures: grantsPie.percentage, labels: grantsPie.labels }}
													/>

													<div className={trusteeDashboardStyle.chartLegend}>
														<div className={trusteeDashboardStyle.chartLegendLower}>
															<div className="" style={{ maxWidth: '20%' }}>
																<ChartLegendDivided labels={grantsPie.labels} percenteges={grantsPie.percentage} toColums />
															</div>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</Card>

								<Card className={trusteeDashboardStyle.card2} img={IC_GRANTS}>
									<div className={trusteeDashboardStyle.cardText}>
										<h3>Grant Documents</h3>
										<p>
											Pending approvals: <span style={{ marginLeft: '1rem', fontWeight: 600 }}>{formatNumber(trusteeStore.grantDocumentsSummary.numberOfTotalItems)}</span>
										</p>
									</div>
								</Card>

								<Card className={trusteeDashboardStyle.card3} img={IC_BENEFICIARIES}>
									<div className={trusteeDashboardStyle.cardText}>
										<h3>Beneficiaries</h3>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<p>Active: </p>
												<p style={{ marginLeft: '1rem', fontWeight: 600 }}>{formatNumber(trusteeStore.benficiariesSummary.active)}</p>
											</div>
											<div style={{ display: 'flex', flexDirection: 'row', marginLeft: '2rem' }}>
												<p>Inactive: </p>
												<p style={{ marginLeft: '1rem', fontWeight: 600 }}>{formatNumber(trusteeStore.benficiariesSummary.inactive)}</p>
											</div>
										</div>
									</div>
								</Card>

								<div className={trusteeDashboardStyle.card4}>
									{/* <FutureVestingTable /> */}
									<div className={trusteeDashboardStyle.vestTable}>
										<h3>Upcoming vesting events</h3>
										<PagedTable
											tableData={trusteeStore.upcomingVestings.upComingVestingList}
											totalRows={trusteeStore.upcomingVestings.totalVestingEvents}
											newDataReq={trusteeStore.GetUpcomingVestingsSummaryByParams}
											tableKeys={upcomingVestingsKeys}
											itemsInPage={3}
										/>
									</div>
								</div>
							</div>
						</div>
					</WithToggle>
				</div>

				{/* )} */}

				<div className={trusteeDashboardStyle.mainTable}>
					{
						!byBeneficiaries
							?
							<TrusteeBeneficiariesMain
								header={tableHeader}
								expand={!isDashboardOpen}
							/>
							:
							<TrusteeGrantsMain
								header={tableHeader}
								expand={!isDashboardOpen}
							/>
					}
				</div>
			</div>
		</div>

	);
};
export default observer(TrusteeDashboard);
