import { createRef, useEffect, useState } from "react";

import useMultiStepForm, { ForwardedRef } from "../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import NewVestingSchedule from "../Settings/VestingSchedule/NewVestingSchedule/NewVestingSchedule";
import CompanyData from "./Steps/CompanyData";
import Intro from "./Steps/Intro";
import NewEquityPlan from "./Steps/NewEquityPlan";

import { ModalBodyProps } from "../../../../Shared/Components/Modal/types";
import VestingScheduleTable from "../Settings/VestingSchedule/VestingScheduleTable";
import RegistrationStyle from "./Registration.Style";
import { EquityPlan } from "../../../../Models/App/EquityPlans/Company";

export enum EquityEntities {
	Beneficiaries,
	Grants,
	Orders,
	Cancellations,
}

export enum CompanyRegistrationEnum {
	intro,
	newEquityPlan,
	vestingScheduleTemplates,
	newVestingSchedule,
	companyData,
}

interface Props extends ModalBodyProps {
	startingStep: CompanyRegistrationEnum;
}

const Registration = (props: Props) => {
	const { equityPlansStore } = useRootStore();
	const stepRef = createRef<ForwardedRef>();
	const { step, goTo } = useMultiStepForm([
		{
			element: <Intro ref={stepRef} />,
		},
		{
			element: <NewEquityPlan ref={stepRef} />,
		},
		{
			element: <VestingScheduleTable ref={stepRef} isRegistration />,
		},
		{
			element: <NewVestingSchedule />,
			isRequired: false,
		},
		{
			element: <CompanyData removeModal={props.removeModal} />,
		},
	]);

	useEffect(() => {
		goTo(props.startingStep);
	}, [props.startingStep]);

	const [haveCompany, setHaveCompany] = useState(false);

	// const newCompanyHandler = async () => {
	// 	const company = await equityPlansStore.CreateNewCompany();

	// 	if (company === true) setHaveCompany(true);
	// };

	const checkEquityCompany = async () => {
		await equityPlansStore.GetEquitySettings();
		if (equityPlansStore.companyData) {
			setHaveCompany(true);
		} else {
			setHaveCompany(false);
		}
	};

	useEffect(() => {
		checkEquityCompany();
		equityPlansStore.GetGrantsForCompany();
		// equityPlansStore.GetBeneficiariesForCompany();
		equityPlansStore.GetDashboardOrders();
	}, []);

	useEffect(() => {
		haveCompany && equityPlansStore.GetEquitySettings();
	}, [haveCompany]);

	return <section className={RegistrationStyle}>{step}</section>;
};
export default Registration;
