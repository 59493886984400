import { css } from "@emotion/css";
import { breakpoints } from "../../../../config/style/theme";
import appConfig from "../../../../config/config";

export const PlanStyle = css({
	label: 'Plan',
	position: 'relative',
	minWidth: '25rem',
	maxWidth: '38rem',
	maxHeight: '81vh',
	height: 'calc(100% - 4.5rem)',
	borderRadius: '16px',
	border: '1px solid #EAECF0',
	background: '#FFF',
	boxShadow: '0px 12px 16px -4px rgba(0, 0, 0, 0.08)',
	display: 'flex',
	flexDirection: 'column',
	paddingInline: '1.25rem',
	transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
	transform: 'scale(1)',
	flex: '1 0 38rem',
	color: appConfig.style.colors.text1,

	'&:not(.disabled):hover': {
		boxShadow: '0 20px 25px rgb(0 0 0 / 25%)',
		transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
		cursor: 'pointer',
		transform: 'scale(1.02)',
	},

	'&.focus': {
		outline: `3px solid ${appConfig.style.colors.color1}`,
		borderRadius: ' 0 0 16px 16px',
	},

	'&.disabled': {
		cursor: 'not-allowed',
	},

	'&.carousel': {
		boxShadow: '0 20px 25px rgb(0 0 0 / 25%)',
		transform: 'scale(1.03)',
	},

	'.focusHeader': {
		position: 'absolute',
		bottom: '100%',
		left: '-4px',
		backgroundColor: appConfig.style.colors.color1,
		width: 'calc(100% + 8px)',
		borderRadius: '22px 22px 0 0',
		color: '#fff',
		fontSize: '2rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0.75rem 0',
		fontWeight: 700,
	},

	'.topContainer': {
		flexDirection: 'column',
		justifyContent: 'start',
		padding: '3.3rem 2.5rem 1.5rem 2.5rem',
		flex: '0 0 20rem',

		'&__title': {
			fontSize: '2rem',
			color: appConfig.style.colors.color1,
			fontWeight: 700,
		},

		'&__description': {
			textAlign: 'center',
			fontsize: '1.6rem',
			margin: '1.6rem 0 0 0',
			height: '3rem',
		},

		'&__price': {
			fontSize: '4.5rem',
			fontWeight: '500',
			whiteSpace: 'nowrap',
		},

		'&__month': {
			fontSize: '2rem',
			marginTop: '2rem',
			whiteSpace: 'nowrap',
		},

		'&__brandLogo': {
			margin: 'auto 0',
		},
	},

	'.middleContainer': {
		width: '100%',

		'.buttonContainer': {
			position: 'relative',
			background: 'linear-gradient(90deg, #8B96E9 0%, #F3D465 33.00%, #F2AA63 66.00%, #E98B8B 100%)',
			minHeight: '4rem',
			borderRadius: '8px',
			padding: '1rem 4rem',
			width: '100%',
			boxShadow: '1px 2px 2px 0px rgba(130, 130, 130, 0.30)',

			'&.disabled': {
				cursor: 'not-allowed',
			},

			span: {
				background: '#fff',
				borderRadius: '4px',
				minWidth: 'Calc(100% - 6px)',
				height: 'Calc(100% - 6px)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: '1.6rem',
				fontWeight: '700',
				whiteSpace: 'nowrap',

				'&:first-child': {
					position: 'absolute',
				},
			},
		},
	},

	'.divider': {
		width: 'calc(100% - 2.5rem)',
		height: '0.5px',
		backgroundColor: appConfig.style.colors.field,
		margin: '2.5rem auto 1.6rem auto',
	},

	'.customContainer': {
		display: 'flex',
		gap: '2rem',
		fontSize: '1.6rem',

		'.title': {
			fontSize: '1.9rem',
			fontWeight: 'bold',
			marginBottom: '2rem',
			display: 'block',
		},
	},

	'.stakeholdersContainer': {
		display: 'flex',
		gap: '2rem',
		fontSize: '1.6rem',
		marginBottom: '1.5rem',

		'&__icon': {
			height: '2.4rem',
			width: '2.4rem',
		},

		'&__tooltip': {
			marginLeft: 'auto',
		},
	},

	'.bottomContainer': {
		marginBottom: '1rem',
		flex: 1,
		justifyContent: 'start',
		flexDirection: 'column',
		alignItems: 'start',
		overflowY: 'hidden',
		paddingLeft: '1.25rem',

		'&__title': {
			fontSize: '1.6rem',
			color: appConfig.style.colors.color1,
			marginBottom: '2rem',
		},

		'.innerFeatures': {
			overflowY: 'auto',
			paddingRight: '1.25rem',

			'.featureContainer': {
				justifyContent: 'start',
				gap: '2rem',
				marginBottom: '1.5rem',

				'&:not(:last-child)': {
					marginBottom: '2rem',
				},

				'&__text': {
					fontSize: '1.6rem',
					fontWeight: 400,
				},

				'&__icon': {
					height: '2.4rem',
					width: '2.4rem',
				},

				'&__tooltip': {
					marginLeft: 'auto',
				},
			},
		},

		'&__footerText': {
			padding: '0.8rem 3rem 1.6rem',
		},
	},

	[`@media screen and (max-width: ${breakpoints.s})`]: {
		'.focusHeader': {
			fontSize: '1.6rem',
		},

		'.topContainer': {
			'&__title': {
				fontSize: '1.6rem',
			},

			'&__price': {
				fontSize: '3.5rem',
			},

			'&__month': {
				fontSize: '1.2rem',
			},

			'&__chargeType': {
				fontsize: '1.4rem',
			},

			'&__description': {
				fontsize: '1.4rem',
			},
		},

		'.middleContainer': {
			width: '100%',

			'.buttonContainer': {
				span: {
					fontSize: '1.5rem',
				},
			},
		},

		'.divider': {
			width: '100%',
			height: '2px',
			backgroundColor: 'rgba(139, 150, 233, 0.32)',
			margin: '2.5rem 0 1.6rem 0',
		},

		'.bottomContainer': {
			marginBottom: '1rem',
			flex: 1,
			justifyContent: 'start',
			flexDirection: 'column',
			alignItems: 'start',
			overflowY: 'auto',

			'&__title': {
				fontSize: '1.2rem',
				color: appConfig.style.colors.color1,
				margin: '0 0 2rem 1.2rem',
			},

			'.featureContainer': {
				justifyContent: 'start',
				gap: '1.9rem',

				'&:not(:last-child)': {
					marginBottom: '1.5rem',
				},

				'&__text': {
					fontSize: '1.2rem',
				},
			},
		},
	},

	[`@media screen and (max-width: ${breakpoints.s})`]: {
		// maxHeight: "60rem",
		maxWidth: '30rem',
		minWidth: '30rem',
		marginTop: '4rem',
		'&.focus': {
			marginTop: '10rem',
		},
	},
});
