import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { IC_EDIT2, IC_EYE2, IC_INLINE_EDIT, IC_PUBLISH, IC_TRASH2 } from "../../../../../../Assets";
import { WaterfallProject } from "../../../../../../Models/API/Waterfall/IForm";
import Clickable from "../../../../../../Shared/Components/Clickable/Clickable";
import Image from "../../../../../../Shared/Components/Image";
import Spinner from "../../../../../../Shared/Components/Spinner/Spinner";
import { InputValidationRef } from "../../../../../../Shared/Hooks/useFormValidation";
import { formatDate } from "../../../../../../Shared/Utilities";
import { VersionsProps } from "../VersionsDropDown";
import VersionStyle from "./Version.style";
import { WaterfallFormSteps } from "../../../../../../Store/WaterfallStore";
import { SummaryStatusCode } from "../../../AddEditWaterfall/Steps/Summary/deps/types";
import ProgressCard from "../../../AddEditWaterfall/Steps/Summary/components/ProgressCard";

interface Props extends Pick<VersionsProps, "onDelete" | "onLoad" | "isViewMode"> {
    project: WaterfallProject;
}

const Version = ({ project, onDelete, onLoad }: Props) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const editInputRef = useRef<InputValidationRef>(null);

    useEffect(() => {
        setName(project.projectName);
    }, [project]);

    useEffect(() => {
        isEdit && editInputRef?.current?.focus(false);
    }, [isEdit, editInputRef?.current]);

    const openProject = () => onLoad(project, Math.min(project.maxStep - 1, WaterfallFormSteps.preferencesTerms));

    return (
        <div className={`${VersionStyle}__row-wrapper`}>
            {isLoading && (
                <Spinner
                    attachParent
                    size={24}
                />
            )}
            <div className={classNames(`${VersionStyle}__row`)}>
                <div className={`${VersionStyle}__row-name`}>
                    <div
                        data-qaid="Vesion.Link.Project"
                        className={`${VersionStyle}__project-link pointer`}
                        onClick={() => !isEdit && openProject()}
                    >
                        {project.projectName}
                    </div>
                </div>
                <div className={`${VersionStyle}__row-exit-value gray`}>{project.exitValue}</div>
                <div className={`${VersionStyle}__row-update ${VersionStyle}__gray`}>{formatDate(project.dateModified)}</div>
                <div className={`${VersionStyle}__row-valuator ${VersionStyle}__gray`}>{project.valuatorFullName}</div>
                <div className={`${VersionStyle}__row-actions`}>
                    {project.summaryStatus.status === SummaryStatusCode.RUNNING ? (
                        <ProgressCard
                            startTime={project.summaryStatus.status}
                            estimated={project.summaryStatus.estimatedTimeDTO}
                            isSummaryStep={false}
                        />
                    ) : project.isSummary ? (
                        <Clickable
                            flex={0}
                            qaid="Version.Button.InlineEdit"
                            onClick={() => {
                                onLoad(project, WaterfallFormSteps.summary);
                            }}
                            disabled={!project.isSummary}
                        >
                            <Image
                                src={IC_EYE2}
                                alt="inline edit"
                                tooltip="View summary"
                            />
                        </Clickable>
                    ) : (
                        <div style={{ width: "2.8rem" }}></div>
                    )}
                    <Clickable
                        flex={0}
                        qaid="Version.Button.Trash"
                        className={classNames("btn-trash")}
                        onClick={() => project.isOwner && openProject()}
                        disabled={!project.isOwner}
                    >
                        <Image
                            src={IC_EDIT2}
                            alt="trash"
                            tooltip="Edit"
                        />
                    </Clickable>
                    <Clickable
                        flex={0}
                        qaid="Version.Button.Trash"
                        className={classNames("btn-trash")}
                        onClick={() => project.isOwner && onDelete(project.waterfallId, project.projectName)}
                        disabled={!project.isOwner}
                    >
                        <Image
                            src={IC_TRASH2}
                            alt="publish"
                            tooltip="Delete"
                        />
                    </Clickable>
                </div>
            </div>
        </div>
    );
};

export default Version;
