import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Clickable from "../Clickable/Clickable";
import StyledCheckBox, { CheckBoxCss } from "./CheckBox.Style";
import { isFunction, isNullOrUndefined, isNumber, isString } from "../../Utilities";
import { ReactElement, SyntheticEvent, isValidElement } from "react";
import { inputClassName } from "../Input/Input.Style";

export interface CheckBoxProps extends Omit<React.HTMLAttributes<HTMLElement>, "onClick"> {
    qaid: string;
    isChecked: boolean | undefined;
    size?: number | string;
    onClick?: (value: boolean, name?: string) => void;
    label?: string | number | (() => ReactElement) | ReactElement;
    disabled?: boolean;
    disableBoxSpacing?: boolean;
    autoTranslate?: boolean;
    name?: string;
    type?: "primary" | "secondary";
    inputClassName?: string;
}

const CheckBox = ({
    isChecked,
    size,
    onClick,
    label,
    qaid,
    disabled,
    disableBoxSpacing,
    autoTranslate,
    name,
    type = "primary",
    inputClassName = "",
    ...props
}: CheckBoxProps) => {
    const { t } = useTranslation();

    const renderLabel = () => {
        if (isNullOrUndefined(label)) return <></>;
        return (
            <div className={`${CheckBoxCss}__label`}>
                {isFunction(label)
                    ? label()
                    : isValidElement(label)
                    ? label
                    : autoTranslate !== false && !isNullOrUndefined(label)
                    ? t(label.toString())
                    : label}
            </div>
        );
    };

    return (
		<Clickable
			{...props}
			flex="0 0 fit-content"
			justify="start"
			className={classNames(CheckBoxCss, {
				disabled,
				[props.className as string]: !!props.className,
				[`${CheckBoxCss}__mt-10`]: !disableBoxSpacing,
			})}
			align="start"
			qaid={qaid}
			width="fit-content"
			onClick={(e: SyntheticEvent) => {
				!disabled ? onClick?.(!isChecked, name) : e.stopPropagation();
			}}
			height="auto"
		>
			{renderLabel()}
			<StyledCheckBox
				disabled={disabled}
				className={classNames('cb-input', { [inputClassName]: !!inputClassName })}
				isChecked={isChecked}
				size={size}
				type={type}
			>
				<span className="check"></span>
			</StyledCheckBox>
		</Clickable>
	);
};

export default CheckBox;
