import classNames from "classnames";
import { CSSProperties, createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Fade } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { checkQaidConvention, isNullOrUndefined, isNumber, isString } from "../../Utilities";
import StyledSelect, { Option } from "../Select/Select.Style";
import { StyledInput, inputClassName } from "./Input.Style";
import InputWrapper from "./InputWrapper";
import { AutoCompleteProp, InputRef } from "../../../Models/App/Inputs/types";
import { NumberOption, NumberOptionDescription } from "../../../Models/API/All/NumberOption";
import Flex from "../Layout/Flex";
import { KeyDownEnum } from "../../Enums";
import OverflowText from "../OverflowText";

const AutoCompleteInput = forwardRef<InputRef, AutoCompleteProp>(({ strict = true, options, autoTranslate, onChange, ...props }, ref) => {
    checkQaidConvention(props.qaid);
    const { t } = useTranslation();
    const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
    const inputRef = createRef<HTMLInputElement>();
    const optionsRef = createRef<HTMLInputElement>();
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [internalValue, setInternalValue] = useState<string>("");
    const [optionsStyle, setOptionsStyle] = useState<CSSProperties>();
    const optionsRefs = useRef<HTMLDivElement[]>([]);
    const [optionFocusId, setOptionFocusId] = useState<number>(-1);

    useEffect(() => {
        if (!inputRef?.current) return;

        const observer = new ResizeObserver((entries: any) => {
            const target = entries[0].target;
            setOptionsStyle((prevState) => ({
                ...prevState,
                width: `${target.clientWidth}px`,
                top: `${target.offsetTop + 40}px`,
                left: `${target.offsetLeft}px`,
            }));
        });

        observer.observe(inputRef.current);

        return () => observer.disconnect();
    }, [inputRef.current]);

    useEffect(() => {
        // optionsRefs.current[optionFocusId]?.focus();
        // Daniel: start scrolling after third option is selected for smoothbess, -2 is to center the focused element in the list
        optionFocusId > 1 && optionsRefs.current[optionFocusId - 2]?.scrollIntoView({ behavior: "smooth" });
    }, [optionFocusId]);

    useEffect(() => {
        setInternalValue("");
    }, [options]);

    useEffect(() => {
        if (!options?.length) return;

        const item = options?.find((item) => isNumber(item.value) && item.value === props.value);
        setInternalValue(isString(item?.label) ? item?.label : ""); // If options are provided, update internalValue because we send the value and display the label
    }, [props.value]);

    // useEffect(() => {
    //   // options && !internalValue && onItemSelect?.(undefined, props.name); // If no text, send undefined
    // }, [internalValue]);

    useImperativeHandle(ref, () => ({
        resetError() {
            setIsFocusOnce(false);
        },
        showError() {
            setIsFocusOnce(true);
        },
        focus() {
            inputRef?.current?.focus();
            inputRef?.current?.scrollIntoView({ behavior: "smooth" });
        },
        clear() {
            setInternalValue("");
        },
        getName() {
            return props.name;
        },
    }));

    // Filtering items in case of auto complete
    const selectedItem = options?.find((item) => isString(item.label) && item.label.toLowerCase() === internalValue.toLocaleLowerCase());
    if (options && ((!strict && selectedItem?.value !== props.value) || strict)) {
        options = options?.filter(
            (item: NumberOptionDescription) =>
                (isString(item.label) && item.label.toLowerCase().includes(internalValue.toLocaleLowerCase())) ||
                item.description?.toLowerCase().includes(internalValue.toLocaleLowerCase())
        );

        if (internalValue) {
            options = options.sort((a, b) =>
                isString(a.label) && a.label.toLocaleLowerCase().startsWith(internalValue.toLocaleLowerCase()) ? -1 : 1
            );
        }
    }

    const onKeyDownHandler = (e: any) => {
        if (isNullOrUndefined(optionsRef.current) || isNullOrUndefined(options)) return;

        if (e.keyCode === KeyDownEnum.ArrowDown) {
            e.preventDefault();
            setOptionFocusId((prev) => (prev + 1 < (options?.length ?? 0) ? prev + 1 : prev)); // Not going above idx options.length - 1
        }
        if (e.keyCode === KeyDownEnum.ArrowUp) {
            e.preventDefault();
            setOptionFocusId((prev) => (prev - 1 < 0 ? prev : prev - 1)); // Not going below idx 0
        }

        if ((e.keyCode === KeyDownEnum.Enter || e.keyCode === KeyDownEnum.Tab) && optionFocusId > -1) {
            setIsSelected(true);
            onChange?.(options[optionFocusId].value, props.name);
            setOptionFocusId(-1);
        }
    };

    const showError = (isFocusOnce || props.forceValidation) && props.error;

    return (
        <InputWrapper
            {...props}
            error={showError ? props.error : undefined}
            relative
        >
            {props.isViewMode ? (
                <Flex
                    height="3.6rem"
                    align="end"
                    justify="start"
                >
                    <OverflowText>{internalValue || "---------"}</OverflowText>
                </Flex>
            ) : (
                <>
                    <StyledInput
                        {...props}
                        onKeyDown={onKeyDownHandler}
                        autoComplete="nope"
                        flex={1}
                        className={classNames(inputClassName, props.className || "", {
                            inverse: props.inverse,
                            flat: props.flat,
                        })}
                        disabled={props.disabled || props.isLoading}
                        onBlur={(e) => {
                            setIsFocusOnce(true);

                            if (!isSelected && e.target.value) {
                                const item = options?.find(
                                    (option) =>
                                        isString(option.label) && option.label.toLocaleLowerCase() === e.target.value.toLocaleLowerCase()
                                );
                                onChange?.(item?.value ?? null, props.name);
                            }

                            if (!e.target.value) {
                                onChange?.(undefined, props.name);
                            }
                            props.onBlur?.(e);
                        }}
                        placeholder={props.placeholder ? t(props.placeholder) : props.placeholder}
                        onChange={(e: any) => {
                            setIsFocusOnce(false);
                            setIsSelected(false);
                            if (optionsRef.current) optionsRef.current.scrollTop = 0;
                            setOptionFocusId(-1);
                            setInternalValue(e.target.value);
                        }}
                        onInput={(e) => {
                            setIsSelected(false);
                            props.onInput?.(e);
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOptionFocusId(-1);
                            props.onClick?.(e);
                        }}
                        value={internalValue}
                        data-value={internalValue}
                        onFocus={(e) => {
                            setIsSelected(false);
                            props.onFocus?.(e);
                        }}
                        ref={inputRef}
                        data-qaid={props.qaid}
                    />

                    <Fade
                        in={options && !isSelected}
                        unmountOnExit
                    >
                        <StyledSelect.OptionsContainer
                            className="options-container"
                            ref={optionsRef}
                            style={optionsStyle}
                        >
                            {!options?.length && <StyledSelect.Option className="disabled">{t("general.none")}</StyledSelect.Option>}
                            {options?.map((item: NumberOption, idx: number) => (
                                <StyledSelect.Option
                                    onMouseDown={(e) => {
                                        // e.stopPropagation();
                                        setIsSelected(true);
                                        setOptionFocusId(-1);
                                        onChange?.(item.value, props.name);
                                    }}
                                    ref={(el: HTMLDivElement) => {
                                        if (optionsRefs.current) optionsRefs.current[idx] = el;
                                    }}
                                    key={item.value ?? idx}
                                    tabIndex={idx}
                                    className={classNames("text-ellipsis", { focus: idx === optionFocusId })}
                                >
                                    {autoTranslate && isString(item.label) ? t(item.label) : item.label}
                                </StyledSelect.Option>
                            ))}
                        </StyledSelect.OptionsContainer>
                    </Fade>
                </>
            )}
        </InputWrapper>
    );
});

export default AutoCompleteInput;
