import Heading from "../../../../Shared/Components/Heading/Heading";

type Props = {
	children: React.ReactNode;
};

const WaterfallSubtitle = (props: Props) => {
	return (
		<Heading
			ap={{
				tag: "h5",
				color: "dark",
				size: "md",
				spacingBottom: "md",
				isBold: true,
			}}
		>
			{props.children}
		</Heading>
	);
};

export default WaterfallSubtitle;
