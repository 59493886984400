import { css } from "@emotion/css";

const NewEquityPlanStyle = css({
	label: "NewEquityPlan",
	display: "flex",
	flexDirection: "column",

	".innerButtons": {
		position: "relative",
	},
	"&__loading-spinner": {
		minHeight: "40rem",
	},
});

export default NewEquityPlanStyle;
