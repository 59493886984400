import { useEffect, useState } from "react";
import { PlanData, WaterfallAwardType } from "../../../../../../Models/API/Waterfall/IForm";
import Separator from "../../../../../../Shared/Components/Separator";
import { css } from "@emotion/css";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import { useAppendState } from "../../../../../../Shared/Hooks/useAppendState";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import Button from "../../../../../../Shared/Components/Button/Button";
import { isNullOrUndefined } from "../../../../../../Shared/Utilities";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { InputValidationRef, useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";

const Style = css({
    label: "WaterfallAwardType",
    display: "flex",
    flexDirection: "column",
    "&__form": {
        display: "flex",
        gap: "2.4rem",
        marginTop: "2.4rem",
    },
});

type Props = {
    onAddHandler?: (data: PlanData[], isChanged: boolean, isUnalocation: boolean | null) => void;
    data?: PlanData;
};

const AddAward = ({ onAddHandler, data }: Props) => {
    const { waterfallStore } = useRootStore();
    const [award, setAward, onInputHandler] = useAppendState<WaterfallAwardType>();
    const { formValidationState, validateForm, inputRefs } = useFormValidation({
        form: award,
        schema: {
            name: [commonValidators.required()],
            totalAmount: [commonValidators.required(), commonValidators.graterThan(0)],
            price: [commonValidators.required(), commonValidators.graterThan(0)],
        },
    });

    useEffect(() => {
        if (isNullOrUndefined(data)) return setAward({} as WaterfallAwardType);

        setAward({
            shareClassId: data.shareClassId,
            name: data.name,
            price: data.price,
            totalAmount: data.amount,
        });
    }, [data]);

    const onAddOrUpdate = async () => {
        if (!validateForm()) return;
        const res = data?.shareClassId ? await waterfallStore.updateAwardType(award) : await waterfallStore.addAwardType(award);
        if (!res.isSuccess) return;
        onAddHandler?.(res.data.options, res.data.isUpdatedOption, res.data.withUnalocated);
    };

    return (
        <div className={Style}>
            <span>Please set the information for this award & press “{data?.shareClassId ? "Update" : "Add"}”</span>
            <Separator margin={false} />
            <div className={`${Style}__form`}>
                <TextInput
                    qaid="AddAward.Input.Name"
                    label="Equity award name"
                    name="name"
                    value={award.name}
                    onChange={onInputHandler}
                    required
                    error={formValidationState?.name?.message}
                    ref={(el: InputValidationRef) => (inputRefs.name = el)}
                />
                <NumberInput
                    qaid="AddAward.Input.Amount"
                    label="Amount"
                    name="totalAmount"
                    value={award.totalAmount}
                    onChange={onInputHandler}
                    required
                    error={formValidationState?.totalAmount?.message}
                    ref={(el: InputValidationRef) => (inputRefs.totalAmount = el)}
                />
                <NumberInput
                    qaid="AddAward.Input.Price"
                    label="Exercise price"
                    name="price"
                    value={award.price}
                    onChange={onInputHandler}
                    required
                    error={formValidationState?.price?.message}
                    ref={(el: InputValidationRef) => (inputRefs.price = el)}
                    number="float"
                />
                <Button
                    qaid="AddAward.Button.Add"
                    label={data?.shareClassId ? "Update" : "Add"}
                    position="end"
                    onClick={onAddOrUpdate}
                />
            </div>
            <Separator />
        </div>
    );
};

export default AddAward;
