import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../../Routes";
import TextInput from "../../../../Shared/Components/TextInput";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Button from "../../../../Shared/Components/Button/Button";
import { ApplicationEnum } from "../../../../Models/API/enums";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import { commonValidators, successResult } from "../../../../Shared/ObjectValidator";
import Flex from "../../../../Shared/Components/Layout/Flex";
import classNames from "classnames";
import { IMG_RESET_PASSWORD, IMG_PASSWORD_CHANGED } from "../../../../Assets";

import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import Text from "../../../../Shared/Components/Layout/Text";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import { REGEX_PASSWORD } from "../../../../Shared/Utilities";

interface UserData {
    password?: string;
    confirmPassword?: string;
}

export const AccountResetPassword = () => {
    const { auth, appState } = useRootStore();
    const history = useHistory();
    const params = useParams<{ otpguid: string }>();
    const { otpguid } = params;
    const [userData, setUserData] = useComplexState<UserData>({});
    const [isResetSuccess, setIsResetSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { isMobile, isTablet } = useScreenSize();

    const [isUserValid, userValState] = useObjectValidation(userData, {
        password: [commonValidators.required()],
        confirmPassword: [
            commonValidators.required(),
            (v) => {
                if (v !== userData.password) {
                    return {
                        isValid: false,
                        message: "Password confirmation must match",
                    };
                }
                return successResult;
            },
        ],
    });

    const passwordRulesValidation = () => {
        const isPasswordOk =
            // eslint-disable-next-line no-useless-escape
            REGEX_PASSWORD.test(userData.password as string);

        if (!isPasswordOk) {
            setErrorMessage(
                "Password must contain at least 8 characters, one uppercase character, one lowercase character, one numeric character and one special character"
            );
            return false;
        }

        return true;
    };

    useEffect(() => {
        (async () => {
            const res = await auth.validateUniqueKey(otpguid);

            if (!res.isSuccess) {
                history.push("/");
            }
        })();
    }, []);

    const submitOtp = async (e: any) => {
        e.preventDefault();
        appState.isLoading2 = true;

        setIsSubmitted(true);
        if (isUserValid && passwordRulesValidation()) {
            const res = await auth.validateResetPasswordOTP(userData.password as string, otpguid, ApplicationEnum.Cfo);
            if (!res.isSuccess) {
                setErrorMessage("Reset password failed. Please try again later");
            } else {
                setIsResetSuccess(true);
                setTimeout(() => {
                    history.push(Routes.account.login);
                }, 3000);
            }
        }

        appState.isLoading2 = false;
    };

    const troubleResettingMessage = (
        <Text
            width={"fit-content"}
            align="center"
            color="#828282"
            size={11}
        >
            If you run into trouble resetting password, please&nbsp;
            <a
                href={Routes.contactUs.external}
                target="_blank"
                rel="noreferrer"
                className="account-link"
            >
                contact us
            </a>
        </Text>
    );

    return (
        <Flex
            className={classNames("form-account-box login-box reset-password-box", {
                "password-changed": isResetSuccess,
                mobile: isMobile,
            })}
            position="relative"
        >
            {isResetSuccess ? (
                <Flex
                    className="password-changed"
                    direction="column"
                >
                    <span className="password-changed-title">Success!</span>
                    <img
                        className="password-changed-img"
                        src={IMG_PASSWORD_CHANGED}
                        alt="password changed"
                    />
                    <Text
                        className="password-changed-des"
                        align="center"
                        size={20}
                    >
                        The password has successfully changed
                    </Text>
                </Flex>
            ) : (
                <>
                    <Flex
                        direction="column"
                        align="center"
                    >
                        <span className={classNames("form-login-title", { mobile: isMobile })}>Set up a new password</span>
                        <Flex
                            className={classNames("reset-password-inner-box", {
                                mobile: isMobile,
                                tablet: isTablet,
                            })}
                            direction="column"
                        >
                            <img
                                className="reset-password-img"
                                src={IMG_RESET_PASSWORD}
                                alt="reset password"
                            />
                            <Flex
                                className="loginFields"
                                direction="column"
                                position="relative"
                                padding={isMobile ? "0 3rem" : 0}
                            >
                                <Flex
                                    direction="column"
                                    justify="between"
                                >
                                    <Flex
                                        direction="column"
                                        position="relative"
                                    >
                                        <Flex
                                            align="center"
                                            position="relative"
                                        >
                                            <TextInput
                                                className="account-input account-input-password"
                                                placeholder="New password * "
                                                qaid="AccountResetPassword.Input.Password"
                                                value={userData.password}
                                                hasError={isSubmitted && !userValState?.password?.isValid}
                                                isPassword
                                                capsLockPosition="top"
                                                onChange={(e) => {
                                                    setErrorMessage("");
                                                    setUserData({ password: e });
                                                }}
                                            />
                                        </Flex>

                                        {(userData.password?.length ?? 0) < 8 && (
                                            <Text className="input-label">{"Must have at least 8 characters"}</Text>
                                        )}
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        position="relative"
                                    >
                                        <Flex
                                            align="center"
                                            position="relative"
                                            margin={"3rem 0 5rem 0"}
                                        >
                                            <TextInput
                                                className="account-input account-input-password"
                                                placeholder="Confirm password *"
                                                qaid="AccountResetPassword.Input.ConfirmPassword"
                                                value={userData.confirmPassword}
                                                hasError={isSubmitted && !userValState?.confirmPassword?.isValid}
                                                isPassword
                                                onChange={(e) => {
                                                    setErrorMessage("");
                                                    setUserData({ confirmPassword: e });
                                                }}
                                            />
                                        </Flex>

                                        <ErrorMessage
                                            top={"8.2rem"}
                                            left={"1.2rem"}
                                        >
                                            {isSubmitted && userValState?.confirmPassword?.message}
                                        </ErrorMessage>
                                    </Flex>

                                    <Flex
                                        direction="column"
                                        position="relative"
                                    >
                                        <Button
                                            qaid="AccountResetPassword.Button.Reset"
                                            className={classNames(
                                                {
                                                    "account-orange-butt--disabled": !isUserValid,
                                                },
                                                "account-orange-butt",
                                                "reset-password-butt"
                                            )}
                                            position="center"
                                            onClick={submitOtp}
                                            disabled={!isUserValid}
                                        >
                                            Reset Password
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex align="center">{troubleResettingMessage}</Flex>
                    </Flex>
                    <Flex
                        position="relative"
                        margin={"2rem 0"}
                    >
                        <ErrorMessage
                            top={0}
                            width="100%"
                        >
                            {isSubmitted && errorMessage}
                        </ErrorMessage>
                    </Flex>

                    <Flex
                        align="center"
                        className={classNames("card-footer", { mobile: isMobile })}
                    >
                        <Link
                            className="account-link"
                            key="registration"
                            to={Routes.account.login}
                        >
                            Back to Login
                        </Link>
                    </Flex>
                </>
            )}
        </Flex>
    );
};

export default AccountResetPassword;
