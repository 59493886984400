import { css } from "@emotion/css";
import { breakpoints } from "../../../config/style/theme";

export const PricingStyle = css({
	label: "Pricing",
	backgroundColor: "#F4F5FD",
	overflow: "hidden",

	".plansContainer": {
		paddingInline: "10.5rem",
		height: "100%",
	},

	".carouselContainer": {
		display: "flex",
		height: "100%",

		".css-doq0dk": {
			position: "unset",
		},
	},
});
