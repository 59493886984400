import { useEffect, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { isNullOrUndefined } from "../../../../Shared/Utilities";

interface IProps {
    readonly cards: {
        key: number;
        content: JSX.Element;
    }[];
    readonly offsetRadius: number;
    readonly offset: number;
}

const CarouselCard = (props: IProps) => {
    const table = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    const [offsetRadius, setOffsetRadius] = useState<number>(2);
    const [goToSlide, setGoToSlide] = useState<number | undefined>(undefined);
    const [xDown, setXDown] = useState(null);
    const [yDown, setYDown] = useState(null);
    const [cards] = useState(table);

    const getTouches = (evt: any) => {
        return evt.touches || evt.originalEvent.touches;
    };

    const touchStartHandler = (evt: any) => {
        const firstTouch = getTouches(evt)[0];

        setXDown(firstTouch.clientX);
        setYDown(firstTouch.clientY);
    };

    const touchMoveHandler = (evt: any) => {
        if (isNullOrUndefined(xDown) || isNullOrUndefined(yDown)) {
            return;
        }

        let xUp = evt.touches[0].clientX;
        let yUp = evt.touches[0].clientY;

        let xDiff = (xDown ?? 0) - xUp;
        let yDiff = (yDown ?? 0) - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                setGoToSlide((prev) => (prev ?? 0) + 1);
                setXDown(null);
                setYDown(null);
            } else {
                setGoToSlide((prev) => (prev ?? 1) - 1);
                setXDown(null);
                setYDown(null);
            }
        }
    };

    useEffect(() => {
        setOffsetRadius(props.offset);
    }, [props.offset]);

    return (
        <div
            onTouchStart={touchStartHandler}
            onTouchMove={touchMoveHandler}
        >
            <Carousel
                slides={cards}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={false}
            />
        </div>
    );
};

export default CarouselCard;
