import { observer } from "mobx-react-lite";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../../Routes";
import { TextAreaInput } from "../../../../../../Shared/Components/Input/Input.Style";
import Spinner from "../../../../../../Shared/Components/Spinner/Spinner";
import { InputRefs, InputValidationRef, useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";
import { eliminateTimeZone, formatDate, isNullOrUndefined } from "../../../../../../Shared/Utilities";
import { ScenariosForm, WaterfallFormSteps } from "../../../../../../Store/WaterfallStore";

import { add } from "date-fns";
import { useTranslation } from "react-i18next";
import Hr from "../../../../../../Shared/Components/HorizontalRule/Hr";
import DatePickerInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import useEffectOnce from "../../../../../../Shared/Hooks/useEffectOnce";
import useModal from "../../../../../../Shared/Hooks/useModal";
import { ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import InputLabel from "../../../../../../config/style/components/InputLabel/InputLabel";
import WaterfallSubtitle from "../../../WaterfallSubtitle/WaterfallSubtitle";
import { WaterfallStep } from "../../AddEditWaterfall.Style";
import Styles from "./GeneralInfo.Style";
import Reviewers from "./Reviewers/Reviewers";

export function calculateEffectiveExitDate(valuationDate: Date, timeToExit: number) {
    const numOfYears = Math.floor(timeToExit);
    const numOfMonths = Math.round((timeToExit - numOfYears) * 12);
    return add(new Date(valuationDate), { years: numOfYears, months: numOfMonths });
}

const Scenarios = React.forwardRef<ForwardedRef, unknown>((props, forwardedRef) => {
    const {
        waterfallStore,
        waterfallStore: { updateScenarioForm, createProject, scenariosForm, isViewMode },
        companyStore: { companyId },
    } = useRootStore();
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>("");
    const [showDesc, setShowDesc] = useState<boolean>(false);
    const [effectiveExitDate, setEffectiveExitDate] = useState<string | undefined>(undefined);
    const history = useHistory();
    const { t } = useTranslation();
    const { showAsyncModal } = useModal();
    const formRef = useRef<ScenariosForm>();

    useEffect(() => {
        if (!scenariosForm.valuationDate || !scenariosForm.timeToExit) return;
        const res = calculateEffectiveExitDate(scenariosForm.valuationDate, scenariosForm.timeToExit);
        const newEffectiveExitDate = formatDate(res);
        setEffectiveExitDate(newEffectiveExitDate);
    }, [scenariosForm.valuationDate, scenariosForm.timeToExit]);

    const inputRefs = useRef<InputRefs<ScenariosForm>>({} as InputRefs<ScenariosForm>);

    const formSchema = {
        projectName: [
            commonValidators.maxLength(200),
            commonValidators.regex(/^[a-zA-Z0-9 \-&:+@()]*$/, 'Only letters, numbers, spaces or "- & : + @ ( )" allowed'),
            commonValidators.customValidation(!nameError, nameError),
        ],
        valuationDate: [commonValidators.requiredIf(() => false)],
        timeToExit: [commonValidators.range2(0, 100, { includeMax: true, includeMin: true })],
        exitValue: [commonValidators.range2(0, 1000000000, { includeMax: true, includeMin: false })],
    };

    const { validateForm, formValidationState } = useFormValidation({
        form: scenariosForm,
        schema: formSchema,
        deps: [nameError],
        refs: inputRefs.current,
    });

    const [tempName, setTempName] = useState<string>("");

    useEffect(() => {
        // initialStateRef.current = scenarios;

        if (!waterfallStore.currentWaterfallId) return;
        (async () => {
            // setIsFetching(true);
            await waterfallStore.getProject(waterfallStore.currentWaterfallId);
            setShowDesc(!!waterfallStore.scenariosForm.description);
            // setIsFetching(false);
        })();
    }, [waterfallStore.currentWaterfallId]);

    useEffectOnce(
        () => {
            if (!waterfallStore.currentWaterfallId) return;
            formRef.current = JSON.parse(JSON.stringify(waterfallStore.scenariosForm));
        },
        [waterfallStore.scenariosForm],
        !!waterfallStore.currentWaterfallId
    );

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            if (!waterfallStore.waterfallId) return false;

            const schemaValidation = {
                ...formSchema,
                projectName: [commonValidators.required(), ...formSchema.projectName],
                valuationDate: [commonValidators.required()],
                timeToExit: [commonValidators.required(), ...formSchema.timeToExit],
                exitValue: [commonValidators.required(), ...formSchema.exitValue],
            };

            const isValid = validateForm({ schemaValidation });
            if (!isValid) return false;

            try {
                const { exitValue, timeToExit, description, valuationDate } = scenariosForm;
                let isApproved = true;

                if (valuationDate && shouldShowWarning(valuationDate)) {
                    isApproved = await showAsyncModal({
                        title: "Warning!",
                        type: "confirm",
                        body: "Changing valuation date will erase the current analysis data including cap table and classes's rights, do you want to proceed?",
                        confirmButton: {
                            label: "Proceed",
                        },
                    });
                }

                const isScenarioChanged = JSON.stringify(formRef.current) !== JSON.stringify(scenariosForm);
                if (isScenarioChanged) {
                    await waterfallStore.updateScenarioStep({
                        companyId,
                        waterfallId: waterfallStore.currentWaterfallId,
                        exitValue: exitValue as number,
                        timeToExit: timeToExit || 0.000001,
                        valuationDate: valuationDate || new Date(),
                        description: description as string,
                    });
                }
                waterfallStore.setSelectedCapTableBase(undefined);
                await waterfallStore.getSteps();
                return true;
            } catch (error) {
                console.error("An error occurred while validating the form:", error);
                return false;
            }
        },
    }));

    const shouldShowWarning = (valuationDate: Date) => {
        return (
            waterfallStore.lastAllowedStep > 1 &&
            valuationDate &&
            waterfallStore.originalValuationDate &&
            +new Date(valuationDate) !== +new Date(waterfallStore.originalValuationDate)
        );
    };

    const getWarningModalConfig = () => ({
        title: "Warning!",
        type: "confirm",
        body: "Changing the valuation date will erase the current analysis data including cap table and classes's rights, do you want to proceed?",
        confirmButton: {
            label: "Proceed",
        },
    });

    const onInputHandler = (value?: string | number, name?: string) => {
        if (isNullOrUndefined(name)) return;
        setNameError("");
        updateScenarioForm(name, value);
    };

    const onCreateScenario = async () => {
        if (!scenariosForm.projectName || tempName === scenariosForm.projectName || !formValidationState?.projectName?.isValid) return;
        setNameError("");

        setIsCreating(true);
        const res = await createProject({
            companyId: companyId,
            waterfallId: waterfallStore.currentWaterfallId,
            projectName: scenariosForm.projectName,
        });
        setIsCreating(false);

        if (res?.errorMessage) {
            setNameError(res.errorMessage);
            return;
        }

        history.replace(`${Routes.waterfall.index}/${waterfallStore.currentWaterfallId}/${WaterfallFormSteps.scenarios}`);

        setNameError("");
    };

    return (
        // <div className="waterfall_step waterfall_step--general-info layout layout--primary" ref={forwardedRef}>
        <WaterfallStep small>
            <div className="layout__main">
                {isFetching ? (
                    <Spinner
                        incorporated
                        center
                    />
                ) : (
                    <>
                        <WaterfallSubtitle>{t("waterfall.generalInfo")}</WaterfallSubtitle>
                        <div className={Styles}>
                            <div className="scenario-name">
                                <TextInput
                                    disabled={isViewMode}
                                    error={formValidationState?.projectName?.message}
                                    forceValidation={false}
                                    info="waterfall.analysisNameInfo"
                                    isLoading={isCreating}
                                    label="Waterfall scenario name *"
                                    name="projectName"
                                    onBlur={onCreateScenario}
                                    onChange={onInputHandler}
                                    onFocus={() => setTempName(scenariosForm.projectName as string)}
                                    qaid="Scenarios.Input.ProjectName"
                                    ref={(el: InputValidationRef) => (inputRefs.current.projectName = el)}
                                    value={scenariosForm.projectName as string}
                                />
                            </div>

                            <div className="scenario-valuation-date">
                                <DatePickerInput
                                    label={`${t("waterfall.valuationDate")} *`}
                                    value={scenariosForm.valuationDate ? eliminateTimeZone(scenariosForm.valuationDate) : undefined}
                                    name="valuationDate"
                                    onChange={(value, name) => name && updateScenarioForm(name, value?.toISOString())}
                                    disabled={isViewMode}
                                    info="waterfall.valuationDateInfo"
                                    error={formValidationState?.valuationDate?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.valuationDate = el)}
                                    qaid="Scenarios.Input.ValidationDate"
                                />
                            </div>

                            <div className="scenario-time-to-exit">
                                <NumberInput
                                    label={`${t("waterfall.timeToExit")} *`}
                                    value={scenariosForm.timeToExit as number}
                                    onChange={onInputHandler}
                                    disabled={isViewMode}
                                    name="timeToExit"
                                    info="waterfall.timeToExitInfo"
                                    error={formValidationState?.timeToExit?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.timeToExit = el)}
                                    qaid="Scenarios.Input.TimeToExit"
                                />
                            </div>

                            <div className="scenario-effective-time-to-exit">
                                <TextInput
                                    aria-disabled
                                    label={`${t("waterfall.effectiveExitDate")} *`}
                                    value={effectiveExitDate}
                                    name="effectiveTimeToExit"
                                    disabled
                                    ref={(el: InputValidationRef) => (inputRefs.current.valuationDate = el)}
                                    qaid="Scenarios.Input.ValidationDate"
                                    placeholder="Day/month/year"
                                />
                            </div>

                            <div className="scenario-exit-amount">
                                <NumberInput
                                    label={`${t("waterfall.exitAmount")} *`}
                                    value={scenariosForm.exitValue as number}
                                    onChange={onInputHandler}
                                    disabled={isViewMode}
                                    name="exitValue"
                                    number="float"
                                    multilineLabel
                                    info="waterfall.exitAmountInfo"
                                    error={formValidationState?.exitValue?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.exitValue = el)}
                                    qaid="Scenarios.Input.ExitValue"
                                    placeholder="For example: $175.35M"
                                />
                            </div>

                            <div className="scenario-description">
                                <InputLabel
                                    ap={{
                                        htmlFor: "description",
                                        text: `${t("waterfall.description")} (optional)`,
                                    }}
                                />
                                <TextAreaInput
                                    style={{ resize: "none", height: "100%" }}
                                    rows={5}
                                    maxLength={200}
                                    name="description"
                                    value={scenariosForm.description || undefined}
                                    onChange={(e: any) => {
                                        onInputHandler(e.target.value, e.target.name);
                                    }}
                                    data-qaid="Scenarios.Input.Description"
                                    placeholder="Write something..."
                                />
                            </div>
                        </div>
                    </>
                )}

                <Hr
                    ap={{
                        spacing: "xl",
                    }}
                />

                <WaterfallSubtitle>{t("waterfall.projectReviewers")}</WaterfallSubtitle>
                <Reviewers />
            </div>
        </WaterfallStep>
    );
});

export default observer(Scenarios);
