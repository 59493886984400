import { NumberOption } from "../Models/API/All/NumberOption";
import { EmployeeStatus } from "../Models/API/Expensing/employee-status-enum";
import { CapTablePermission } from "../Models/API/UsersAndPermissions/permissions-enum";
import {
    AwardType,
    CancelationReason,
    CancelDateType,
    CancelType,
    Currencies,
    DataRoomPermissionsLVL,
    EntityTypeEnum,
    OptionScreenTransactionTypesEnum,
    OptionsTransactionTypes,
    OptionTransactionTypesEnum,
    PersonTypesEnum,
    TaxTracks,
    TransactionTypesEnum,
    WarrantsType,
} from "../Models/API/enums";
import i18n from "./Middleware/i18n";

export const customSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        minHeight: "2.5rem",
        boxShadow: "none",
        border: state.isFocused && "none",
    }),

    indicatorsContainer: (provided: any) => ({
        // ...provided,
        paddingTop: "0.8rem !important",
        paddingBottom: "0.8rem !important",

        // paddingTop: '0.2rem !important',
        // paddingBottom: '0.2rem !important',
    }),
    singleValue: (provided: any, state: { isDisabled: any }) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused && "lightgray",
        color: state.isFocused && "red",
    }),
};
export const validExtensions = [".pdf", ".xls", ".xlsx", ".doc", ".docx", ".jpeg", ".jpg", ".png", ".pptx"];
export const validImageExtensions = [".jpeg", ".jpg", ".png"];
export const validExcelExtensions = [".csv", ".xls", ".xlsx"];
export const accessLvlOptions = [
    { value: 0, label: "No Access" },
    { value: 1, label: "View" },
    { value: 2, label: "View + download" },
    { value: 3, label: "Contributor" },
];

export const accessLvlOptions_Options = [
    { value: 0, label: "options.access-level-not-allowed" },
    { value: 1, label: "options.access-level-admin" },
    { value: 2, label: "options.access-level-editor" },
    { value: 5, label: "options.access-level-viewer" },
];

export const accessLvlOptionsCapTable = [
    {
        value: CapTablePermission.no_access,
        label: "options.access-level-no-access",
    },
    {
        value: CapTablePermission.editor,
        label: "options.access-level-editor",
    },
    {
        value: CapTablePermission.full_access,
        label: "options.access-level-viewer",
    },
];

export const accessLvlOptionsDataRoom = [
    {
        value: DataRoomPermissionsLVL.NotAllowed,
        label: "usersAndPermissions.notAllowed",
    },
    {
        value: DataRoomPermissionsLVL.Owner,
        label: "usersAndPermissions.owner",
    },
    {
        value: DataRoomPermissionsLVL.Contributer,
        label: "usersAndPermissions.contributer",
    },
    {
        value: DataRoomPermissionsLVL.Downloader,
        label: "usersAndPermissions.downloader",
    },
    {
        value: DataRoomPermissionsLVL.Viewer,
        label: "usersAndPermissions.viewer",
    },
];

export const accessLvlOptions_FundingSce = [
    { value: 2, label: "Owner" },
    { value: 3, label: "Contributer" },
    { value: 4, label: "Downloader" },
    { value: 5, label: "Viewer" },
];

export const accessLvlOptions_409A = [
    { value: 2, label: "Owner" },
    { value: 3, label: "Contributer" },
    { value: 4, label: "Downloader" },
    { value: 5, label: "Viewer" },
];

export const accessLvlOptions_asC820 = [
    { value: 2, label: "Owner" },
    { value: 3, label: "Contributer" },
    { value: 4, label: "Downloader" },
    { value: 5, label: "Viewer" },
];

export const roleOptions = [
    { value: 3, label: "account.management" },
    { value: 4, label: "account.financeDept" },
    { value: 1, label: "account.legalAdvisor" },
    { value: 5, label: "account.hrDept" },
    { value: 8, label: "account.investor" },
    { value: 7, label: "account.shareholder" },
    { value: 2, label: "account.auditor" },
    // { value: 6, label: "account.employee" },
    // { value: 9, label: "account.other" },
];

export const shareholderRoleOptions = [
    { value: 1, label: "Founder" },
    { value: 2, label: "VC" },
    { value: 3, label: "Advisor" },
    { value: 4, label: "Lawyer" },
    { value: 5, label: "Employee" },
    { value: 6, label: "Ex-employee" },
    { value: 7, label: "Other investor" },
    { value: 8, label: "Exercised options" },
];

export const mobileOptions = [
    { value: "050", label: "050" },
    { value: "051", label: "051" },
    { value: "052", label: "052" },
    { value: "053", label: "053" },
    { value: "054", label: "054" },
    { value: "055", label: "055" },
    { value: "056", label: "056" },
    { value: "057", label: "057" },
    { value: "058", label: "058" },
    { value: "059", label: "059" },
];

export const currenciesOptions = [
    { value: Currencies.USD, label: "USD ($)" },
    { value: Currencies.EUR, label: "EUR (€)" },
    { value: Currencies.JPY, label: "JPY (¥)" },
    { value: Currencies.GBP, label: "GBP (£)" },
    { value: Currencies.ILS, label: "ILS (₪)" },
];

// NOTE: Have the list of currencies localy becuase server developers refused to store symbols in the DB
// So each time the server adds currency it has to be added here as well

export type Currency = {
    currencyId: Currencies;
    label: string;
    symbol: string;
};

export const currencies: Currency[] = [
    { currencyId: Currencies.USD, label: "USD ($)", symbol: "$" },
    { currencyId: Currencies.EUR, label: "EUR (€)", symbol: "€" },
    { currencyId: Currencies.JPY, label: "JPY (¥)", symbol: "¥" },
    { currencyId: Currencies.GBP, label: "GBP (£)", symbol: "£" },
    { currencyId: Currencies.ILS, label: "ILS (₪)", symbol: "₪" },
];

// add rewards to translations

export type TransactionType = null | TransactionTypesEnum;
export const transactionTypes = (hideEmpty: boolean = false) => {
    const arr = [
        { value: 0, label: " " },
        {
            value: TransactionTypesEnum.IssueTransaction,
            label: i18n.t("captable.issue", "Issue"),
        },
        {
            value: TransactionTypesEnum.SecondaryTransaction,
            label: i18n.t("captable.secondary", "Secondary"),
        },
        {
            value: TransactionTypesEnum.ExerciseWarrantsTransaction,
            label: i18n.t("captable.tx-type-ex-awards", "Exercise Awards"),
        },
    ];
    return hideEmpty ? arr.slice(1) : arr;
};

export const optionTransactionSelectList = () => {
    return [
        { value: OptionTransactionTypesEnum.None, label: " " },
        {
            value: OptionTransactionTypesEnum.Pool,
            label: "captable.addPool",
        },
        {
            value: OptionTransactionTypesEnum.Grant,
            label: "captable.addGrant",
        },
        {
            value: OptionTransactionTypesEnum.Exercise,
            label: "captable.addExercise",
        },
        //{ value: OptionTransactionTypesEnum.Sell, label: "Sell" },
        {
            value: OptionTransactionTypesEnum.Cancel,
            label: "captable.cancel",
        },
    ];
};

export const optionSectionTransactionSelectList = [
    { value: OptionTransactionTypesEnum.None, label: "Choose action" },
    { value: OptionTransactionTypesEnum.Exercise, label: "Exercise" },
    { value: OptionTransactionTypesEnum.Sell, label: "Sell" },
    { value: OptionTransactionTypesEnum.Cancel, label: "Cancel" },
];

export const TransactionEntityType = [
    {
        value: EntityTypeEnum.Buyer,
        label: "Buyer",
    },
    {
        value: EntityTypeEnum.Seller,
        label: "Seller",
    },
];

export const PersonTypes = [
    {
        value: PersonTypesEnum.Person,
        label: "Person",
    },
    {
        value: PersonTypesEnum.LegalCompany,
        label: "Legal entity",
    },
];

export type OptionSectionTransactionType = null | OptionScreenTransactionTypesEnum;

export const optionSectiontransactionTypes = [
    { value: null, label: "options.chooseAction" },
    {
        value: OptionsTransactionTypes.Exercise,
        label: "options.exercise",
    },
    {
        value: OptionsTransactionTypes.Sell,
        label: "options.sell",
    },
    {
        value: OptionsTransactionTypes.SameDaySale,
        label: "options.sameDaySale",
    },
    {
        value: OptionsTransactionTypes.Cancelation,
        label: "options.cancel",
    },
];

export const optionSectionAwardTypes = [
    { value: AwardType.RSU, label: "options.rsu" },
    { value: AwardType.Options, label: "options.options" },
    { value: AwardType.AGA, label: "options.Aga-graph" },
    { value: AwardType.BSPCE, label: "options.Bspce-graph" },
];

export const taxTracksTypes = [
    { value: TaxTracks.None, label: "None" },
    { value: TaxTracks.CSOP, label: "CSOP" },
    { value: TaxTracks.EMI, label: "EMI" },
    { value: TaxTracks.ISO, label: "ISO" },
    { value: TaxTracks.NSO, label: "NSO" },
    { value: TaxTracks.CapitalGainTranch_102, label: "102 Capital Gain Tranch" },
    {
        value: TaxTracks.OrdinaryIncomeTranch_102,
        label: "102 Ordinary Income Tranch",
    },
    { value: TaxTracks.Other, label: "Other" },
    { value: TaxTracks.AGA, label: "AGA" },
    { value: TaxTracks.BSPCE, label: "BSPCE" },
];

// TODO: change name
export const optionSectionWarrantsTypes = [
    { value: WarrantsType.None, label: "None" },
    { value: WarrantsType.Warrants, label: "Warrants" },
    { value: WarrantsType.Bsa, label: "BSA" },
];
//

export const currenciesTypes = [
    { value: Currencies.USD, label: "USD" },
    { value: Currencies.EUR, label: "EUR" },
    { value: Currencies.JPY, label: "JPY" },
    { value: Currencies.GBP, label: "GBP" },
    { value: Currencies.ILS, label: "ILS" },
];

export enum ExtraAssetTypes {
    ConvertibleLoan = 992,
    SAFE = 993,
}

export const cancelReasonOptions = [
    { value: CancelationReason.Termination, label: "options.termination" },
    { value: CancelationReason.EndOfGPT, label: "options.endOfGpt" },
    { value: CancelationReason.GrantExpiration, label: "options.grantExpiration" },
    { value: CancelationReason.DueToExercise, label: "options.dueToExercise" },
    { value: CancelationReason.Death, label: "options.death" },
    { value: CancelationReason.Resignation, label: "options.resignation" },
    { value: CancelationReason.Waiver, label: "options.waiver" },
    { value: CancelationReason.Default, label: "options.default" },
    { value: CancelationReason.Retirment, label: "options.retirement" },
];

export const whatToDoOptions = [
    { value: CancelType.Vested, label: "options.cancelVestedOptions" },
    { value: CancelType.Unvested, label: "options.cancelUnvestedOptions" },
    { value: CancelType.VestedAndUnvested, label: "options.cancelUnvestedVestedOptions" },
];

export const graceOptions = [
    { value: CancelDateType.Days, label: "options.days" },
    { value: CancelDateType.Months, label: "options.months" },
    { value: CancelDateType.Years, label: "options.years" },
];

export const employeeStatusOptions: NumberOption[] = [
    {
        label: "Employee",
        value: EmployeeStatus.Employee,
    },
    {
        label: "Ex.Employee",
        value: EmployeeStatus.ExEmployee,
    },
    {
        label: "Director",
        value: EmployeeStatus.Director,
    },
    {
        label: "Officer",
        value: EmployeeStatus.Officer,
    },
    {
        label: "ShareHolder",
        value: EmployeeStatus.ShareHolder,
    },
    {
        label: "Founder",
        value: EmployeeStatus.Founder,
    },
    {
        label: "Service provider",
        value: EmployeeStatus.ServiceProvider,
    },
    {
        label: "Death",
        value: EmployeeStatus.Death,
    },
    {
        label: "Leave of absense",
        value: EmployeeStatus.LeaveOfAbsence,
    },
    {
        label: "Chairman & Advisor",
        value: EmployeeStatus.ChairmanAndAdvisor,
    },
];

export enum adsdsa {
    Employee = 0,
    ExEmployee = 1,
    Director = 2,
    Officer = 3,
    ShareHolder = 4,
    Founder = 5,
    ServiceProvider = 6,
    Death = 7,
    LeaveOfAbsence = 8,
    ChairmanAndAdvisor = 9,
}
