import { css } from "@emotion/css";

export const CompanyDataStyle = css({
	label: "CompanyData",
	display: "flex",
	flexDirection: "column",
	height: "50rem",

	".innerButtons": {
		position: "relative",
	},

	".buttonsContainer": {
		position: "absolute",
		bottom: "20px",
		right: "20px",
	},
});
