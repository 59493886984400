import { ServerResponseApi } from "../Models/API/All/server-response";
import { DocumentSigner, ElectronicSignature } from "../Models/API/Document/eletronics-signature";
import { SendForSingaturePayload } from "../Models/API/Document/send-for-signature-payload";
import { SendForSignatureResponse } from "../Models/API/Document/send-for-signature-response";
import { DocumentTemplateData } from "../Models/API/Document/template-data";
import { DocumentTemplateInfo } from "../Models/API/Document/template-info";
import { DocumentTemplatePayload } from "../Models/App/Document/template-payload";
import { documentsApi, signatureApi } from "./Axios";

export class DocumentsService {
    private companyId: number = 0;

    constructor(companyId: number) {
        this.companyId = companyId;
    }

    // Template

    getTemplates() {
        return documentsApi.get<ServerResponseApi<DocumentTemplateInfo[]>>("/template/details");
    }

    getTemplate(templateId: string, isPreview: boolean = false) {
        return documentsApi.get<ServerResponseApi<DocumentTemplateData>>("/template", { templateId, preview: isPreview });
    }

    createTemplate(template: DocumentTemplatePayload) {
        return documentsApi.post<ServerResponseApi<DocumentTemplateInfo>>("/template", template);
    }

    updateTemplate(template: DocumentTemplatePayload) {
        return documentsApi.put<ServerResponseApi<DocumentTemplateInfo>>("/template", template);
    }

    deleteTemplate(templateId: string) {
        return documentsApi.delete<ServerResponseApi<DocumentTemplateData>>("/template", null, { templateId });
    }

    getDocument(docId: string) {
        return documentsApi.get<ServerResponseApi<DocumentTemplateData>>(`/document/${docId}`);
    }

    getDocuments() {
        return signatureApi.get<ServerResponseApi<{ electronicSignatures: ElectronicSignature[] }>>("/electronicSignature/company");
    }

    signDocuments(documentIds: string[], signerName: string) {
        return signatureApi.post<ServerResponseApi<Omit<DocumentSigner, "id" | "auditTrails">>>("/electronicSignature/sign", {
            documentIds,
            signerName,
        });
    }

    sendForSignature(payload: SendForSingaturePayload) {
        return signatureApi.post<ServerResponseApi<SendForSignatureResponse[]>>("/electronicSignature", payload);
    }

    getDocumentSignatures(docId: string) {
        return signatureApi.get<ServerResponseApi<ElectronicSignature>>(`/electronicSignature/document/${docId}`);
    }
}
