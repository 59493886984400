import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { rootStore } from '../../index';
import { eliminateTimeZone, getSessionStorage } from '../Utilities';

// This line below is needed to preserve the selected date (without deducting the time zone ) when serialized
// eslint-disable-next-line no-extend-native
// Date.prototype.toJSON = function () {
//   return moment(this).format();
// };

const CancelToken = axios.CancelToken;
// const source = axios.CancelToken.source();
export interface CancelTokenDelegate {
	cancelDelegate?: (message?: string) => void;
}
export function createHttpCanceler(ctd: CancelTokenDelegate) {
	const cancelToken = new CancelToken((c) => {
		ctd.cancelDelegate = c;
	});

	return cancelToken;
}
export default function httpClient(headers?: any, apiUrl?: string): AxiosInstance {
	const logRequests = false;
	const logResponses = false;

	let client = axios.create({
		baseURL: apiUrl,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers,
		},
	});
	// axiosRetry(client, { retries: 3 });
	function getUrl(config: AxiosRequestConfig) {
		if (config.baseURL) {
			return config.url?.replace(config.baseURL, '');
		}
		return config.url;
	}
	function getToken(): string | null {
		const token = rootStore.auth.userInfo.jwttoken;
		if (token) {
			return `Bearer ${token}`;
		}
		return null;
	}

	const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

	function isIsoDateString(value: any): boolean {
		return value && typeof value === 'string' && isoDateFormat.test(value);
	}

	function handleDates(body: any) {
		if (body === null || body === undefined || typeof body !== 'object') return body;

		for (const key of Object.keys(body)) {
			const value = body[key];
			if (isIsoDateString(value)) body[key] = new Date(value);
			else if (typeof value === 'object') handleDates(value);
		}
	}

	// Intercept all requests
	client.interceptors.request.use(
		async (config) => {
			const token = await getToken();
			if (token) {
				config.headers.Authorization = token;
				rootStore.appState.updateLastApiCall(new Date());
			}

			if (logRequests) {
				console.log(`%c ${config?.method?.toUpperCase()} - ${getUrl(config)}:`, 'color: #0086b3; font-weight: bold', config);
			}

			config.data = JSON.stringify(config.data, function (key, value) {
				if (Object.prototype.toString.call(this[key]) === '[object Date]') {
					return eliminateTimeZone(this[key]);
				}
				return value;
			});

			//  config.paramsSerializer = (params) => {
			//     return qs.stringify(params, {
			//       serializeDate: (date: Date) =>
			//         format(date, "yyyy-MM-ddThh:mm:ssZ", {
			//           locale: languageDetector(i18next.language),
			//         }),
			//     });
			//   };

			config.headers.common.CompanyId = rootStore.companyStore.companyId;

			return config;
		},
		(error) => Promise.reject(error)
	);
	// Intercept all responses
	client.interceptors.response.use(
		async (response) => {
			if (response) {
				if (response.status === 401) {
					// If there is token then delete it because server returned unathorized anyway
					rootStore.auth.logOut();
					console.trace(
						`%cLogging out due to unauthorized request`,
						'background: red; padding: 4px; color: white; font-weight: bold; font-size: 20px'
					);
				}
				if (logResponses) {
					console.log(`%c ${response.status} - ${getUrl(response.config)}:`, 'color: #008000; font-weight: bold', response);
				}
				handleDates(response.data);
			}
			return response;
		},
		(error) => {
			// const config = error.config
			// // you could defined status you want to retry, such as 503
			// // if (counter < max_time && error.response.status === retry_status_code) {
			// if (counter <= max_time) {
			//   counter++
			//   return new Promise((resolve) => {
			//     setTimeout(() => {

			//       resolve(axios(config));
			//     }, Math.random()*500);
			//   })
			// }

			if (error.response) {
				if (error.response.status === 401) {
					// If there is token then delete it because server returned unathorized anyway
					rootStore.auth.logOut();
					console.log(`%cLogging out due to unauthorized request`, 'background: red; padding: 4px; color: white; font-weight: bold; font-size: 20px');
				}

				console.log(`%c ${error.response.status} - ${getUrl(error.response.config)}:`, 'color: #a71d5d; font-weight: bold', error.response);
				return Promise.reject({ error: error, data: error.response.data });
			} else {
				console.log(`%c ${error}:`, 'color: #a71d5d; font-weight: bold', error);
				return Promise.reject({ error: error, data: null });
			}
		}
	);

	return client;
}
