import { css } from '@emotion/css';
import appConfig from '../../../config/config';
import { breakpoints } from '../../../config/style/theme';

const HeaderStyle = css({
	label: 'Header',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	background: '#ffffff',
	borderBottom: `1px solid ${appConfig.style.colors.table}`,
	flex: '0 0 7.2rem',
	padding: '0 2rem',
	height: '7.2rem',
	zIndex: 11,
	position: 'sticky',
	top: 0,
	[`@media screen and (min-width: ${breakpoints.s})`]: {
		minWidth: '70rem',
		padding: '0 4rem',
	},
	'&__side-a': {
		display: 'flex',
		alignItems: 'center',
		flex: 2,
		gap: '3rem',
		'.logoContainer': {
			[`@media screen and (max-width: ${breakpoints.s})`]: {
				display: 'none',
			},
		},
	},
	'&__side-b': {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: '1.6rem',
	},
	'&__alt-logo': {
		// paddingLeft: "3rem",
		// paddingRight: "1.5rem",
		// width: "14.84rem",
		height: '3rem',
		position: 'relative',
	},
	'&__page-title': {
		// paddingLeft: "1.5rem",
		color: appConfig.style.colors.color1,
		fontSize: '2.5rem',
		fontFamily: appConfig.style.fonts.alt,
		userSelect: 'none',
	},
	'&__separator': {
		width: 1,
		height: '1.65rem',
		background: appConfig.style.colors.text2,
	},
	'&__user-circle': {
		background: appConfig.style.colors.gradientOrange,
		borderRadius: '3.5rem',
		paddingTop: '0.2rem',
		flex: '0 0 3.5rem',
		height: '3.5rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textTransform: 'capitalize',
		color: '#ffffff',
		fontFamily: appConfig.style.fonts.alt,
		fontSize: '1.6rem',
		width: '3.5rem',
		boxShadow: appConfig.style.boxShadow.light,
		userSelect: 'none',
	},
	'&__select': {
		flex: '0 0 21rem',
		'.select': {
			borderColor: '#ACB2CC',
		},
	},
	'&__nav-menu-container': {
		position: 'relative',
		'.menu': {
			width: '2.8rem',
		},
		'.company-menu': {
			width: '4.1rem',
		},
	},
	'&__nav-menu': {
		position: 'absolute',
		zIndex: 1239,
		background: '#ffffff',
		padding: '2rem',
		boxShadow: `3px 3px 16px 0px #ABAFC661`,
		borderRadius: 8,
		display: 'flex',
		width: 'max-content',
		flexDirection: 'column',
		gap: '2rem',
		maxHeight: 'calc(100vh - 10rem)',
		overflow: 'auto',

		'&:not(.company)': {
			top: 'calc(100% + 2rem)',
			left: '-4rem',
			'&::after': {
				content: "''",
				borderStyle: 'solid',
				borderWidth: ' 0.6rem',
				position: 'absolute',
				top: '-0.5rem',
				left: '4.9rem',
				border: '0.6rem solid black',
				borderColor: ' transparent transparent #ffffff #ffffff',
				// transform-origin: 0 0,
				boxShadow: '-0.3rem 0.3rem 0.3rem 0.1rem rgb(0 0 0 / 7%)',
				transform: 'rotate(135deg)',
			},
		},
		'&.company': {
			top: 'calc(100% + 1rem)',
			left: '0',
			width: '23rem',
			transform: 'translate(-17rem, 0)',
			img: {
				width: '3.8rem',
			},
		},
	},
	'&__nav-menu-item': {
		display: 'flex',
		alignItems: 'center',
		userSelect: 'none',

		img: {
			width: '5rem',
			height: '5rem',
		},

		'&.comingSoon': {
			opacity: 0.5,
		},
	},
	'&__nav-menu-item-text': {
		borderRadius: appConfig.style.borderRadius.medium,
		transition: 'all .2s',
		// flex: 1,
		'&:not(.company)': {
			// padding: "0.8rem 1.2rem",
		},
	},
	'&__active': {
		marginTop: '0.4rem',
		borderRadius: 4,
		height: 3,
		// width: "12.5rem",
		width: '100%',
		background: 'linear-gradient(105.21deg, #8B96E9 22.77%, #F2AB64 43.36%, #F6818F 65.65%, #F5DC82 89.48%)',
	},
	'&__menu-clicked': {
		width: '4.5rem',
		height: '4.5rem',
		position: 'absolute',
		top: '-0.88rem',
		left: '-0.88rem',
		zIndex: -1,
		background: appConfig.style.colors.background1,
		borderRadius: 8,
		transition: 'background .3s',
	},
	'&__disabled': {
		opacity: 0.5,
	},
});

export default HeaderStyle;
