import { observer } from "mobx-react-lite";
import { PagedTableKeys } from "./PagedTable";
import { formatDate, formatNumber } from "../../../Utilities";

import tableStyle from "./PagedTable.module.css";

interface BodyProps<T> {
    data: T[];
    headers: PagedTableKeys<T>[];
    onRowClick?: (obj: T) => any;
}

const BodyRow = observer(<T extends object, U extends keyof T>(props: BodyProps<T>) => {
    // console.log('bodyRowProps', props)

    // const objkeys = props.headers.map(obj => obj.key.toString())
    // const filteredColumns = props.data?.map((row) => {
    // const ffKey = Object.keys(row)
    // let newRow = objkeys.map(oKey => {
    //     if (oKey in row) {
    //         return row.oKey
    //     }
    //     if (row.hasOwnProperty(oKey)) {
    //          return row.oKey
    //     }
    // });
    // let newRow = objkeys.map(oKey => oKey in row);
    // console.log('newRow', newRow)
    // })
    // const filteredColumns = objkeys.map(key => props.headers.filter(hd => hd.key === key))

    const rowClickHandler = (e: any) => {
        props.onRowClick && props.onRowClick(e)
    }

    const isoTest = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;

    const renderDtuff = (value: any) => {
        if (typeof value === "number") {
            return formatNumber(value);
        } else if (isoTest.test(value)) {
            return formatDate(value);
        } else {
            return <>{value}</>;
        }
    };

    if (!Array.isArray(props?.data)) {
        return <></>;
    } else {
        return (
            <>
                {props?.data.map((rowData, i) => {
                    const rowValues = Object.values(rowData);

                    return (
                        <tr
                            key={i}
                            onClick={(e) => rowClickHandler(rowData)}
                            className={tableStyle.tableRow}
                        >
                            {rowValues.map((val, x) => {
                                return (
                                    <td key={x + i * 10}>
                                        {renderDtuff(val)}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </>
        );
    }
});

export default BodyRow;
