import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

export const StepTitle = css({
	color: appConfig.style.colors.background2,
	fontSize: "2.5rem",
	fontWeight: "500",
	marginBottom: "5rem",

	"&__subTitle": {
		fontSize: "1.4rem",
		fontWeight: "700",
		color: appConfig.style.colors.background2,
	},
	"&__award-title": {
		display: "flex",
		alignItems: "center",
		gap: "1.2rem",
		marginBottom: "1.6rem",
	},
});

export const StepDivider = css({
	width: "100%",
	height: "1px",
	color: appConfig.style.colors.table,
	margin: "2.4rem 0",
});

export const NavButtons = css({
	position: "absolute",
	bottom: "20px",
	right: "20px",
});

const RegistrationStyle = css({
	label: "Registration",
	padding: "6rem 7.7rem 6.7rem 7.7rem",
});

export default RegistrationStyle;
