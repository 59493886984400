import { toJS } from "mobx";
import { IOrder } from "../../../../Models/App/EquityPlans/Order";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import useModal from "../../../../Shared/Hooks/useModal";
import SectionStyles from "../../General/styles/SectionStyles";
import { useEffect, useState } from "react";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { IGrantCancelation, IGrantCancelationTable } from "../../../../Models/App/EquityPlans/GrantCancelations";
import NewCancelation from "./NewGrantCancelation/NewGrantCancelation";
import { cancelationReason, cancelationType } from "../../../../Shared/StaticData/equityPlans";
import CancelationCard from "./CancelationCard";
import { useHistory } from "react-router-dom";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import useEsopModals from "../../useEsopModals";
import { formatDate } from "../../../../Shared/Utilities";
// import OrderRow from "./OrderRow/OrderRow";
// import NewOrder from "./NewOrder/NewOrder";

interface CancelationProps {
    rtd: () => void;
}

const Cancelations = (props: CancelationProps) => {
    const history = useHistory();

    const { equityPlansStore } = useRootStore();
    const { showModal } = useModal();
    const { showCancelationModal } = useEsopModals();

    const [tableRows, setTableRows] = useState<IGrantCancelationTable[]>([]);

    useEffect(() => {
        equityPlansStore.GetGrantCancelationsForCompany();
    }, []);

    useEffect(() => {
        equityPlansStore.GetGrantCancelationsForCompany();
    }, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

    const tableColumns: TableColumn<IGrantCancelation>[] = [
        { label: "Cancelation number", name: "grantCancelationId" },
        { label: "Employee number", name: "employeeNumber" },
        {
            label: "Full name",
            name: "fullName",
            format(val) {
                return `${val.firstName} ${val.lastName}`;
            },
        },
        { label: "Grant number", name: "grantNumber" },
        {
            label: "Cancel type",
            name: "cancelType",
            format(val, obj) {
                if (!obj) return;
                return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
            },
        },
        {
            label: "Reason",
            name: "cancelationReason",
            format(val, obj) {
                if (!obj) return;
                return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
            },
        },
        {
            label: "Date",
            name: "cancelationDate",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amountToCancel" },
    ];

    // const generateTableRows = () => {
    // 	if (!equityPlansStore.companyCancelations) return []
    // 	const rows = equityPlansStore.companyCancelations.map(row => {

    // 		const modRow: IGrantCancelationTable = {
    // 			...row,
    // 			cancelationReason: (row.cancelationReason + 10).toString(),
    // 			cancelType: cancelationType.filter(ctyp => row.cancelType === ctyp.value)[0].label,
    // 			gracePeriod: (row.gracePeriodNum + row.gracePeriodType).toString(),
    // 			cancelationDate: row.cancelationDate.toString()
    // 		}
    // 		return modRow;
    // 	})

    // 	setTableRows(rows)
    // }

    const newCancelationHandler = () => {
        showModal({
            title: "Create New Cancelation Order",
            body: <NewCancelation showBen={true} showGrant />,
            width: "60%",
        });
    };

    const multiDeleteHandler = () => {};

    // useEffect(() => {
    // 	generateTableRows()
    // }, [equityPlansStore.companyCancelations])

    // useEffect(() => {
    // 	generateTableRows()
    // }, [])

    const openCancelationModal = (row: IGrantCancelation) => {
        showModal({
            title: `Order # ${row?.grantCancelationId}`,
            body: <CancelationCard />,
            height: "100%",
            width: "70%",
            isMust: true,
        });
    };

    return (
        <div className={SectionStyles}>
            <div className="section-header">
                <Button qaid="" inverse onClick={history.goBack}>
                    Go back
                </Button>
                {/* <h1>Cancelations</h1> */}
            </div>
            <div className="section-table">
                {/* <div className="table-actions">
					<Button qaid="" inverse label="Add Grant Cancelation" onClick={newCancelationHandler} />
				</div> */}

                <Table
                    columns={tableColumns}
                    rows={equityPlansStore.companyCancelations}
                    // color="secondary"
                    scrollAfterRows={13}
                    // selectBy="grantCancelationId"
                    // onRowSelect={() => {}}
                    // actions={[
                    // 	{
                    // 		label: 'Delete',
                    // 		onClick: multiDeleteHandler,
                    // 		qaid: "Order.Button.Delete"
                    // 	}
                    // ]}
                    // onExpandRender={(row) => {
                    // 	equityPlansStore.GetBeneficiaryOrder(row.grantCancelationId);
                    // 	return <>GGG</>
                    // 	// return <OrderRow row={row} />;
                    // }}
                    // onRowClick={(row) => {
                    // 	equityPlansStore.GetGrantCancelation(row.grantCancelationId);
                    // 	openCancelationModal(row);
                    // }}
                    showTotal={false}
                    // onRowSelect={(row) => {
                    //     return <>{row.}</>
                    //     // setInvitationArr(invi)
                    //     // console.log(toJs(row))
                    // }}
                    // oneditrender
                    filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof IGrantCancelation)}
                    searchBy={["amountToCancel"]}
                    exportToExcel={{ fileName: "Cancelations", sheetName: "Cancelations" }}
                    fullscreen
                    renderBottom={
                        <Flex align="center" justify="start">
                            <AddButton onClick={() => showCancelationModal(true, true)} qaid="" label="Add new grant cancelation" />
                        </Flex>
                    }
                />
            </div>
        </div>
    );
};

export default observer(Cancelations);
