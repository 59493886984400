import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import useComplexState from "../../../../Shared/Hooks/useComplexState";
import { IMG_LOGIN_LOCK } from "../../../../Assets";
import TextInput from "../../../../Shared/Components/TextInput";
import { useObjectValidation } from "../../../../Shared/Hooks/useObjectValidation";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { t } from "i18next";
import { Routes } from "../../../../Routes";
import { useEffect, useRef, useState } from "react";
import Button from "../../../../Shared/Components/Button/Button";
import { UserStatus } from "../../../../Models/API/enums";
import classNames from "classnames";
import ErrorMessage from "../../../../Shared/Components/Layout/ErrorMessage";
import Flex from "../../../../Shared/Components/Layout/Flex";
import useScreenSize from "../../../../Shared/Hooks/useScreenSize";
import useRecaptcha from "../../../../Shared/Hooks/useRecaptcha";
import Image from "../../../../Shared/Components/Image";

interface OtpProps {
    error?: string;
    isLoading?: boolean;
    isLogin?: boolean;
}
interface UserData {
    Otp?: string;
}

export const AccountLoginOTP = (props: OtpProps) => {
    const { auth } = useRootStore();
    const { isMobile, isTablet } = useScreenSize();
    const [userData, setUserData] = useComplexState<UserData>({});
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState<JSX.Element | string>();
    const { otpguid }: { otpguid: string } = useParams();
    const location = useLocation();
    const isOtpLoginInvitation = location.pathname.includes(Routes.account.otpLoginInvitation);
    const [isValidUser, userValState] = useObjectValidation(userData, {
        Otp: [commonValidators.required(), commonValidators.minLength(6), commonValidators.maxLength(6)],
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const mounted = useRef(true);
    const { execute } = useRecaptcha();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const cleanup = () => {
            mounted.current = false;
            setIsLoading(false);
        };

        return cleanup;
    }, []);

    const submitOtp = async () => {
        if (!userData.Otp) return;

        setIsLoading(true);
        const res = await auth.validateOtp(userData.Otp, otpguid);

        switch (auth.userInfo.status) {
            case UserStatus.PendingForCompanyRegist:
                history.push(Routes.account.companySetup);
                return;
            case UserStatus.PendingForPaymentPlan:
                history.push(Routes.payment.pricing);
                return;
        }

        switch (res?.status) {
            case 401:
                setErrorMsg("Wrong one-time password");
                setIsLoading(false);
                return;
            case 500:
                setErrorMsg("There is a problem with your account. Please contact support.");
                setIsLoading(false);

                return;
        }

        setIsLoading(false);
    };

    const resendOTP = async (guid: string) => {
        execute(async (token) => {
            const res = await auth.resendOTP(guid, token);

            if (res?.isSuccess) {
                history.push(Routes.account.otpLogin + "/" + res.data?.guid);
            } else {
                setErrorMsg(t("loginAndRegister.login-resend-otp-failed"));
            }
        });
    };

    useEffect(() => {
        if (!mounted.current) return;

        if (!userData.Otp) return;

        if (userData.Otp?.length === 6) {
            setErrorMsg(undefined);
            inputRef.current?.blur();
            submitOtp();
        }
    }, [userData.Otp]);

    return (
        <section className={classNames("form-account-box login-box", { mobile: isMobile })}>
            <>
                <div
                    className={classNames({
                        "form-header": true,
                        mobile: isMobile,
                    })}
                >
                    <div className={classNames("form-login-title", { otp: true, mobile: isMobile })}>
                        A one-time password has been sent to your {isOtpLoginInvitation ? "private" : "business"} email
                    </div>
                </div>
                <Flex
                    direction="column"
                    width={"100%"}
                    className={classNames({
                        "login-account-form": true,
                        mobile: isMobile,
                        tablet: isTablet,
                    })}
                >
                    <Image
                        className="ic"
                        src={IMG_LOGIN_LOCK}
                        alt="lock"
                        width="9.6rem"
                    />

                    <div className="input-error-container">
                        <TextInput
                            className="account-input"
                            qaid="AccountLoginOTP.Input.Otp"
                            value={userData.Otp}
                            hasError={!!errorMsg}
                            autoFocus
                            placeholder="Enter the one-time password"
                            maxLength={6}
                            ref={inputRef}
                            onChange={(e) => {
                                setErrorMsg(undefined);
                                setUserData({ Otp: e });
                            }}
                        />
                        <ErrorMessage
                            top={"5rem"}
                            left={"1.2rem"}
                        >
                            {errorMsg}
                        </ErrorMessage>
                    </div>

                    <Button
                        qaid="AccountLoginOTP.Button.Verify"
                        disabled={!isValidUser}
                        position="center"
                        isLoading={isLoading}
                        onClick={async () => {
                            await submitOtp();
                        }}
                        className={classNames(
                            {
                                "account-orange-butt--disabled": !isValidUser,
                            },
                            "account-orange-butt",
                            "account-orange-butt--no-gap"
                        )}
                    >
                        Verify
                    </Button>
                </Flex>
            </>

            <div className="account-footer">
                <div>
                    <Link
                        className="account-link"
                        key="registration"
                        to="#"
                        onClick={() => {
                            resendOTP(otpguid);
                        }}
                    >
                        Resend one-time password
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default AccountLoginOTP;
