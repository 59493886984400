import Axios, { AxiosError, AxiosInstance, AxiosResponse as AxiosResponse2 } from "axios";
import { rootStore } from "../index";
import { eliminateTimeZone, getSessionStorage } from "../Shared/Utilities";

type AxiosMethods = "GET" | "POST" | "DELETE" | "PUT" | "PATCH";

export type AxiosResponse<T> = Promise<HigherLevelResponse<T> | HighLevelErrorResponse>;

export type HigherLevelResponse<T> = {
    data: T;
    error: undefined;
    errorMessage?: string;
    statusCode?: number;
    errorCode?: undefined;
    isSuccess: true;
};

export type HighLevelErrorResponse = {
    data: undefined;
    error: AxiosError;
    errorMessage: string;
    statusCode: number;
    errorCode: number;
    isSuccess: false;
};



export interface CustomAxiosInstance<T> {
	get: (url: string) => Promise<T>;
	post: (url: string, data?: object | null, headers?: object | null) => Promise<T>;
	delete: (url: string, data?: object | null) => Promise<T>;
	put: (url: string, data?: object | null) => Promise<T>;
	patch: (url: string, data?: object | null) => Promise<T>;
	setToken: (token: string) => void;
	clearToken: () => void;
}

type RequestProps = {
	url: string;
	method: AxiosMethods;
	data?: object | null;
	headers?: object | null;
	params?: any;
};

export enum NetworkStatusCode {
	'OK' = 200,
	'CREATED' = 201,
	'NO_CONTENT' = 204,
	'BAD_REQUEST' = 400,
	'UNAUTHORIZED' = 401,
	'PAYMENT_REQUIRED' = 402,
	'FORBIDDEN' = 403,
	'NOT_FOUND' = 404,
	'NOT_ALLOWED' = 405,
	'CONFLICT' = 409,
	'TRUSTEE_UNAUTHORIZED' = 420,
	'UNPROCESSABLE_ENTITY' = 422,
	'INTERNAL' = 500,
}

interface ErrorResponse {
	traceId: number | null;
	responseObject: object | null; // Daniel - to get the interface
	statusCode: number;
	message: string;
	errorCode: number;
}

export enum ContentTypeEnum {
	JSON = 'application/json',
	FormData = 'multipart/form-data',
}

const AUTH_KEY = 'auth_token';
const TOKEN_PREFIX = 'Bearer';

const createAxiosInstance = (baseURL: string = '') => {
	let token = getSessionStorage(AUTH_KEY);
	const sessionToken = `${TOKEN_PREFIX} ${token}`;
	const axiosHeaders = sessionToken
		? {
				Authorization: sessionToken,
		  }
		: {};

	const axios: AxiosInstance = Axios.create({
		baseURL,
		headers: { ...axiosHeaders, 'Content-Type': ContentTypeEnum.JSON },
	});

	const setToken = (t?: string) => {
		token = t;
		axios.interceptors.request.use(
			(config) => {
				config.headers = t
					? {
							Authorization: `${TOKEN_PREFIX} ${t}`,
					  }
					: {};
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	};

	const clearToken = () => {
		axios.interceptors.request.use((config) => {
			config.headers = {};
			return config;
		});
	};

	axios.interceptors.request.use(
		(req) => {
			if (!token) {
				// happens once after after the first API call as logged in, if there isn't token (was logged out and then logged in), update the token
				token = rootStore.auth.authToken as string;
				rootStore.appState.updateLastApiCall(new Date());
			}
			if (token) {
				// if token exists, set is in header
				req.headers.Authorization = `${TOKEN_PREFIX} ${token || (rootStore.auth.authToken as string)}`;
				req.headers.Companyid = rootStore.companyStore.companyId;
			}

			if (req.headers['Content-Type'] === ContentTypeEnum.JSON) {
				req.data = JSON.stringify(req.data, function (key, value) {
					if (Object.prototype.toString.call(this[key]) === '[object Date]') {
						return eliminateTimeZone(this[key]);
					}
					return value;
				});
			}

			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

    const ajax = async <T>({
		url,
		method = 'GET',
		data = null,
		headers = {},
		params = null,
	}: RequestProps): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> => {
		try {
			const res = await axios({ url, method, data, headers, params });

			return {
				data: res.data,
				error: undefined,
				errorMessage: undefined,
				errorCode: undefined,
				statusCode: undefined,
				isSuccess: true,
			};
		} catch (error: any) {
			if (!error.response) {
				// CORS issue
				return {
					data: undefined,
					error: error.response,
					statusCode: 500,
					errorCode: 0,
					errorMessage: 'Server CORS Error',
					isSuccess: false,
				};
			}

			if (error.response.status === NetworkStatusCode.UNAUTHORIZED) {
				rootStore.auth.logOut(); // Logout if 401
				console.log(`%cLogging out due to unauthorized request`, 'background: red; padding: 4px; color: white; font-weight: bold; font-size: 20px');
			}

			if (error.response.status === NetworkStatusCode.TRUSTEE_UNAUTHORIZED) {
				rootStore.trusteeStore.setRegistration = true;
			}

			if (error.response.status === NetworkStatusCode.PAYMENT_REQUIRED) {
				rootStore.companyStore.setPlanLimitation({
					show: true,
					limitCode: error.data.limitCode,
				});
			}

			if (error.response.status === NetworkStatusCode.INTERNAL) {
				rootStore.appState.setErrorCode(error.response.data.errorCode);
			}

			const errorMessage =
				error.response.data?.message ||
				error.title ||
				error.message ||
				(error.statusCode === NetworkStatusCode.INTERNAL || error.response.data?.statusCode === NetworkStatusCode.INTERNAL
					? `${NetworkStatusCode.INTERNAL} (Internal Server Error)`
					: null) ||
				error.Description ||
				'Server error';

			return {
				data: undefined,
				error: error.response,
				errorMessage,
				errorCode: error.response.data.errorCode,
				statusCode: error.response.status,
				isSuccess: false,
			};
			// throw message;
		}
	};

	const methods = {
		get<T>(url: string, params?: any): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> {
			return ajax<T>({ url, method: 'GET', params });
		},
		post<T>(url: string, data?: object | null, headers?: object | null, params?: any): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> {
			return ajax<T>({ url, method: 'POST', data, params, headers });
		},
		put<T>(url: string, data?: object | null, params?: any): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> {
			return ajax<T>({ url, method: 'PUT', data, params });
		},
		patch<T>(url: string, data?: object | null, params?: any): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> {
			return ajax<T>({ url, method: 'PATCH', data, params });
		},
		delete<T>(url: string, data?: object | null, params?: any): Promise<HigherLevelResponse<T> | HighLevelErrorResponse> {
			return ajax<T>({ url, method: 'DELETE', data, params });
		},
	};

	return {
		...methods,
		setToken,
		clearToken,
	};
};

export const waterfallApi = createAxiosInstance(process.env.REACT_APP_API_URL_WATERFALL);
export const optionsApi = createAxiosInstance(process.env.REACT_APP_API_URL_OPTIONS);
export const equityPlansApi = createAxiosInstance(process.env.REACT_APP_API_URL_OPTIONS);
export const shareRepApi = createAxiosInstance(process.env.REACT_APP_API_URL_SHAREREP);
export const loginApi = createAxiosInstance(process.env.REACT_APP_API_URL_LOGIN);
export const userApi = createAxiosInstance(process.env.REACT_APP_API_URL_USER_MANAGEMENT);
export const pivotalManagmentApi = createAxiosInstance(process.env.REACT_APP_API_URL_PIVOTAL);
export const companyApi = createAxiosInstance(process.env.REACT_APP_API_URL_COMPANY);
export const expensingApi = createAxiosInstance(process.env.REACT_APP_API_URL_EXPENSING);
export const documentsApi = createAxiosInstance(process.env.REACT_APP_API_URL_DOCS);
export const signatureApi = createAxiosInstance(process.env.REACT_APP_API_URL_SIGNATURE);
export const paymentApi = createAxiosInstance(process.env.REACT_APP_API_URL_PAYMENT);

export const setInstancesToken = (token: string) => {
	waterfallApi.setToken(token);
	equityPlansApi.setToken(token);
	optionsApi.setToken(token);
	shareRepApi.setToken(token);
	loginApi.setToken(token);
	userApi.setToken(token);
	expensingApi.setToken(token);
	documentsApi.setToken(token);
	signatureApi.setToken(token);
	companyApi.setToken(token);
	paymentApi.setToken(token);
};

export const clearInstancesToken = () => {
	waterfallApi.clearToken();
	equityPlansApi.clearToken();
	optionsApi.clearToken();
	shareRepApi.clearToken();
	loginApi.clearToken();
	userApi.clearToken();
	expensingApi.clearToken();
	documentsApi.clearToken();
	signatureApi.clearToken();
	companyApi.clearToken();
	paymentApi.clearToken();
};

// export const axiosEmptyResponse = () => ({
//     data: undefined,
//     error: undefined,
//     errorMessage: undefined,
//     errorCode: undefined,
//     statusCode: undefined,
// });
