import { css } from "@emotion/css";
import Styled from "@emotion/styled";
import classNames from "classnames";
import appConfig from "../../../config/config";
import { CSSProperties } from "react";

// const Style = css({
// 	label: "SwitchToggle",
// 	border: `1px solid ${appConfig.style.colors.color1}`,
// 	height: "3.5rem",
// 	minHeight: "3.5rem",
// 	borderRadius: 30,
// 	width: "28.7rem",
// 	overflow: "hidden",
// 	alignItems: "center",
// 	userSelect: "none",
// 	boxShadow: appConfig.style.boxShadow.light,
// 	"&.small": {
// 		width: "20rem",
// 		fontSize: "1.2rem",
// 	},
// 	"&:before": {
// 		transition: "all .3s",
// 		width: "calc(50% + 0.5rem)",
// 		position: "absolute",
// 		height: "100%",
// 		background: appConfig.style.colors.gradientPurpleVertical,
// 	},
// 	"&__option": {
// 		flex: 1,
// 		display: "flex",
// 		justifyContent: "center",
// 		height: "100%",
// 		alignItems: "center",
// 		borderRadius: 30,
// 		transition: "all .25s",
// 		color: appConfig.style.colors.color1,
// 		position: "absolute",
// 		width: "calc(50% + 0.5rem)",
// 		"&.active": {
// 			color: "#ffffff",
// 		},
// 	},
// 	"&__person": {
// 		left: 0,
// 	},
// 	"&__entity": {
// 		right: 0,
// 	},
// 	".switch": {
// 		cursor: "pointer",
// 		position: "relative",
// 		height: "100%",
// 		width: "100%",
// 		display: "inline-block",
// 	},
// 	"#switch": {
// 		display: "none",
// 		"+ .switch": {
// 			"&::before": {
// 				boxShadow: appConfig.style.boxShadow.light,
// 				transition: "0.3s",
// 				content: "''",
// 				display: "block",
// 				width: "calc(50% + 0.5rem)",
// 				height: "100%",
// 				position: "absolute",
// 				left: 0,
// 				top: 0,
// 				background: appConfig.style.colors.gradientPurpleVertical,
// 				borderRadius: 30,
// 				border: `1.5px solid ${appConfig.style.colors.color1}`,
// 			},
// 		},
// 		"&:checked": {
// 			"+ .switch": {
// 				"&::before": {
// 					left: "calc(50% - 0.5rem)",
// 				},
// 			},
// 		},
// 	},
// 	"&.secondary": {
// 		"&__option": {
// 			color: "red",
// 		},
// 		"#switch": {
// 			"+ .switch": {
// 				"&::before": {
// 					boxShadow: appConfig.style.boxShadow.light,
// 					transition: "0.3s",
// 					content: "''",
// 					display: "block",
// 					width: "calc(50% + 0.5rem)",
// 					height: "100%",
// 					position: "absolute",
// 					left: 0,
// 					top: 0,
// 					background: "white",
// 					borderRadius: 30,
// 					border: `1.5px solid ${appConfig.style.colors.color1}`,
// 				},
// 			},
// 		},
// 	},
// });

const ToggleWrapper = Styled.div(
    {
        label: "SwitchToggle",
        borderRadius: 30,
        width: "28.7rem",
        overflow: "hidden",
        alignItems: "center",
        userSelect: "none",
        boxShadow: appConfig.style.boxShadow.light,
        "&.disabled": {
            opacity: 0.7,
        },
        ".switch": {
            cursor: "pointer",
            position: "relative",
            height: "100%",
            width: "100%",
            display: "inline-block",
        },
        "#switch": {
            display: "none",
            "+ .switch": {
                "&::before": {
                    boxShadow: appConfig.style.boxShadow.light,
                    transition: "0.3s",
                    content: "''",
                    display: "block",
                    width: "calc(50% + 0.5rem)",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    background: appConfig.style.colors.gradientPurpleVertical,
                    borderRadius: 30,
                    border: `1.5px solid ${appConfig.style.colors.color1}`,
                },
            },
            "&:checked": {
                "+ .switch": {
                    "&::before": {
                        left: "calc(50% - 0.5rem)",
                    },
                },
            },
        },
    },
    ({ size, type }: Partial<Props>) => ({
        width: size === "sm" ? "20rem" : "28.7rem",
        border: `1px solid ${type === "primary" ? appConfig.style.colors.color1 : appConfig.style.colors.text3}`,
        height: size === "sm" ? "3rem" : "3.5rem",
        minHeight: size === "sm" ? "3rem" : "3.5rem",
        ".switch": {
            background: type === "primary" ? "#ffffff" : appConfig.style.colors.background1,
        },
        "#switch": {
            display: "none",
            "+ .switch": {
                "&::before": {
                    // boxShadow: appConfig.style.boxShadow.light,
                    background:
                        type === "primary"
                            ? appConfig.style.colors.gradientPurpleVertical
                            : "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(160,163,176,0.05) 100%);",
                    border: `1.5px solid ${appConfig.style.colors.color1}`,
                },
            },
        },
    })
);

const Option = Styled.div(
    {
        label: "ToggleOption",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        borderRadius: 30,
        transition: "all .25s",
        position: "absolute",
        width: "calc(50% + 0.5rem)",
        left: 0,
        // marginTop: "0.1rem",
        "&:last-child": {
            right: 0,
            left: "unset",
        },
    },
    ({ type, isActive, size }: OptionProps) => ({
        fontSize: size === "sm" ? "1.2rem" : "inherit",
        paddingTop: size === "sm" ? 1 : 0,
        color:
            type === "primary"
                ? isActive
                    ? "#ffffff"
                    : appConfig.style.colors.color1
                : isActive
                ? appConfig.style.colors.color1
                : appConfig.style.colors.text2,
    })
);

type Action = {
    label: string;
    value: boolean;
};

interface Props {
    value: any;
    actions: [Action, Action];
    onChange?: (value: boolean) => void;
    className?: string;
    disabled?: boolean;
    size?: "sm" | "md";
    type?: "primary" | "secondary";
    style?: CSSProperties;
}

interface OptionProps extends Pick<Props, "type" | "size"> {
    isActive: boolean;
}

const SwitchToggle = ({ value = true, onChange, className = "", disabled, actions, size = "md", type = "primary", style }: Props) => {
    return (
        <ToggleWrapper
            className={classNames({ [className]: !!className, disabled })}
            size={size}
            type={type}
            style={style}
        >
            <input
                type="checkbox"
                id="switch"
                name="switch"
                checked={value}
                onChange={() => {}}
            />
            <label
                htmlFor="switch"
                className="switch"
            >
                <Option
                    size={size}
                    type={type}
                    isActive={value === actions[0].value}
                    onClick={() => !disabled && onChange?.(actions[0].value)}
                    data-qaid="SwitchToggle.Button.Left"
                >
                    {actions[0].label}
                </Option>
                <Option
                    size={size}
                    type={type}
                    isActive={value === actions[1].value}
                    // className={classNames(`${ToggleWrapper}__option`, `${ToggleWrapper}__entity`, { active: value === actions[1].value })}
                    onClick={() => !disabled && onChange?.(actions[1].value)}
                    data-qaid="SwitchToggle.Button.Right"
                >
                    {actions[1].label}
                </Option>
            </label>
        </ToggleWrapper>
    );
};

export default SwitchToggle;
