export const Routes = {
    dashboard: {
        index: "/",
    },
    dataRoom: {
        overview: "/data-room",
        room: "/data-room/room/",
    },
    capTable: {
        index: "/cap-table",
        import: "/cap-table/import",
    },
    funding: {
        index: "/fundings",
        old: "/funding-old",
    },
    usersAndPermission: {
        index: "/users",
        old: "/users-old",
    },
    options: {
        index: "/options",
    },
    options7: {
        index: "/ESOP",
        orders: "/ESOP/orders",
        grants: "/ESOP/grants",
        beneficiaries: "/ESOP/beneficiaries",
        cancelations: "/ESOP/cancelations",
        dashboard: "/ESOP",
    },
    trusteeData: {
        index: "/trustee",
        submitFiles: "/trustee/submit_file",
        reports: "/trustee/reports",
        forms: "/trustee/forms",
        grantDocuments: "/trustee/grantDocuments",
        orders: "/trustee/orders",
        cancelations: "/trustee/cancelations"
    },
    waterfall: {
        index: "/waterfall",
    },
    shareRep: {
        index: "/shareRep",
    },
    payment: {
        pricing: "/pricing",
        paymentPay: "/payment-pay",
    },
    account: {
        login: "/account/login",
        accountRegistration: "/account/registration",
        accountSetup: "/account/setup",
        companySetup: "/account/companySetup",
        forgotPass: "/account/forgotPassword",
        resetPass: "/resetPassword",
        invite: "/account/invitation",
        inviteEmp: "/account/invitation/emp",
        inviteEmpExsistUser: "/account/login/invitation",
        otpLogin: "/account/otp-login",
        otpLoginInvitation: "/account/otp-login/invitation",
        otpReg: "/account/otp-reg",
        companyProfile: "/account/company-profile",
        createCompanyProfile: "/account/create-company-profile",
        employeeVerification: "/account/verification",
        completeRegisration: "/account/complete-registration",
    },
    settings: {
        companyProfile: "/settings/company-profile",
    },
    privacy: "/generalDocuments/PrivacyPolicyV1.pdf",
    tos: "/generalDocuments/TermsOfServiceV1.pdf",
    contactUs: {
        external: "https://altshare.com/contact-us-saas",
        index: "/contact-us",
    },
    marketing: {
        index: "https://altshare.com",
    },
    expensing: {
        index: "/expensing",
        settings: "/expensing/settings",
        reportSettings: "/expensing/report-settings",
        blackAndScholes: "/expensing/bs-parameters",
    },
    docs: {
        index: "/docs",
    },
} as const;
