import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { IC_CHECK_CIRCLE_PURPLE, IC_CHECK_PURPLE, IC_EDIT2, IC_TRASH2, IC_X_CANCEL_CIRCLE } from "../../../../../../Assets";
import { NumberOption } from "../../../../../../Models/API/All/NumberOption";
import { PayoffSelectionOptions, PreferenceShareClassRight } from "../../../../../../Models/API/Waterfall/IForm";
import CheckBox from "../../../../../../Shared/Components/CheckBox/CheckBox";
import Clickable from "../../../../../../Shared/Components/Clickable/Clickable";
import Image from "../../../../../../Shared/Components/Image";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import QuestionCard from "../../../../../../Shared/Components/QuestionCard/QuestionCard";
import RadioButton, { RadioButtonOptions } from "../../../../../../Shared/Components/RadioButton/RadioButton";
import { TableColumn } from "../../../../../../Shared/Components/Table/Table";
import { useFormValidation } from "../../../../../../Shared/Hooks/useFormValidation";
import useModal from "../../../../../../Shared/Hooks/useModal";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../../../Shared/ObjectValidator";
import { formatNumber, isBoolean, isNullOrUndefined } from "../../../../../../Shared/Utilities";
import { PerferenceTermsTexts } from "./Texts";

export interface PreferencesTermsBasicOption {
    value: any;
    header: {
        title: string;
        info?: string;
    };
    render?: React.ReactElement;
    example: string | string[];
}
export interface PreferencesTermsOption extends PreferencesTermsBasicOption {
    onChange: (value?: any) => void;
    isSelected: boolean;
    isActive: boolean;
}

const selectOptions: RadioButtonOptions[] = [
    {
        label: "general.yes",
        value: true,
        name: "isLiquidation",
        qaid: "PreferenceTerms.Radio.Yes",
    },
    {
        label: "general.no",
        value: false,
        name: "isLiquidation",
        qaid: "PreferenceTerms.Radio.No",
    },
];

const useShareClass = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined);
    const [classRightEditId, setClassRightEditId] = useState<number | undefined>(undefined);
    const [selectedClass, setSelectedClass] = useState<PreferenceShareClassRight | undefined>(undefined);
    const {
        waterfallStore: {
            preferenceShareClasses,
            getPreferenceClassRights,
            addPreferenceClassRight,
            deletePreferenceClassRight,
            updatePreferenceClassRights,
            waterfallId,
            isViewMode,
        },
        companyStore: { companyId },
    } = useRootStore();
    const { showModal } = useModal();

    useEffect(() => {
        getPreferenceClassRights({ waterfallId, companyId });
    }, []);

    const classRights = useMemo(() => {
        const selectable: NumberOption[] = [];
        const added: PreferenceShareClassRight[] = [];

        preferenceShareClasses?.forEach((sc) => {
            if (sc.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.None) {
                selectable.push({
                    label: sc.shredClassName,
                    value: sc.shareClassId as number,
                });
            } else {
                added.push(sc);
            }
        });

        return {
            selectable,
            added,
        };
    }, [preferenceShareClasses]);

    useEffect(() => {
        if (!classRights.selectable.length || !isNullOrUndefined(selectedClass?.shareClassId) || isViewMode) return;
        onSelectClassHandler(classRights.selectable[0].value as number);
    }, [selectedClass, classRights.selectable]);

    const updateSelection = (classRight: PreferenceShareClassRight | undefined = selectedClass, typeId?: number, isEdit?: boolean) => {
        if (isNullOrUndefined(classRight) || isViewMode) return;

        if (isEdit) setClassRightEditId(classRight?.shareClassId);
        const defaults = {
            ...classRight,
            shareClassPreferenceTermsTypeId: typeId ?? classRight.shareClassPreferenceTermsTypeId,
            capMultiple: undefined,
            hasOptionToConvertIntoShares: undefined,
            isCapMultipleInculdeInterestRate: undefined,
            hasLimitationOnAmount: undefined,
            convertingCompanyValue: undefined,
            mustConvertIntoSharesOnSpecificCompanyValue: undefined,
            mustConvertIntoSharesOnSpecificPayoff: undefined,
            convertingPayoffValue: undefined,
        };

        switch (defaults.shareClassPreferenceTermsTypeId) {
            // asaf
            case PayoffSelectionOptions.NonParticipating:
                setSelectedClass({
                    ...defaults,
                    capMultiple: isEdit ? classRight.capMultiple : undefined,
                    hasOptionToConvertIntoShares: true,
                    isCapMultipleInculdeInterestRate: isEdit ? classRight.isCapMultipleInculdeInterestRate : undefined,
                });
                break;
            case PayoffSelectionOptions.Participating:
                setSelectedClass({
                    ...defaults,
                    capMultiple: isEdit ? classRight.capMultiple : undefined,
                    hasOptionToConvertIntoShares: true,
                    isCapMultipleInculdeInterestRate: true,
                });
                break;
            case PayoffSelectionOptions.NoCap:
                setSelectedClass({
                    ...defaults,
                    hasLimitationOnAmount: true,
                });
                break;
            case PayoffSelectionOptions.Convert:
                setSelectedClass({
                    ...defaults,
                    mustConvertIntoSharesOnSpecificCompanyValue: true,
                    convertingCompanyValue: isEdit ? classRight?.convertingCompanyValue : undefined,
                });
                break;
            case PayoffSelectionOptions.ProRata:
                setSelectedClass({
                    ...defaults,
                    mustConvertIntoSharesOnSpecificPayoff: true,
                    convertingPayoffValue: isEdit ? classRight?.convertingPayoffValue : undefined,
                });
                break;
            default:
                break;
        }
    };

    const onDelete = async (shareClassId: number) => {
        if (isViewMode) return;
        showModal({
            type: "confirm",
            body: (
                <div>
                    Are you sure that you want to remove the share class right "
                    {classRights.added.find((sc) => sc.shareClassId === shareClassId)?.shredClassName}
                    "?
                </div>
            ),
            confirmButton: {
                label: "general.delete",
            },
            title: "general.warning",
            onConfirm: () => deletePreferenceClassRight({ waterfallId, shareClassId, companyId }),
        });
    };

    const { formValidationState, isFormValid } = useFormValidation({
        form: selectedClass,
        schema: {
            hasOptionToConvertIntoShares:
                selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Participating ||
                selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.NonParticipating
                    ? [commonValidators.customValidation(!isNullOrUndefined(selectedClass?.hasOptionToConvertIntoShares), "")]
                    : [],
            isCapMultipleInculdeInterestRate:
                selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Participating
                    ? [commonValidators.customValidation(!isNullOrUndefined(selectedClass?.isCapMultipleInculdeInterestRate), "")]
                    : [],
            capMultiple: [
                commonValidators.requiredIf(() => selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Participating),
                commonValidators.range2(1, 999, { includeMin: true, includeMax: false }),
            ],
            convertingCompanyValue: [
                commonValidators.requiredIf(() => selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Convert),
                commonValidators.range2(0, 10000000, { includeMin: false, includeMax: true }),
            ],
            convertingPayoffValue: [
                commonValidators.requiredIf(() => selectedClass?.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.ProRata),
                commonValidators.range2(0, 999999999, { includeMin: false, includeMax: true }),
            ],
        },
    });

    const payoffOptions: PreferencesTermsBasicOption[] = [
        {
            ...PerferenceTermsTexts[PayoffSelectionOptions.NonParticipating],
            value: PayoffSelectionOptions.NonParticipating,
            render: (
                <div className="flex-column">
                    <div className="title">The cap multiple x 1</div>
                    <QuestionCard
                        isActive={false}
                        question="The preferred share class also has an option to convert into common shares?"
                        options={selectOptions}
                        onChange={(value) => onInputChange(value, "hasOptionToConvertIntoShares")}
                        selectedOption={selectedClass?.hasOptionToConvertIntoShares}
                        isDisabled={selectedClass?.shareClassPreferenceTermsTypeId !== PayoffSelectionOptions.NonParticipating}
                    />
                </div>
            ),
        },
        {
            ...PerferenceTermsTexts[PayoffSelectionOptions.Participating],
            value: PayoffSelectionOptions.Participating,
            render: (
                <div className="flex-column">
                    <div className="flex gap-1 mb-5">
                        <span className="flex align-center">The cap multiple x</span>
                        <NumberInput
                            disabled={selectedClass?.shareClassPreferenceTermsTypeId !== PayoffSelectionOptions.Participating}
                            qaid="PreferencesTerms.Input.CapMultiple"
                            value={selectedClass?.capMultiple as number}
                            name="capMultiple"
                            onChange={(value, name) => onInputChange(value, name)}
                            error={formValidationState?.capMultiple?.message}
                            number="float"
                            style={{ marginBottom: "unset" }}
                            inverse
                        />
                    </div>
                    <div className="mb--sm">
                        <QuestionCard
                            question={"The cap multiple includes the accumulated interest till liquidation event?"}
                            options={selectOptions}
                            onChange={(value) => onInputChange(value, "isCapMultipleInculdeInterestRate")}
                            selectedOption={selectedClass?.isCapMultipleInculdeInterestRate}
                            isDisabled={selectedClass?.shareClassPreferenceTermsTypeId !== PayoffSelectionOptions.Participating}
                        />
                    </div>
                    <QuestionCard
                        question="The preferred share class also has an option to convert into common shares? "
                        options={selectOptions}
                        onChange={(value) => onInputChange(value, "hasOptionToConvertIntoShares")}
                        selectedOption={selectedClass?.hasOptionToConvertIntoShares}
                    />
                </div>
            ),
        },

        {
            ...PerferenceTermsTexts[PayoffSelectionOptions.NoCap],
            value: PayoffSelectionOptions.NoCap,
        },
        {
            ...PerferenceTermsTexts[PayoffSelectionOptions.Convert],
            value: PayoffSelectionOptions.Convert,
            render: (
                <div className="flex gap-1 mb-2">
                    <span className="flex align-center">The business value limitation ($M)</span>
                    <NumberInput
                        disabled={selectedClass?.shareClassPreferenceTermsTypeId !== PayoffSelectionOptions.Convert}
                        inverse
                        name="convertingCompanyValue"
                        value={selectedClass?.convertingCompanyValue as number}
                        onChange={(value, name) => onInputChange(value, name)}
                        qaid="PreferencesTerms.Input.ConvertingCompanyValue"
                        inputWidth={100}
                        error={formValidationState?.convertingCompanyValue?.message}
                        number="float"
                    />
                </div>
            ),
        },
        {
            ...PerferenceTermsTexts[PayoffSelectionOptions.ProRata],
            value: PayoffSelectionOptions.ProRata,
            render: (
                <div className="flex gap-1 mb-2">
                    <span className="flex align-center">The pro-rata amount ($M)</span>
                    <NumberInput
                        disabled={selectedClass?.shareClassPreferenceTermsTypeId !== PayoffSelectionOptions.ProRata}
                        inverse
                        qaid="PreferencesTerms.Input.ConvertingPayoffValue"
                        name="convertingPayoffValue"
                        value={selectedClass?.convertingPayoffValue as number}
                        onChange={(value, name) => onInputChange(value, name)}
                        error={formValidationState?.convertingPayoffValue?.message}
                        number="float"
                    />
                </div>
            ),
        },
    ];

    const tableColumns: TableColumn<PreferenceShareClassRight>[] = [
        {
            name: "shredClassName",
            label: "Share Class",
        },
        {
            name: "shareClassPreferenceTermsTypeId",
            label: "Cap Multiple",

            render: (obj, val) =>
                (val === PayoffSelectionOptions.NonParticipating || val === PayoffSelectionOptions.Participating) && (
                    <>
                        <Image
                            src={IC_CHECK_PURPLE}
                            width="2.5rem"
                            alt="Confirm"
                        />
                        {val === PayoffSelectionOptions.NonParticipating ? "(x1) Non-participating" : `(x${formatNumber(obj.capMultiple)})`}
                    </>
                ),
        },
        {
            name: "isCapMultipleInculdeInterestRate",
            label: "Includes Interest",
            render: (obj, val) => {
                return (
                    val &&
                    obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Participating && (
                        <Image
                            src={IC_CHECK_PURPLE}
                            width="2.5rem"
                            alt="Confirm"
                        />
                    )
                );
            },
        },
        {
            name: "hasOptionToConvertIntoShares",
            label: "Conversion Right",
            render: (obj, val) => {
                return (
                    val &&
                    (obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Participating ||
                        obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.NonParticipating) && (
                        <Image
                            src={IC_CHECK_PURPLE}
                            width="2.5rem"
                            alt="Confirm"
                        />
                    )
                );
            },
        },
        {
            name: "hasLimitationOnAmount",
            label: "Fully Participating",
            render: (obj, val) => {
                return (
                    obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.NoCap && (
                        <Image
                            src={IC_CHECK_PURPLE}
                            width="2.5rem"
                            alt="Confirm"
                        />
                    )
                );
            },
        },
        {
            name: "mustConvertIntoSharesOnSpecificCompanyValue",
            label: "Business Value Limitation",
            render: (obj, val) => {
                return (
                    val &&
                    obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.Convert && (
                        <>
                            <Image
                                src={IC_CHECK_PURPLE}
                                width="2.5rem"
                                alt="Confirm"
                            />{" "}
                            {formatNumber(obj?.convertingCompanyValue)}
                        </>
                    )
                );
            },
        },
        {
            name: "mustConvertIntoSharesOnSpecificPayoff",
            label: "Pro-Rata Cap",
            render: (obj, val) => {
                return (
                    val &&
                    obj.shareClassPreferenceTermsTypeId === PayoffSelectionOptions.ProRata && (
                        <>
                            <Image
                                src={IC_CHECK_PURPLE}
                                width="2.5rem"
                                alt="Confirm"
                            />{" "}
                            {formatNumber(obj?.convertingPayoffValue)}
                        </>
                    )
                );
            },
        },
        {
            name: "actions",
            label: "Actions",
            render: (shareClassRight) => (
                <>
                    <Clickable
                        className={classNames("open", { disabled: isViewMode })}
                        justify="start"
                        onClick={async (e) => {
                            e.stopPropagation();
                            if (isViewMode || shareClassRight.shareClassId === classRightEditId) return;
                            // setClassRightEditId(shareClassRight.shareClassId);
                            // setSelectedClass(shareClassRight);
                            updateSelection(shareClassRight, shareClassRight.shareClassPreferenceTermsTypeId, true);
                        }}
                        qaid={`PreferencesTerms.Button.Edit-${shareClassRight.shareClassId}`}
                    >
                        <Image
                            src={IC_EDIT2}
                            width="2rem"
                            alt="Edit"
                        />
                    </Clickable>

                    <Clickable
                        className={classNames("delete", { disabled: isViewMode })}
                        justify="start"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isViewMode || shareClassRight.shareClassId === classRightEditId) return;
                            onDelete(shareClassRight.shareClassId);
                        }}
                        qaid={`PreferencesTerms.Button.Delete-${shareClassRight.shareClassId}`}
                    >
                        <Image
                            src={IC_TRASH2}
                            width="2rem"
                            alt="Delte"
                        />
                    </Clickable>
                </>
            ),
        },
    ];

    const onInputChange = (value: any, name: string | undefined) => {
        if (isNullOrUndefined(name)) return;
        setSelectedClass((state) => (state ? { ...state, [name]: value } : undefined));
    };

    const onSelectClassHandler = (id: number) => {
        const sc = preferenceShareClasses?.find((sch) => sch.shareClassId === id);
        updateSelection(sc, PayoffSelectionOptions.NonParticipating);
        setClassRightEditId(undefined);
    };

    const onAddShareClass = async () => {
        if (isNullOrUndefined(selectedClass)) return;

        setIsLoading(true);
        await addPreferenceClassRight({
            ...(({ seniorityLevel, ...o }) => o)(selectedClass),
            waterfallId,
            companyId,
            shareClassPreferenceTermsTypeId: selectedClass.shareClassPreferenceTermsTypeId as number,
        });

        setSelectedClass(undefined);
        setClassRightEditId(undefined);
        setIsLoading(false);
    };

    const onEvaluate = async () => {
        const res = await updatePreferenceClassRights({ companyId, waterfallId });
        return res.isSuccess;
    };

    const onResetEditHandler = () => {
        setSelectedClass(undefined);
        setClassRightEditId(undefined);
    };

    return {
        // selectedOption,
        // setSelectedOption,
        selectedClass,
        setSelectedClass,
        classRights,
        isFormValid,
        payoffOptions,
        onSelectClassHandler,
        onAddShareClass,
        isLoading,
        tableColumns,
        onInputChange,
        classRightEditId,
        updateSelection,
        onEvaluate,
        preferenceShareClasses,
        isViewMode,
        onResetEditHandler,
    };
};

export default useShareClass;
