import RadioButton from "../../../../../../../Shared/Components/RadioButton/RadioButton";
import Tooltip from "../../../../../../../Shared/Components/Tooltip";
import InputGroup from "../../../../InputGroup/InputGroup";

import { PerferenceTermsTooltipTitle } from "../Texts";
import { PreferencesTermsOption } from "../useShareClass";

const Card = ({ isSelected, header, onChange, value, example, render, isActive }: PreferencesTermsOption) => {
	return (
		<div className="mb-4">
			<div className={`flex flex-column ${isActive ? "is-active" : ""}`}>
				<div className="flex align-center justify-between">
					<RadioButton
						qaid={`PreferenceTerms.Radio.${value}`}
						name="terms"
						onChange={onChange}
						value={value}
						checked={isSelected}
						label={header.title}
					/>
					<div className="flex justify-between align-center gap-1" style={{ whiteSpace: 'pre', marginLeft: "2rem" }}>
						<Tooltip title={PerferenceTermsTooltipTitle}>
							<div className="pointer">View examples</div>
						</Tooltip>
						<Tooltip title={header.info} />
					</div>
				</div>

				{render && isSelected && <InputGroup>{render}</InputGroup>}
			</div>
		</div>
	);
};

export default Card;
