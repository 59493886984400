import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { ReactNode } from "react";

type Props = {
    isActive?: boolean;
    label: string;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledTab = styled.div(
    {
        label: "Tab",
        color: appConfig.style.colors.text4,
        padding: "1rem 3rem",
        cursor: "pointer",
        transition: "background 1s",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    ({ isActive }: Pick<Props, "isActive">) => ({
        ...(isActive
            ? {
                  outlineWidth: 1,
                  outlineStyle: "solid",
                  outlineColor: appConfig.style.colors.border1,
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 500,
                  justifyContent: "center",
                  color: appConfig.style.colors.text1,
                  background: `linear-gradient(180deg, #ffffff 25%, ${appConfig.style.colors.background1} 100%)`,
              }
            : {}),
    })
);

const Tab = ({ label, ...props }: Partial<Props>) => {
    return <StyledTab {...props}>{label}</StyledTab>;
};

export default Tab;
