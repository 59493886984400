import { useRef } from "react";
import { ServerResponseApi } from "../../../../Models/API/All/server-response";
import { DocumentTemplateData } from "../../../../Models/API/Document/template-data";
import { DocumentTemplateInfo } from "../../../../Models/API/Document/template-info";
import { useFetchApi } from "../../../../Shared/Hooks/useFetchApi";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { InfoProps } from "../../Components/Preview";
import { IC_PREVIEW_LAST_UPDATE, IC_PREVIEW_RULE, IC_PREVIEW_SIGNATURE, IC_PREVIEW_TYPE, IC_PREVIEW_USER } from "../../../../Assets";
import { formatDate, isString } from "../../../../Shared/Utilities";
import { templateTypeOptions } from "../../../../Models/API/Document/template-type-enum";
import { signinRuleOptions } from "../../../../Models/API/Document/signin-rule-enum";
import useGeneralModal from "../../../../Shared/Hooks/useGeneralModal";

const useTemplate = () => {
    const { documentsStore, contactStore, appState } = useRootStore();
    const { run, data, loading, clearData } = useFetchApi<ServerResponseApi<DocumentTemplateData>>();
    const templateRef = useRef<DocumentTemplateInfo>();
    const { showDocumentModal } = useGeneralModal();

    const getTemplate = (templateId: string, updateState: boolean = true, isPreview: boolean = false) => {
        templateRef.current = documentsStore.templates?.find((t) => t.templateId === templateId);
        return run(() => documentsStore.getTemplate(templateId, isPreview), {
            cacheKey: isPreview ? `preview-${templateId}` : templateId,
            updateState,
        });
    };

    const getTypeName = (value: number) => {
        const option = templateTypeOptions.find((opt) => opt.value === value);
        if (option && isString(option?.label)) return option.label;
        return "-";
    };

    const getRuleName = (value: number) => {
        const option = signinRuleOptions.find((opt) => opt.value === value);
        if (option && isString(option?.label)) return option.label;
        return "-";
    };

    const getPreviewInfo = (): InfoProps => {
        if (!templateRef.current) return {} as InfoProps;

        return {
            info: [
                {
                    icon: IC_PREVIEW_USER,
                    title: "Updated By",
                    text: [contactStore.getContactFullNameById(templateRef.current.lastUpdateByContactId)],
                },
                {
                    icon: IC_PREVIEW_LAST_UPDATE,
                    title: "Last update",
                    text: [formatDate(templateRef.current.lastTimeEdited) ?? "-"],
                },
                {
                    icon: IC_PREVIEW_TYPE,
                    title: "Type",
                    text: [getTypeName(templateRef.current.documentType)],
                },
                {
                    icon: IC_PREVIEW_RULE,
                    title: "Signing Rule",
                    text: [getRuleName(templateRef.current.signinRule)],
                },
                {
                    icon: IC_PREVIEW_SIGNATURE,
                    title: "Signatories",
                    text: templateRef.current.signers.map((s) => contactStore.getContactFullNameById(s.contactId, "-")),
                },
            ],
            isPreview: true,
        };
    };

    const previewTemplate = async (id: string) => {
        appState.isLoading = true;
        const res = await getTemplate(id, true, true);
        appState.isLoading = false;
        if (!res.data) return;
        showDocumentModal(res.data.data, getPreviewInfo(), clearData);
    };

    return {
        getTemplate,
        data,
        loading,
        clearData,
        getPreviewInfo,
        previewTemplate,
    };
};

export default useTemplate;
