import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ClickAwayListener, Fade } from "@mui/material";
import classNames from "classnames";
import { Fragment, ReactElement, SyntheticEvent, isValidElement, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IC_CONTRACT,
    IC_DOTS_MENU,
    IC_EXCEL_EXPORT,
    IC_EXPAND,
    IC_FULLSCREEN_EXPAND,
    IC_FULLSCREEN_SHRINK,
    IC_FUNEL,
    IC_LOOKUP,
    IC_X_RED,
} from "../../../Assets";
import { NumberOption } from "../../../Models/API/All/NumberOption";
import {
    getUniqueId,
    isArray,
    isDate,
    isDateValid,
    isNullOrUndefined,
    isNumber,
    isObject,
    isString,
    onExportToExcel,
} from "../../Utilities";
import Button from "../Button/Button";
import CheckBox from "../CheckBox/CheckBox";
import Clickable from "../Clickable/Clickable";
import FullScreenContainer from "../FullScreenContainer";
import Image from "../Image";
import TextInput from "../Input/TextInput";
import Flex from "../Layout/Flex";
import Text from "../Layout/Text";
import Select from "../Select/Select";
import Spinner from "../Spinner/Spinner";
import StyledTable, { BorderStyle, FloatingCard, TableContainer, rowHeight } from "./Table.Style";
import { toJS } from "mobx";
import Tooltip from "../Tooltip";
import OverflowText from "../OverflowText";
import Pagination from "../Pagination";
import { InView } from "react-intersection-observer";

export enum ConnectorEnum {
    and,
    or,
}

export enum ConditionEnum {
    gt,
    lt,
    eq,
    inc,
}

interface SelectAction<T> {
    qaid: string;
    icon: string;
    onClick: (selected: T[]) => void | Promise<void>;
    tooltip: string;
    disabled?: boolean;
}

const conditionsOptions: NumberOption[] = [
    {
        value: 0,
        label: "options.above",
    },
    {
        value: 1,
        label: "options.below",
    },
    {
        value: 2,
        label: "options.equals",
    },
    {
        value: 3,
        label: "options.contains",
    },
];

const connectorsOptions: NumberOption[] = [
    {
        value: 0,
        label: "options.and",
    },
    {
        value: 1,
        label: "options.or",
    },
];

export interface FilterField<T> {
    id: string;
    name?: string;
    condition?: number;
    value?: string;
    connector?: 0 | 1;
    optionValue?: number;
}

export interface TableColumn<T> {
    name: keyof T | "actions" | "menu" | Array<keyof T>;
    label: string;
    align?: "start" | "end" | "center";
    justify?: "start" | "end" | "center";
    textAlign?: "left" | "center" | "right";
    format?: (value: any, obj?: T) => string | undefined;
    sortable?: boolean;
    render?: (obj: T, value?: any) => JSX.Element | string | number | boolean | undefined;
    style?: React.CSSProperties;
    className?: string;
    onCellClick?: (value: T[keyof T], obj: T) => void;
    isSearch?: boolean;
    isFilter?: boolean;
    filterType?: "date" | "text" | "number";
    sortType?: "date" | "text" | "number";
    defaultCellSize?: number | string;
    onEditRender?: (obj: T, value?: any) => JSX.Element | string | number | boolean;
    menuItems?: ((row: T) => Menu<T>[] | undefined) | Menu<T>[];
    inlineEdit?: (row: T) => JSX.Element;
    isOverflow?: boolean;
    tooltip?: string;
}

type Menu<T> = {
    onClick: (obj: T) => void;
    qaid: string;
    disabled?: boolean;
    element: ReactElement;
};

export interface TableProps<T> {
    rows: T[] | undefined | null;
    columns: TableColumn<T>[];
    onRowClick?: (obj: T) => void;
    color?: "primary" | "secondary";
    showTotal?: boolean;
    scrollAfterRows?: number;
    searchBy?: Array<keyof T>;
    filterBy?: Array<keyof T>;
    exportToExcel?: { fileName: string; sheetName: string };
    fullscreen?: boolean;
    defaultCellSize?: number;
    isStickyHeader?: boolean;
    isStickyTotal?: boolean;
    fallbackText?: string;
    headerSize?: number;
    rowSize?: number;
    selectBy?: keyof T;
    border?: BorderStyle | false;
    isLoading?: boolean;
    headerless?: boolean;
    renderHeader?: ReactElement;
    renderBottom?: ReactElement;
    className?: string;
    rowClassName?: ((row: T) => string | undefined) | string;
    selectActions?: SelectAction<T>[];
    customActionsRender?: ReactElement;
    customHeaderRender?: ReactElement;
    onRowRender?: (obj: T) => ReactElement | null | undefined | false;
    onExpandRender?: (obj: T) => ReactElement | null | undefined | false;
    onAfterEachRowRender?: (obj: T) => ReactElement;
    onRowSelect?: (selected: T[] | T[keyof T][], row?: T | T[keyof T], isAdd?: boolean) => void;
    onSelectException?: (obj: T) => boolean;
    onRowEnter?: (row: T) => void;
    onRowLeave?: (row: T) => void;
    onRowIntersection?: (row: T) => void | Promise<void>;
    rowsPerPage?: number;
}

export const extractCellValue = <T extends object>(column: TableColumn<T>, obj: T) => {
    return column.render ? (
        column.render(obj, obj[column.name as keyof T]) || <span>&nbsp;</span>
    ) : column.format ? (
        <OverflowText className="text-ellipsis">
            {column.format(isArray(column.name) ? column.name.map((key) => obj[key]) : obj[column.name as keyof T], obj)}
        </OverflowText>
    ) : isArray(column.name) ? (
        column.name.map((key) => obj[key]).join(" ")
    ) : (
        <OverflowText className="text-ellipsis">{obj[column.name as keyof T]}</OverflowText>
    );
};

const Table = <T extends object>({
    rows,
    columns,
    onRowClick,
    color = "primary",
    border = { top: true, right: true },
    showTotal,
    scrollAfterRows = 10,
    searchBy,
    filterBy,
    exportToExcel,
    fullscreen,
    defaultCellSize,
    isStickyHeader = true,
    fallbackText,
    headerSize = 1.5,
    rowSize = 1,
    isLoading,
    isStickyTotal = true,
    selectBy,
    className = "",
    renderHeader,
    renderBottom,
    headerless,
    rowClassName,
    selectActions,
    customActionsRender,
    onRowRender,
    onAfterEachRowRender,
    onRowSelect,
    onSelectException,
    onExpandRender,
    customHeaderRender,
    onRowEnter,
    onRowLeave,
    onRowIntersection,
    rowsPerPage,
}: TableProps<T>) => {
    const [isDesc, setIsDesc] = useState<boolean | undefined>(undefined);
    const [sortBy, setSortBy] = useState<string | undefined>(undefined);
    const [keyword, setKeyword] = useState<string>("");
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filters, setFilters] = useState<FilterField<T>[] | undefined>(undefined);
    const [filter, setFilter] = useState<FilterField<T>>({
        id: getUniqueId(),
        value: "",
    });
    const { t } = useTranslation();
    const tableRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<T[]>([]);
    const [expandedObj, setExpandedObj] = useState<T>();
    const [selectedMenuObj, setSelectedMenuObj] = useState<T>();
    const [editCells, setEditCells] = useState<{ [key: string]: boolean }>({});
    const [pageIdx, setPageIdx] = useState<number>(0);

    const extractCellValueWithEdit = <T extends object>(column: TableColumn<T>, obj: T, rIdx: number, cIdx: number) => {
        if (column.inlineEdit && editCells[`${rIdx}.${cIdx}`]) {
            return (
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => setEditCell(rIdx, cIdx, false)}
                >
                    <div
                        className="w-100"
                        onClick={(e: SyntheticEvent) => e.stopPropagation()}
                    >
                        {column.inlineEdit(obj)}
                    </div>
                </ClickAwayListener>
            );
        }
        return extractCellValue(column, obj);
    };

    // useEffect(() => {
    //     setSelected([]);
    // }, [rows]);

    const searchableKeys = useMemo(
        () =>
            (
                searchBy || columns.filter((col) => col.isSearch).map((col) => (isArray(col.name) ? col.name.map((key) => key) : col.name))
            ).flat(4) as Array<keyof T>,
        [columns, searchBy]
    );

    const filterableKeys = useMemo(
        () =>
            filterBy?.map((key, idx) => ({ label: key, value: idx })) ||
            (
                columns
                    .filter((col) => col.isFilter)
                    .map((col) => (isArray(col.name) ? new Set(col.name.map((key) => key)) : col.name))
                    .flat(4) as Array<keyof T>
            ).map((opt, idx) => ({ label: opt, value: idx })),
        [columns, filterBy]
    );

    const filterNameOptions = filterableKeys.map((option) => ({
        value: option.value,
        label: columns?.find((col: TableColumn<T>) => (isArray(col.name) ? col.name.includes(option.label) : col.name === option.label))
            ?.label as string,
    }));

    const isTotalShown = !keyword && !filters?.length && showTotal; // Adding extra layer of checking in case the table is not filtered, show total when no filters provided

    const filteredRows = useMemo(() => {
        if (isNullOrUndefined(rows) || !isArray(rows)) return rows;

        // Search by keyword
        let searchedRows = [...rows];
        if (searchableKeys && keyword) {
            searchedRows = searchedRows.filter((row) => {
                return searchableKeys.some((key: keyof T) => {
                    const value = row[key];
                    const formatValue = columns.find((col) => (isArray(col.name) ? col.name.includes(key) : col.name === key))?.format;

                    return (
                        ((isString(value) || isNumber(value)) && value?.toString().toLowerCase().includes(keyword.toLowerCase())) ||
                        formatValue?.(value)?.toString().toLowerCase().includes(keyword.toLowerCase())
                    );
                });
            });
        }

        if (showTotal && (keyword || filters?.length)) {
            searchedRows.pop(); // in case total is the last line, remove it
        }
        if (!filters?.length) return searchedRows;

        // Search by filter
        let result: T[] = [];
        filters?.forEach((filter: FilterField<T>, idx: number) => {
            const isAnd = idx && ConnectorEnum.and === filter.connector;
            const arrayToFilter = isAnd ? result : searchedRows || [];

            const filteredArray: T[] = arrayToFilter.filter((data: T) => {
                const col = columns.find((col) => col.name === filter.name);
                let fieldValue: any = data[filter.name as keyof T];
                // if (isObject(fieldValue)) {
                // 	fieldValue = Object.values(fieldValue).join(" ");
                // }

                // In case filter type is date, convert to timestamp
                let keywordValue: any = filter.value;
                let formattedValue: any;
                if (col?.filterType === "date") {
                    formattedValue = +new Date(fieldValue).setHours(0, 0, 0, 0);
                    keywordValue = +new Date(keywordValue.toString()).setHours(0, 0, 0, 0);
                } else if (col?.filterType === "number") {
                    formattedValue = fieldValue;
                } else {
                    formattedValue = col?.format ? col.format(fieldValue) : fieldValue;
                }

                if (!formattedValue) {
                    return false;
                }

                switch (filter.condition) {
                    case ConditionEnum.inc:
                        return formattedValue?.toString().toLowerCase().includes(keywordValue?.toString().toLowerCase());
                    case ConditionEnum.eq:
                        return formattedValue?.toString().toLowerCase() === keywordValue?.toString().toLowerCase();
                    case ConditionEnum.gt:
                        return !isNullOrUndefined(formattedValue) && +formattedValue > +(keywordValue ?? -1);
                    case ConditionEnum.lt:
                        return !isNullOrUndefined(formattedValue) && +formattedValue < +(keywordValue ?? -1);
                    default:
                        return false;
                }
            });
            result = isAnd ? filteredArray : [...result, ...filteredArray];
        });
        return result;
    }, [keyword, filters, rows, searchableKeys]);

    const sortedRows = useMemo(() => {
        if (!sortBy || !filteredRows) {
            if (isNullOrUndefined(rowsPerPage)) return filteredRows;
            if (!isTotalShown) return filteredRows?.slice(pageIdx * rowsPerPage, pageIdx * rowsPerPage + rowsPerPage);

            let rows = filteredRows?.slice(0, filteredRows.length - 1);
            return rows && filteredRows
                ? [...rows.slice(pageIdx * rowsPerPage, pageIdx * rowsPerPage + rowsPerPage), filteredRows[filteredRows.length - 1]]
                : rows;
        }

        let rows = isTotalShown ? filteredRows.slice(0, filteredRows.length - 1) : filteredRows;
        rows = rows?.sort((a: T, b: T) => {
            const aVal = a[sortBy as keyof T];
            const bVal = b[sortBy as keyof T];
            // if the key has a format, extract the value to sort by it
            let aFormatted = columns.find((col) => col.name === sortBy)?.format?.(aVal, a) || aVal || "";
            let bFormatted = columns.find((col) => col.name === sortBy)?.format?.(bVal, b) || bVal || "";

            const sortType = columns.find((col) => col.name === sortBy)?.sortType || "text";

            let aResult, bResult;
            if (sortType === "date" && isString(aFormatted) && isString(bFormatted)) {
                aResult = +new Date(aFormatted).setHours(0, 0, 0, 0);
                bResult = +new Date(bFormatted).setHours(0, 0, 0, 0);
            } else if (sortType === "number" && isNumber(aVal) && isNumber(bVal)) {
                aResult = aVal;
                bResult = bVal;
            } else if (isString(aFormatted) && isString(bFormatted)) {
                aResult = aFormatted.toString().toLocaleLowerCase();
                bResult = bFormatted.toString().toLocaleLowerCase();
            } else {
                aResult = aFormatted;
                bResult = bFormatted;
            }

            // if (isString(aResult) && isString(bResult)) {
            //     aResult = aResult.toLowerCase();
            //     bResult = bResult.toLowerCase();
            // }

            if (aResult < bResult) return -1 * (isDesc ? 1 : -1);
            if (aResult > bResult) return 1 * (isDesc ? 1 : -1);
            return 0;
        });
        return isTotalShown ? [...rows, filteredRows[filteredRows.length - 1]] : rows;
    }, [sortBy, isDesc, filteredRows, pageIdx]);

    const onHeaderCellClickHandler = (keyName: string) => {
        if (sortBy !== keyName) {
            setIsDesc(false);
            setSortBy(keyName);
            return;
        }

        if (isDesc === true) {
            setIsDesc(undefined);
            setSortBy(undefined);
        } else setIsDesc((state) => !state);
    };

    const onCellClickHandler = (e: any, column: TableColumn<T>, object: T, rIdx: number, cIdx: number) => {
        const isDoubleClick = e.detail === 2;
        if (isDoubleClick && column.inlineEdit) {
            return setEditCell(rIdx, cIdx, true);
        }
        if (!column.onCellClick || onRowClick || onExpandRender) return;
        e.stopPropagation();
        column.onCellClick?.(object[column.name as keyof T], object);
    };

    const isFirstTotalCell = (rIdx: number, cIdx: number) => isTotalShown && cIdx === 0 && rIdx === (sortedRows?.length || 0) - 1;

    const isLastRow = (rIdx: number) => isTotalShown && rIdx === (sortedRows?.length || 0) - 1;

    const filteredColumns = columns.filter((col) => col.name !== "actions" && col.name !== "menu");

    // Daniel: move to utils
    const onExportHandler = () => {
        onExportToExcel({
            rows: selected?.length ? selected : filters?.length ? filteredRows : rows,
            exportToExcel,
            color,
            columns: filteredColumns,
            showTotal: isTotalShown && !selected?.length && selected.length === rows?.length,
        });
    };

    const onFilterUpdate = (value: string | number | null, name?: string) => {
        if (isNullOrUndefined(name)) return;
        setFilter((f) => ({ ...f, [name]: value }));
    };

    const onAddFilter = () => {
        setFilters((filters) => (filters ? [...filters, filter] : [{ ...filter, connector: 1 }]));
        setFilter({
            id: getUniqueId(),
            value: "",
        });
    };

    const onResetFilter = () => {
        setFilters(undefined);
        setFilter({
            id: getUniqueId(),
            value: "",
        });
        setShowFilterDialog(false);
    };

    const isObjectSelected = (obj: T) => {
        return selected?.some((object) => (selectBy ? object[selectBy] === obj[selectBy] : JSON.stringify(object) === JSON.stringify(obj)));
    };

    const onToggleSelect = (e: any, obj: T) => {
        e.stopPropagation();
        if (onSelectException?.(obj)) return;
        setSelected((state) => {
            const isAdded = isObjectSelected(obj);
            const newState = isAdded
                ? selectBy
                    ? state.filter((s) => s[selectBy] !== obj[selectBy])
                    : state.filter((s) => JSON.stringify(obj) !== JSON.stringify(s))
                : [...state, obj];

            onRowSelect?.(selectBy ? newState.map((obj) => obj[selectBy]) : newState, selectBy ? obj[selectBy] : obj, !isAdded);

            return newState;
        });
    };

    const toggleExpand = (obj: T) => {
        setExpandedObj(() => {
            return isExpandSelected(obj) ? undefined : obj;
        });
    };

    const isExpandSelected = (obj: T) =>
        selectBy
            ? !isNullOrUndefined(expandedObj?.[selectBy as keyof T]) && expandedObj?.[selectBy as keyof T] === obj[selectBy as keyof T]
            : JSON.stringify(expandedObj) === JSON.stringify(obj);

    const toggleMenu = (obj?: T) => {
        setSelectedMenuObj(isMenuOpen(obj) ? undefined : obj);
    };

    const isMenuOpen = (obj?: T) =>
        obj
            ? selectBy
                ? selectedMenuObj?.[selectBy as keyof T] === obj[selectBy as keyof T]
                : JSON.stringify(selectedMenuObj) === JSON.stringify(obj)
            : false;

    const setEditCell = (rIdx: number, cIdx: number, value: boolean) =>
        setEditCells((prevState) => ({ ...prevState, [`${rIdx}.${cIdx}`]: value }));

    const renderFilter = (filter: FilterField<T>, idx: number) => {
        const connectorLabel = connectorsOptions.find((f) => filter.connector === f.value)?.label || "";
        return (
            <div
                key={filter.id}
                style={{
                    display: "flex",
                    gap: 3,
                    marginBottom: 15,
                    alignItems: "center",
                }}
            >
                <Clickable
                    className="clickable"
                    flex={0}
                    onClick={() => setFilters((prevFilters) => prevFilters?.filter((f) => f.id !== filter.id))}
                    qaid={`Filter.Button.RemoveCondition-${filter.value}`}
                    style={{ display: "flex", alignItems: "center", marginRight: 4 }}
                >
                    <img
                        src={IC_X_RED}
                        alt="Remove condition"
                        width={15}
                        height="auto"
                    />
                </Clickable>
                <span className="bold">{idx && isString(connectorLabel) ? t(connectorLabel) : "If"} </span>
                {t(
                    columns.find((col) => (isArray(col.name) ? col.name.includes(filter.name as keyof T) : col.name === filter.name))
                        ?.label as string
                )}
                <span className="bold danger">{isString(connectorLabel) ? t(connectorLabel) : ""}</span>
                {filter.value}
            </div>
        );
    };

    const renderMenuItem = (item: Menu<T>, obj: T) => {
        return (
            <Clickable
                className={classNames(`${TableContainer}__item`, {
                    disabled: item.disabled,
                })}
                key={item.qaid}
                onClick={(e: any) => {
                    // e.stopPropagation();
                    if (item.disabled) return;
                    item.onClick(obj);
                    setSelectedMenuObj(undefined);
                }}
                qaid={item.qaid}
            >
                {item.element}
            </Clickable>
        );
    };

    const showToolbar = Boolean(
        searchableKeys?.length ||
            exportToExcel ||
            fullscreen ||
            filterableKeys?.length ||
            customActionsRender ||
            (customHeaderRender && !selected?.length) ||
            (customHeaderRender && !selectActions?.length)
    );

    const showMenu = (column: TableColumn<T>, obj: T) => {
        return isArray(column.menuItems) || column.menuItems?.(obj);
    };

    return (
        <FullScreenContainer
            isFullScreen={isFullscreen}
            className={classNames({ [className]: !!className })}
        >
            {showToolbar && (
                <div className={classNames(`${TableContainer}__actions`, { [`${className}__actions`]: !!className })}>
                    {((customHeaderRender && !selected?.length) || (customHeaderRender && !selectActions?.length)) &&
                        isValidElement(customHeaderRender) &&
                        customHeaderRender}
                    <div className={classNames(`${TableContainer}__actions__right`)}>
                        {!!searchableKeys?.length && (
                            <TextInput
                                containerClassName="table-search"
                                placeholder="general.search2"
                                name="keyword"
                                value={keyword}
                                onChange={(value) => {
                                    setKeyword(value);
                                    setPageIdx(0);
                                }}
                                containerStyle={{ maxWidth: "25rem", marginBottom: 0 }}
                                qaid="Filter.Input.Search"
                                style={{ flex: 1 }}
                                endIcon={IC_LOOKUP}
                            />
                        )}
                        <Flex
                            gap="1.2rem"
                            justify="end"
                            flex={0}
                            className={`TableContainer__actions-wrapper ${TableContainer}__actions-wrapper`}
                        >
                            {customActionsRender}
                            {!!filterableKeys?.length && (
                                <>
                                    {!!filters?.length && (
                                        <Button
                                            onClick={onResetFilter}
                                            qaid="Toolbar.Button.ToggleFilter"
                                            className={`${TableContainer}__actions__clickable`}
                                            label="Clear Filter"
                                            small
                                            square
                                            inverse
                                        />
                                    )}
                                    <Clickable
                                        flex={0}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setFilter({
                                                id: getUniqueId(),
                                                value: "",
                                                connector: filters?.length ? undefined : 0,
                                            });
                                            setShowFilterDialog((show) => !show);
                                        }}
                                        qaid="Toolbar.Button.ToggleFilter"
                                    >
                                        <Image
                                            src={IC_FUNEL}
                                            alt="Filter By"
                                            tooltip="Filter..."
                                        />
                                    </Clickable>
                                </>
                            )}
                            {!!exportToExcel && (
                                <Clickable
                                    flex={0}
                                    onClick={onExportHandler}
                                    qaid="Toolbar.Button.Export"
                                >
                                    <Image
                                        src={IC_EXCEL_EXPORT}
                                        alt={`${exportToExcel?.fileName}.xlsx`}
                                        tooltip="Export to Excel"
                                    />
                                </Clickable>
                            )}
                            {fullscreen && (
                                <Clickable
                                    flex={1}
                                    onClick={() => setIsFullscreen((state) => !state)}
                                    qaid="Toolbar.Button.FullScreen"
                                    title={isFullscreen ? "Exit fullscreen" : "View in fullscreen"}
                                >
                                    <Image
                                        width="2.6rem"
                                        className="icon--action"
                                        src={isFullscreen ? IC_FULLSCREEN_SHRINK : IC_FULLSCREEN_EXPAND}
                                        alt="Fullscreen"
                                        tooltip="Fullscreen"
                                    />
                                </Clickable>
                            )}
                        </Flex>
                    </div>
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowFilterDialog(false);
                        }}
                    >
                        <Fade
                            unmountOnExit
                            in={showFilterDialog}
                        >
                            <div
                                className={FloatingCard}
                                // onClick={(e: any) => e.stopPropagation()}
                            >
                                <Text
                                    family="assistant"
                                    fontWeight={700}
                                    className="mb-4"
                                >
                                    Filter by...
                                </Text>
                                {!!filters?.length && (
                                    <div className={`${TableContainer}__actions__filter-conditions`}>
                                        {filters.map((filter, idx: number) => renderFilter(filter, idx))}
                                    </div>
                                )}
                                <Flex
                                    justify="between"
                                    gap={10}
                                >
                                    {!!filters?.length && (
                                        <Select
                                            label="options.andOr"
                                            options={connectorsOptions}
                                            name="connector"
                                            value={filter?.connector}
                                            disabled={!filters?.length}
                                            onChange={onFilterUpdate}
                                            qaid="Filter.Select.AndOr"
                                        />
                                    )}
                                    <Select
                                        label="options.parameterName"
                                        options={filterNameOptions}
                                        name="name"
                                        value={filter?.optionValue}
                                        onChange={(value) => {
                                            if (!isNumber(value)) return;

                                            const label = filterNameOptions.find((opt) => opt.value === value)?.label || "";
                                            const col = columns.find((col) => col.label === label);
                                            if (!col) return;

                                            setFilter((f) => ({
                                                ...f,
                                                name: col.name.toString(),
                                                optionValue: value,
                                            }));
                                        }}
                                        qaid="Filter.Select.Name"
                                    />
                                    <Select
                                        label="options.conditions"
                                        options={conditionsOptions}
                                        name="condition"
                                        value={filter?.condition}
                                        onChange={onFilterUpdate}
                                        qaid="Filter.Select.Condition"
                                    />
                                    <TextInput
                                        label="options.parameterValue"
                                        name="value"
                                        placeholder="general.search2"
                                        value={filter.value}
                                        onChange={(value, name) => onFilterUpdate(value.toString(), name)}
                                        containerStyle={{ marginBottom: 0 }}
                                        qaid="Filter.Input.Value"
                                    />
                                    <Button
                                        small
                                        label="general.add"
                                        position="end"
                                        disabled={
                                            isNullOrUndefined([filter.name, filter.condition, filter.value]) ||
                                            (!!filters?.length && isNullOrUndefined(filter.connector))
                                        }
                                        onClick={onAddFilter}
                                        qaid="Filter.Button.AddFilter"
                                    />
                                </Flex>
                            </div>
                        </Fade>
                    </ClickAwayListener>
                </div>
            )}
            <Fade
                in={!!(selected?.length && selectActions?.length)}
                timeout={50}
            >
                <div style={{ position: "relative" }}>
                    <StyledTable.SelectedActions>
                        {selectActions?.map((action) => (
                            <Clickable
                                qaid={action.qaid}
                                onClick={async () => {
                                    if (action.disabled) return;
                                    await action.onClick(selected);
                                    setSelected([]);
                                }}
                                style={{ opacity: action.disabled ? 0.5 : 1 }}
                            >
                                <Image
                                    key={action.qaid}
                                    src={action.icon}
                                    alt=""
                                    tooltip={action.tooltip}
                                />
                            </Clickable>
                        ))}
                    </StyledTable.SelectedActions>
                </div>
            </Fade>
            <div className={classNames(TableContainer)}>
                <StyledTable.Table
                    scrollAfterRows={scrollAfterRows * rowSize + headerSize}
                    isFullscreen={isFullscreen}
                    ref={tableRef}
                    isCustom={!!renderBottom}
                    isRelative={!selectedMenuObj}
                >
                    {isLoading && (
                        <Spinner
                            attachParent
                            center
                        />
                    )}
                    {!headerless &&
                        (renderHeader || (
                            <StyledTable.Row
                                color={color}
                                header
                                data-row-type="header"
                                isStickyHeader={isStickyHeader}
                                headerSize={headerSize}
                            >
                                {!!onRowSelect && (
                                    <StyledTable.Cell
                                        isClickable={!onSelectException && !!rows?.length}
                                        data-cell-type="select"
                                        border={
                                            isObject(border)
                                                ? {
                                                      ...border,
                                                      // right: true,
                                                      bottom: true,
                                                      top: false,
                                                  }
                                                : { bottom: true, top: false }
                                        }
                                        style={{ flex: "0 0 54px", minWidth: "54px", maxWidth: "54px" }}
                                        className={classNames("text-ellipsis")}
                                        onClick={() => {
                                            if (onSelectException) return;

                                            const array = selected?.length
                                                ? []
                                                : isTotalShown
                                                ? (filteredRows?.slice(0, filteredRows.length - 1) as T[])
                                                : (filteredRows as T[]);
                                            setSelected(array);
                                            onRowSelect?.(array);
                                        }}
                                        color={color}
                                    >
                                        {!onSelectException && !!rows?.length && (
                                            <CheckBox
                                                style={{ minWidth: "unset", flex: 1, justifyContent: "center" }}
                                                disabled={selectActions?.some((action) => action.disabled)}
                                                disableBoxSpacing
                                                isChecked={!!selected?.length}
                                                qaid="Table.CheckBox.SelectAll"
                                            />
                                        )}
                                    </StyledTable.Cell>
                                )}
                                {columns.map((column: TableColumn<T>) => (
                                    <StyledTable.Cell
                                        key={column.name as string}
                                        style={column.style}
                                        sortable={column.sortable}
                                        onClick={() => column.sortable && onHeaderCellClickHandler(column.name as string)}
                                        color={color}
                                        data-cell-type={column.name === "menu" ? "menu-header" : "header"}
                                        isMenu={column.name === "menu"}
                                        border={
                                            isObject(border)
                                                ? {
                                                      ...border,
                                                      // right: true,
                                                      bottom: true,
                                                      top: false,
                                                  }
                                                : { bottom: true, top: false }
                                        }
                                        align={column.align}
                                        justify={column.justify}
                                        textAlign={column.textAlign}
                                        defaultCellSize={defaultCellSize || column.defaultCellSize}
                                    >
                                        <Tooltip
                                            title={column.tooltip ?? ""}
                                            showIcon={!!column.tooltip}
                                        >
                                            <>
                                                {t(column.label)}
                                                {sortBy === column.name && (isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                                            </>
                                        </Tooltip>
                                    </StyledTable.Cell>
                                ))}
                            </StyledTable.Row>
                        ))}
                    {sortedRows === undefined ? (
                        <StyledTable.Row
                            isClickable={false}
                            rowSize={rowSize}
                            data-row-type="loading"
                        >
                            <Spinner
                                attachParent
                                incorporated
                                center
                                size={20}
                            />
                        </StyledTable.Row>
                    ) : (sortedRows?.length && !isTotalShown) || ((sortedRows?.length || 0) > 1 && isTotalShown) ? (
                        sortedRows?.map((obj: T, rIdx: number) => {
                            const isLast = isLastRow(rIdx);
                            const isExpand = isExpandSelected(obj);
                            return (
                                <Fragment key={rIdx}>
                                    {(!isLast && onRowRender?.(obj)) || (
                                        <>
                                            <InView
                                                triggerOnce
                                                skip={isNullOrUndefined(onRowIntersection)}
                                                onChange={(inView) => !isLast && inView && onRowIntersection?.(obj)}
                                            >
                                                {({ inView, ref, entry }) => (
                                                    <StyledTable.Row
                                                        ref={ref}
                                                        rowSize={rowSize}
                                                        onClick={(e) => {
                                                            if (isTotalShown && rIdx + 1 === rows?.length) return;
                                                            return onExpandRender
                                                                ? toggleExpand(obj)
                                                                : onRowClick
                                                                ? onRowClick?.(obj)
                                                                : onRowSelect
                                                                ? onToggleSelect(e, obj)
                                                                : null;
                                                        }}
                                                        color={color}
                                                        isClickable={
                                                            !!onRowClick ||
                                                            ((!!onRowSelect && !onSelectException?.(obj)) ?? false) ||
                                                            !!onExpandRender
                                                        }
                                                        isTotal={isLast}
                                                        isStickyTotal={isStickyTotal && isLast}
                                                        isHighlight={isExpand}
                                                        isRelative={!selectedMenuObj}
                                                        className={classNames("Table.Row.Qa", {
                                                            [(isString(rowClassName) ? rowClassName : rowClassName?.(obj)) || ""]:
                                                                !!rowClassName,
                                                        })}
                                                        onMouseEnter={() => !isLast && onRowEnter?.(obj)}
                                                        onMouseLeave={() => !isLast && onRowLeave?.(obj)}
                                                        data-row-type={isLast ? "total" : "row"}
                                                        data-in-view={inView}
                                                    >
                                                        {!!onRowSelect && (
                                                            <StyledTable.Cell
                                                                style={{
                                                                    flex: "0 0 54px",
                                                                    minWidth: 54,
                                                                    maxWidth: 54,
                                                                }}
                                                                data-cell-type="select"
                                                                isClickable={
                                                                    ((showTotal && rIdx + 1 !== rows?.length) || !showTotal) &&
                                                                    (!onSelectException?.(obj) ?? false)
                                                                }
                                                                onClick={(e: any) =>
                                                                    (showTotal && rIdx + 1 !== rows?.length) || !showTotal
                                                                        ? onToggleSelect(e, obj)
                                                                        : null
                                                                }
                                                                border={
                                                                    isObject(border)
                                                                        ? {
                                                                              ...border,
                                                                              bottom:
                                                                                  rIdx + 1 === rows?.length
                                                                                      ? false
                                                                                      : isExpand
                                                                                      ? true
                                                                                      : border.bottom,
                                                                              top: headerless && !rIdx ? false : border.top,
                                                                          }
                                                                        : border
                                                                }
                                                                color={color}
                                                            >
                                                                {((showTotal && rIdx + 1 !== rows?.length) || !showTotal) &&
                                                                    (!onSelectException?.(obj) ?? false) && (
                                                                        <CheckBox
                                                                            style={{ minWidth: "unset", flex: 1, justifyContent: "center" }}
                                                                            disabled={
                                                                                selectActions?.some((action) => action.disabled) ||
                                                                                (onSelectException?.(obj) ?? false)
                                                                            }
                                                                            disableBoxSpacing
                                                                            isChecked={isObjectSelected(obj)}
                                                                            qaid={`Table.CheckBox.Select-${rIdx}`}
                                                                        />
                                                                    )}
                                                            </StyledTable.Cell>
                                                        )}
                                                        {columns.map((column: TableColumn<T>, cIdx) => {
                                                            const isFirstTotal = isFirstTotalCell(rIdx, cIdx);
                                                            return (column.name === "actions" || column.name === "menu") && isLast ? (
                                                                <StyledTable.Cell
                                                                    isMenu={column.name === "menu"}
                                                                    data-cell-type={column.name === "menu" ? "menu" : "cell"}
                                                                    style={column.style}
                                                                    key={Math.random()}
                                                                    border={
                                                                        isObject(border)
                                                                            ? {
                                                                                  ...border,
                                                                                  // right: true,
                                                                                  bottom:
                                                                                      rIdx + 1 === sortedRows?.length
                                                                                          ? false
                                                                                          : isExpand
                                                                                          ? true
                                                                                          : border.bottom,
                                                                                  top: headerless && !rIdx ? false : border.top,
                                                                              }
                                                                            : border
                                                                    }
                                                                ></StyledTable.Cell>
                                                            ) : (
                                                                <StyledTable.Cell
                                                                    className={classNames(column.className, {
                                                                        bold: isLast,
                                                                    })}
                                                                    data-cell-type={column.name === "menu" ? "menu" : "cell"}
                                                                    key={column.name as string}
                                                                    style={column.style}
                                                                    isCustom={
                                                                        (!!column.render && !column.inlineEdit && !isFirstTotal) ||
                                                                        column.name === "menu"
                                                                    }
                                                                    color={color}
                                                                    onClick={(e: any) => {
                                                                        !isFirstTotal && onCellClickHandler(e, column, obj, rIdx, cIdx);
                                                                    }}
                                                                    align={column.align}
                                                                    justify={column.name === "menu" ? "end" : column.justify}
                                                                    isClickable={!!column.onCellClick && !isFirstTotal}
                                                                    defaultCellSize={defaultCellSize || column.defaultCellSize}
                                                                    isMenu={column.name === "menu"}
                                                                    data-qaid={`Table.Cell.R(${rIdx})-C(${cIdx})`}
                                                                    isOverflow={column.isOverflow}
                                                                    border={
                                                                        isObject(border)
                                                                            ? {
                                                                                  ...border,
                                                                                  // right: true,
                                                                                  bottom:
                                                                                      rIdx + 1 === sortedRows?.length
                                                                                          ? false
                                                                                          : isExpand
                                                                                          ? true
                                                                                          : border.bottom,
                                                                                  top: headerless && !rIdx ? false : border.top,
                                                                              }
                                                                            : border
                                                                    }
                                                                >
                                                                    {isFirstTotal ? (
                                                                        "Total"
                                                                    ) : column.name === "menu" && showMenu(column, obj) ? (
                                                                        <Clickable
                                                                            onClick={(e: any) => {
                                                                                e.stopPropagation();
                                                                                toggleMenu(obj);
                                                                            }}
                                                                            style={{ width: "100%", height: "100%" }}
                                                                            qaid={`Table.Button.Menu-${obj[selectBy as keyof T] || rIdx}`}
                                                                        >
                                                                            <Flex
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <Image
                                                                                    src={IC_DOTS_MENU}
                                                                                    alt="menu"
                                                                                    width="2rem"
                                                                                />
                                                                            </Flex>
                                                                            <ClickAwayListener
                                                                                onClickAway={(e: any) => {
                                                                                    e.stopPropagation();
                                                                                    setSelectedMenuObj(undefined);
                                                                                }}
                                                                            >
                                                                                <Fade
                                                                                    unmountOnExit
                                                                                    in={isMenuOpen(obj)}
                                                                                >
                                                                                    <div
                                                                                        className={classNames(`${TableContainer}__menu`, {
                                                                                            top:
                                                                                                (sortedRows?.length || 0) > 1 &&
                                                                                                rIdx === (sortedRows?.length || 0) - 1,
                                                                                            left: (sortedRows?.length || 0) === 1,
                                                                                        })}
                                                                                    >
                                                                                        {isArray(column.menuItems)
                                                                                            ? column.menuItems.map((item) =>
                                                                                                  renderMenuItem(item, obj)
                                                                                              )
                                                                                            : column
                                                                                                  .menuItems?.(obj)
                                                                                                  ?.map((item) =>
                                                                                                      renderMenuItem(item, obj)
                                                                                                  )}
                                                                                    </div>
                                                                                </Fade>
                                                                            </ClickAwayListener>
                                                                        </Clickable>
                                                                    ) : (
                                                                        extractCellValueWithEdit(column, obj, rIdx, cIdx)
                                                                    )}
                                                                </StyledTable.Cell>
                                                            );
                                                        })}
                                                    </StyledTable.Row>
                                                )}
                                            </InView>
                                            {onAfterEachRowRender?.(obj)}
                                            {isExpand && onExpandRender?.(obj)}
                                        </>
                                    )}
                                </Fragment>
                            );
                        })
                    ) : (
                        <Flex
                            className={classNames("text-center", "pd-1")}
                            style={{ height: rowHeight * rowSize }}
                        >
                            {t(fallbackText ?? "general.noDataToDisplay")}
                        </Flex>
                    )}
                </StyledTable.Table>
                {isValidElement(renderBottom) && (
                    <StyledTable.Row
                        rowSize={rowSize}
                        className="custom-render"
                        data-row-type="custom"
                    >
                        {renderBottom}
                    </StyledTable.Row>
                )}
            </div>
            {!!(rowsPerPage && filteredRows) && (
                <Pagination
                    style={{ marginTop: "4rem" }}
                    onNavHandler={(idx) => setPageIdx(idx)}
                    currentPage={pageIdx}
                    pageCount={Math.max(Math.ceil((filteredRows.length - (isTotalShown ? 1 : 0)) / rowsPerPage), 1)}
                />
            )}
        </FullScreenContainer>
    );
};

export default Table;
