import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Reviewer } from "../../../../../../../Models/API/Waterfall/IForm";
import CheckBox from "../../../../../../../Shared/Components/CheckBox/CheckBox";
import Flex from "../../../../../../../Shared/Components/Layout/Flex";
import Spinner from "../../../../../../../Shared/Components/Spinner/Spinner";
import Tooltip from "../../../../../../../Shared/Components/Tooltip";
import useRootStore from "../../../../../../../Shared/Hooks/useRootStore";
import { ReviewersStyles, SelectionListStyle } from "./Reviewers.Style";
import { useTranslation } from "react-i18next";

const Reviewers: React.FC = () => {
    const {
        waterfallStore,
        companyStore: { companyId },
    } = useRootStore();
    const [reviewers, setReviewers] = useState<Reviewer[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const checkedReviewers = useMemo(() => {
        return reviewers?.filter((reviewer) => reviewer.isSelected);
    }, [reviewers]);

    useEffect(() => {
        if (!waterfallStore.currentWaterfallId) return;
        fetchReviewers();
    }, [waterfallStore.currentWaterfallId]);

    const fetchReviewers = async () => {
        setIsFetching(true);
        const res = await waterfallStore.getProjectReviewers({
            companyId,
            waterfallId: waterfallStore.waterfallId,
        });
        setIsFetching(false);
        res.data && setReviewers(res.data.users);
    };

    const onToggle = async (id: number) => {
        setIsLoading(true);
        const res = await waterfallStore.toggleProjectReviewer({
            waterfallId: waterfallStore.waterfallId,
            companyId,
            participantUserId: id,
        });
        setIsLoading(false);
        res.data && setReviewers(res.data.users);
    };

    if (isFetching)
        return (
            <Spinner
                center
                incorporated
            />
        );
    if (!waterfallStore.currentWaterfallId) {
        return (
            <Flex
                align="center"
                justify="center"
            >
                {t("general.noDataToDisplay")}
            </Flex>
        );
    }

    return (
        <>
            <div className={ReviewersStyles}>
                <h5 className="title">Add additional reviewers.</h5>
                <div className="selection-list-wrapper">
                    <div className={`${SelectionListStyle} selection-list--selectable`}>
                        <div className={`${SelectionListStyle}__Header`}>
                            {t("waterfall.additionalReviewers")}
                            <Tooltip title={t("waterfall.additionalReviewers")} />
                        </div>

                        {isLoading && (
                            <Spinner
                                attachParent
                                size={30}
                            />
                        )}
                        <ul className={`${SelectionListStyle}__Items`}>
                            {reviewers.map((reviewer) => (
                                <li
                                    key={reviewer.userId}
                                    className={`${SelectionListStyle}__Item`}
                                >
                                    <CheckBox
                                        className={`${ReviewersStyles}__checkbox`}
                                        isChecked={reviewer.isSelected}
                                        label={`${reviewer.name} (${t(`waterfall.${reviewer.isOwner ? "editor" : "viewer"}`)})`}
                                        qaid={`Reviewers.Select.Reviewer-${reviewer.userId}`}
                                        onClick={() => onToggle(reviewer.userId)}
                                        disabled={reviewer.isOwner || waterfallStore.isViewMode}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={`${SelectionListStyle} selection-list--selected`}>
                        <div className={`${SelectionListStyle}__Header`}>
                            {t("waterfall.currentProjectReviewers")}:
                            <Tooltip title={t("waterfall.reviewersInfo")} />
                        </div>

                        <ul className={`${SelectionListStyle}__Items`}>
                            {checkedReviewers.map((reviewer) => (
                                <li
                                    key={reviewer.userId}
                                    className={`${SelectionListStyle}__Item`}
                                >
                                    {reviewer.name} ({t(`waterfall.${reviewer.isOwner ? "editor" : "viewer"}`)})
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Reviewers);
