import { observer } from 'mobx-react-lite';
import { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Routes } from './Routes';
import { PaymentPlanEnum, UserStatus } from './Models/API/enums';
import useRootStore from './Shared/Hooks/useRootStore';

// Components
import PrivateRoute from './Shared/Components/PrivateRoute';
import AccountCreateFirstPhaseScreen from './Screens/Account/AccountCreateFirstPhaseScreen';
import DashboardScreen from './Screens/Dashboard';
import AccountCompanyProfileScreen from './Screens/Account/AccountCompanyProfileScreen';
import AccountCompleteRegistration from './Screens/Account/AccountCompleteRegistration';
import AccountCreateSecondPhaseScreen from './Screens/Account/AccountCreateSecondPhaseScreen';
import AccountForgotPasswordScreen from './Screens/Account/AccountForgotPasswordScreen';
import AccountLoginOtpScreen from './Screens/Account/AccountLoginOtpScreen';
import AccountLoginScreen from './Screens/Account/AccountLoginScreen';
import AccountResetPasswordScreen from './Screens/Account/AccountResetPasswordScreen';
import ContactUs from './Screens/Account/ContactUs';
import ValidateEmployee from './Screens/Account/ValidateEmployee';
import EsopScreen from './Screens/ESOP/index';
import Checkout from './Screens/Payment/Checkout';
import Pricing from './Screens/Payment/Pricing';
import ShareRepScreen from './Screens/ShareRep';
import AddEditWaterfall from './Screens/Waterfall/Components/AddEditWaterfall/AddEditWaterfall';
import WaterfallScreen from './Screens/Waterfall';
import { OptionsPermission, WaterfallPermission } from './Models/API/UsersAndPermissions/permissions-enum';
import CapTableExcelImport from './Screens/CapTable/Components/Import';
import Trustee from './Screens/TrusteeEquity';
import Expensing from './Screens/Expensing';
import Settings from './Screens/Expensing/Components/Settings';
import BlackAndScholes from './Screens/Expensing/BlackAndScholes';
import ReportSettings from './Screens/Expensing/Components/ReportSettings';
import Documents from './Screens/Documents';
import ExpensingMenuWrapper from './Screens/Expensing/MenuWrapper';
import WelcomeScreen from './Shared/Components/WelcomePage';
import { ExpensingWelcomeProps, WaterfallWelcomeProps } from './Shared/Components/WelcomePage/config';
import usePermissions from './Shared/Hooks/usePermissions';

// const OverviewScreen = lazy(() => import("./Screens/Overview/OverviewScreen"));
// const Navigation = lazy(() => import("./Shared/Components/Navigation"));

const CapTableScreen = lazy(() => import('./Screens/CapTable'));
const FundingScreen = lazy(() => import('./Screens/Funding/Index'));
// const OptionsScreen = lazy(() => import("./Screens/Options/Index"));
const UsersAndPermissionsScreen = lazy(() => import('./Screens/UsersAndPermissions'));
const CompanyProfileScreen = lazy(() => import('./Screens/Account/CompanyProfile'));

export type Route = {
	title: string;
	path: string;
	component: any;
	exact: boolean;
	isRequireAuth: boolean;
	disabled?: boolean;
	requiredUserStatus?: UserStatus;
	hideFooter?: boolean;
	hideHeader?: boolean;
};

const Router = () => {
	const rootStore = useRootStore();
	const { permissions } = usePermissions(rootStore.auth.permissions, rootStore.paymentStore.currentPlan);

	const routesList: Array<Route> = [
		{
			title: rootStore.companyStore.odooTransactionId ? 'Transaction' : 'Dashboard',
			path: Routes.dashboard.index,
			component: rootStore.companyStore.odooTransactionId ? <ShareRepScreen /> : <DashboardScreen />,
			exact: true,
			isRequireAuth: true,
		},
		{
			title: 'Cap Table',
			path: Routes.capTable.index,
			component: <CapTableScreen />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.capTable.disabled,
		},
		{
			title: 'Funding Scenarios',
			path: Routes.funding.index,
			component: <FundingScreen />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.funding.disabled,
		},
		// {
		// 	title: "Options",
		// 	path: Routes.options.index,
		// 	component: <OptionsScreen />,
		// 	exact: true,
		// 	isRequireAuth: true,
		// 	disabled: Boolean(!process.env.REACT_APP_IS_OPTIONS_ACTIVE || !auth.permissions?.optionPermission),
		// },
		{
			title: 'Equity Plans',
			path: Routes.options7.index,
			component: <EsopScreen />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.equityPlans.disabled,
		},
		{
			title: 'Equity Plans',
			path: `${Routes.options7.index}/:entity`,
			component: <EsopScreen />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.equityPlans.disabled,
		},
		{
			title: 'Equity Plans',
			path: Routes.trusteeData.index,
			component: <Trustee />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.equityPlansTrustee.disabled,
		},
		{
			title: 'Equity Plans',
			path: `${Routes.trusteeData.index}/:section`,
			component: <Trustee />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.equityPlansTrustee.disabled,
		},
		// {
		// 	title: "Options 7.0",
		// 	path: `${Routes.options7.index}/beneficiaries`,
		// 	component: <Beneficiaries rtd={() => {}}/>,
		// 	exact: true,
		// 	isRequireAuth: false,
		// 	disabled: false
		// },
		{
			title: 'Users & Permissions',
			path: Routes.usersAndPermission.index,
			component: <UsersAndPermissionsScreen />,
			exact: true,
			isRequireAuth: true,
		},
		{
			title: 'Company Profile',
			path: Routes.account.companyProfile,
			component: <CompanyProfileScreen />,
			exact: true,
			isRequireAuth: true,
		},
		{
			title: 'Plans & Pricing',
			path: Routes.payment.pricing,
			component: <Pricing />,
			exact: true,
			isRequireAuth: rootStore.auth.isUserActive,
			disabled: process.env.REACT_APP_IS_USE_STRIPE === 'false',
			requiredUserStatus: rootStore.auth.isUserActive ? UserStatus.Active : UserStatus.PendingForPaymentPlan,
		},
		{
			title: 'Payment Page',
			path: Routes.payment.paymentPay,
			component: <Checkout />,
			exact: true,
			isRequireAuth: rootStore.auth.isUserActive,
			requiredUserStatus: rootStore.auth.isUserActive ? UserStatus.Active : UserStatus.PendingForPaymentPlan,
		},
		{
			title: 'Contact us',
			path: `${Routes.contactUs.index}/:sourceTriggerName?`,
			component: <ContactUs />,
			exact: true,
			isRequireAuth: true,
		},
		{
			title: 'Login',
			path: Routes.account.login,
			component: <AccountLoginScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Login',
			path: Routes.account.inviteEmpExsistUser,
			component: <AccountLoginScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'AccountCreateFirstPhase',
			path: Routes.account.accountRegistration,
			component: <AccountCreateFirstPhaseScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'AccountCreateSecondPhase',
			path: `${Routes.account.accountSetup}/:uniquekey`,
			component: <AccountCreateSecondPhaseScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'AccountCompanyProfile',
			path: `${Routes.account.companySetup}`,
			component: <AccountCompanyProfileScreen />,
			exact: true,
			isRequireAuth: false,
			requiredUserStatus: UserStatus.PendingForCompanyRegist,
		},
		{
			title: 'AccountCompleteRegistration',
			path: Routes.account.completeRegisration,
			component: <AccountCompleteRegistration />,
			requiredUserStatus: UserStatus.PendingForCompanyRegist,
			exact: true,
			isRequireAuth: false,
			hideFooter: true,
			hideHeader: true,
		},
		{
			title: 'Forgot Password',
			path: Routes.account.forgotPass,
			component: <AccountForgotPasswordScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Reset Password',
			path: `${Routes.account.resetPass}/:otpguid`,
			component: <AccountResetPasswordScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Invitation',
			path: `${Routes.account.invite}/:uniquekey`,
			component: <AccountCreateSecondPhaseScreen />,
			exact: true,
			isRequireAuth: false,
		},

		{
			title: 'Invitation Emplyoee',
			path: `${Routes.account.inviteEmp}/:uniquekey`,
			component: <AccountCreateSecondPhaseScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Login Otp',
			path: `${Routes.account.otpLogin}/:otpguid`,
			component: <AccountLoginOtpScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Login Otp Invitation',
			path: `${Routes.account.otpLoginInvitation}/:otpguid`,
			component: <AccountLoginOtpScreen />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Login Otp Invitation',
			path: `${Routes.account.employeeVerification}/:uniquekey`,
			component: <ValidateEmployee />,
			exact: true,
			isRequireAuth: false,
		},
		{
			title: 'Waterfall Modeling',
			path: Routes.waterfall.index,
			component: permissions.waterfall.included ? <WaterfallScreen /> : <WelcomeScreen {...WaterfallWelcomeProps} />,
			exact: true,
			isRequireAuth: true,
			hideHeader: !permissions.waterfall.included,
			hideFooter: true,
		},
		{
			title: 'Waterfall Modeling',
			path: `${Routes.waterfall.index}/:waterfallId/:step?`,
			component: <AddEditWaterfall />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.waterfall.disabled,
			hideFooter: true,
		},
		{
			title: 'Cap Table',
			path: Routes.capTable.import,
			component: <CapTableExcelImport />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.capTable.disabled,
			hideFooter: true,
		},
		{
			title: 'Expensing',
			path: Routes.expensing.index,
			component: permissions.expensing.included ? (
				<ExpensingMenuWrapper>
					<Expensing />
				</ExpensingMenuWrapper>
			) : (
				<WelcomeScreen {...ExpensingWelcomeProps} />
			),
			exact: true,
			isRequireAuth: true,
			disabled: permissions.expensing.disabled,
			hideHeader: !permissions.expensing.included,
			hideFooter: !permissions.expensing.included,
		},
		{
			title: 'Expensing',
			path: Routes.expensing.settings,
			component: (
				<ExpensingMenuWrapper showSelect={false} showBack={true}>
					<Settings />
				</ExpensingMenuWrapper>
			),
			exact: true,
			isRequireAuth: true,
			disabled: permissions.expensing.disabled,
		},
		{
			title: 'Expensing',
			path: Routes.expensing.reportSettings,
			component: (
				<ExpensingMenuWrapper showSelect={false} showBack={true}>
					<ReportSettings />
				</ExpensingMenuWrapper>
			),
			exact: true,
			isRequireAuth: true,
			disabled: permissions.expensing.disabled,
		},
		{
			title: 'Expensing',
			path: Routes.expensing.blackAndScholes,
			component: (
				<ExpensingMenuWrapper showSelect={false} showBack={true}>
					<BlackAndScholes />
				</ExpensingMenuWrapper>
			),
			exact: true,
			isRequireAuth: true,
			disabled: permissions.expensing.disabled,
		},
		{
			title: 'Documents & Signatues',
			path: Routes.docs.index,
			component: <Documents />,
			exact: true,
			isRequireAuth: true,
			disabled: permissions.expensing.disabled,
		},
	];

	return (
		<Switch>
			{routesList
				.filter((route) => !route.disabled)
				.map(({ component, path, title, exact, isRequireAuth, requiredUserStatus }) => (
					<PrivateRoute
						key={path}
						component={() => component}
						path={path}
						title={title}
						exact={exact || true}
						isRequireAuth={isRequireAuth}
						requiredUserStatus={requiredUserStatus}
					/>
				))}
			<Redirect to={Routes.dashboard.index} />
		</Switch>
	);
};

export default observer(Router);
