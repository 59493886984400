import styled from "@emotion/styled";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";
import { IC_PLUS_PURPLE, IC_PLUS_WHITE2 } from "../../../Assets";
import appConfig from "../../../config/config";
import Flex from "../Layout/Flex";
import { css } from "@emotion/css";
import Tooltip from "../Tooltip";
import { isFirefox } from "react-device-detect";
import Image from "../Image";

interface Props {
    qaid: string;
    label: string;
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
    disabled?: boolean;
    tooltip?: ReactNode;
}

const Wrapper = css({
    label: "AddButtonWrapper",
    color: appConfig.style.colors.text2,
    background: "#ffffff",
    "&:hover:not(.disabled)": {
        background: "#ffffff",
        "> div": {
            background: appConfig.style.colors.purple,
            img: {
                "&.purple": {
                    display: "block",
                },
                "&:not(.purple)": {
                    display: "none",
                },
            },
        },
    },
    ".btn--label": {
        width: "max-content",
    },
});

const Button = styled.div({
    label: "AddButton",
    background: appConfig.style.colors.gradientPurpleVertical,
    border: `1px solid ${appConfig.style.colors.color1}`,
    borderRadius: "0.8rem",
    display: "flex",
    // "position": "absolute",
    // "top": "3rem",
    zIndex: 2,
    // "left": "90rem",
    cursor: "pointer",
    height: "3rem",
    width: "3rem",
    transition: "all .25s ease",
    float: "left",
    // "overflow": "hidden",
    padding: "0.8rem",
    position: "relative",
    img: {
        "&.purple": {
            display: "none",
        },
        "&:not(.purple)": {
            display: "block",
        },
    },
});

const AddButton = ({ onClick, qaid, style, className, disabled, label, tooltip }: Props) => {
    const Element = (
        <Flex
            className={classNames(Wrapper, "clickable", { disabled }, { [className || ""]: !!className })}
            flex={0}
            align="center"
            textWrap="nowrap"
            gap="1.2rem"
            onClick={() => !disabled && onClick?.()}
        >
            <Button
                style={style}
                data-qaid={qaid}
            >
                <Image
                    width={isFirefox ? "unset" : "2rem"}
                    src={IC_PLUS_WHITE2}
                    alt="add"
                />
                <Image
                    width={isFirefox ? "unset" : "2rem"}
                    src={IC_PLUS_PURPLE}
                    alt="add"
                    className="purple"
                />
            </Button>
            <span className="btn--label">{label}</span>
        </Flex>
    );
    return tooltip ? <Tooltip title={tooltip}>{Element}</Tooltip> : Element;
};

export default AddButton;
