import { css } from "@emotion/css";
import { useState } from "react";
import { signinRuleOptions } from "../../../../Models/API/Document/signin-rule-enum";
import { SignatoryDetails } from "../../../../Models/API/Document/template-info";
import { templateTypeOptions } from "../../../../Models/API/Document/template-type-enum";
import { DocumentTemplatePayload } from "../../../../Models/App/Document/template-payload";
import Button from "../../../../Shared/Components/Button/Button";
import { Comment } from "../../../../Shared/Components/Input/Input.Style";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import { ModalBodyProps } from "../../../../Shared/Components/Modal/types";
import Select from "../../../../Shared/Components/Select/Select";
import Separator from "../../../../Shared/Components/Separator";
import UploadFile from "../../../../Shared/Components/UploadFile";
import { useAppendState } from "../../../../Shared/Hooks/useAppendState";
import { InputValidationRef, useFormValidation } from "../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../Shared/ObjectValidator";
import { base64ToFile, fileToBase64, isNullOrUndefined, isNumber } from "../../../../Shared/Utilities";
import appConfig from "../../../../config/config";
import Signatories from "./Signatories";
import useGeneralModal from "../../../../Shared/Hooks/useGeneralModal";

const Style = css({
    label: "AddEditTemplate",
    display: "flex",
    flexDirection: "column",
    padding: "6rem 8rem",
    "&__title": {
        fontWeight: 500,
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        fontSize: "2.5rem",
        marginBottom: "4rem",
        paddingBottom: "1rem",
    },
    "&__form": {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridGap: "2.4rem",
        ".upload-container": {
            gridArea: "1 / 1 / 1 / 3",
        },
    },
});

type Props = ModalBodyProps & {
    data?: DocumentTemplatePayload;
    fileName?: string;
};

const AddEditTemplate = ({ removeModal, data, fileName }: Props) => {
    const isEdit = !!data?.templateId;
    const { documentsStore } = useRootStore();
    const { showErrorModal } = useGeneralModal();
    const [template, setTemplate, onInputHandler] = useAppendState<DocumentTemplatePayload>(data || { signers: [] });
    const [file, setFile] = useState<File | undefined>(
        data?.fileBase64 ? base64ToFile(data.fileBase64, `${fileName}.docx`, "docx") : undefined
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { formValidationState, validateForm, inputRefs } = useFormValidation({
        form: template,
        schema: {
            fileBase64: [commonValidators.required('Required a ".docx" file')],
            templateName: [commonValidators.required(), commonValidators.regex(/^[a-zA-Z0-9 ]+$/, "Please enter a valid name")],
            documentType: [commonValidators.required()],
            signinRule: [commonValidators.required()],
            signers: [
                (val) => {
                    const isValid =
                        !!val.length &&
                        val.some(
                            (obj) =>
                                (isNumber(obj.contactId) && obj.isDesignatedCompanySignatory) ||
                                (isNullOrUndefined(obj.contactId) && obj.isDesignatedCompanySignatory === false)
                        );
                    return {
                        isValid,
                        message: isValid ? undefined : "Required at least one signatory",
                    };
                },
            ],
        },
        isEdit,
    });

    const setData = (signers: SignatoryDetails[]) => {
        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            signers,
        }));
    };

    const onAddTemplate = async () => {
        if (!validateForm()) {
            if (!formValidationState?.fileBase64?.isValid) {
                document.querySelector("#fileBase64Error")?.classList.remove("hidden");
            }
            if (!formValidationState?.signers?.isValid) {
                document.querySelector("#signersError")?.classList.remove("hidden");
            }
            return;
        }

        const fixedTemplate: DocumentTemplatePayload = {
            ...template,
            signers: template.signers
                .map((signer) => ({ ...signer, contactId: signer.contactId === -1 ? null : signer.contactId }))
                .filter(
                    (signer) => isNumber(signer.contactId) || (signer.contactId === null && signer.isDesignatedCompanySignatory === false)
                ),
        };

        setIsLoading(true);
        const res = isEdit ? await documentsStore.updateTemplate(fixedTemplate) : await documentsStore.createTemplate(fixedTemplate);
        setIsLoading(false);
        if (res.isSuccess) removeModal?.();
        else showErrorModal(res.errorMessage, "Error");
    };

    return (
        <div className={Style}>
            <div className={`${Style}__title`}>{isEdit ? "Update " : "Add new "}template</div>
            <div className={`${Style}__form`}>
                <div className="upload-container">
                    <UploadFile
                        accept="docx"
                        onFileUpload={async (f) => {
                            const fileBase64 = await fileToBase64(f);
                            setTemplate((prev) => ({ ...prev, fileBase64 }));
                            setFile(f);
                        }}
                        file={file}
                    />
                    {formValidationState?.fileBase64?.message && (
                        <Comment
                            id="fileBase64Error"
                            error
                            className="hidden"
                        >
                            {formValidationState?.fileBase64?.message}
                        </Comment>
                    )}
                </div>
                <TextInput
                    qaid="AddEditTemplate.Input.Name"
                    label="Name"
                    name="templateName"
                    value={template.templateName}
                    onChange={onInputHandler}
                    required
                    ref={(el: InputValidationRef) => (inputRefs.templateName = el)}
                    error={formValidationState?.templateName?.message}
                />
                <Select
                    qaid="AddEditTemplate.Select.Type"
                    label="Type"
                    value={template.documentType}
                    options={templateTypeOptions}
                    name="documentType"
                    onChange={onInputHandler}
                    required
                    ref={(el: InputValidationRef) => (inputRefs.documentType = el)}
                    error={formValidationState?.documentType?.message}
                />
                <div>
                    <Signatories
                        data={template.signers}
                        setData={setData}
                        type={template.documentType}
                    />
                    {formValidationState?.signers?.message && (
                        <Comment
                            className="mt-2 hidden"
                            error
                            id="signersError"
                        >
                            {formValidationState?.signers?.message}
                        </Comment>
                    )}
                </div>
                <Select
                    qaid="AddEditTemplate.Select.Rule"
                    label="Signing Rule"
                    value={template.signinRule}
                    options={signinRuleOptions}
                    name="signinRule"
                    onChange={onInputHandler}
                    required
                    ref={(el: InputValidationRef) => (inputRefs.signinRule = el)}
                    error={formValidationState?.signinRule?.message}
                />
            </div>
            <Separator />
            <Button
                qaid="AddEditTemplate.Button.Add"
                label={isEdit ? "Update" : "Add"}
                position="end"
                onClick={onAddTemplate}
                isLoading={isLoading}
            />
        </div>
    );
};

export default AddEditTemplate;
