import { PayoffSelectionOptions } from "../../../../../../Models/API/Waterfall/IForm";

export const PerferenceTermsTooltipTitle = "Legal Agreement Examples";

export const PerferenceTermsTexts = {
	[PayoffSelectionOptions.NonParticipating]: {
		header: {
			title: "Non-participating preferred",
			info: "The preferred share class receives only its liquidation preference and has the option to convert into common shares",
		},
		example: [
			"Non-participating preferred",
			"1. …an amount per share equal to the greater of (i) the respective Original Issue Price applicable to the shares of Class XXX held by such holders, or (ii) such amount per share as would have been payable had all shares of Class XXX been converted into Common Stock pursuant to..",
			"2. Notwithstanding the above, for purposes of determining the amount each holder of shares of Preferred Stock is entitled to receive with respect to a Liquidation Event, each such holder of shares of a series of Preferred Stock shall be deemed to have converted (regardless of whether such holder actually converted) such holder’s shares of such series into shares of Common Stock immediately prior to the Liquidation Event if, as a result of an actual conversion, such holder would receive, in the aggregate, an amount greater than the amount that would be distributed to such holder if such holder did not convert such series of Preferred Stock into shares of Common Stock.",
		],
		selectExample: [
			[
				"An option to convert into common shares",
				"1. …if, as a result of an actual conversion, such holder would receive, in the aggregate, an amount greater than the amount that would be distributed to such holder if such holder did not convert such series of Preferred Stock into shares of Common Stock",
			],
		],
	},
	[PayoffSelectionOptions.Participating]: {
		header: {
			title: "Participating preferred with a cap",
			info: "If Its a participating preferred with a cap on the total amount, enter the total cap amount and mark as 'Yes' the option to convert into common shares. If its a nonparticipating preferred, enter the fixed liquidation preference amount and mark as 'No' the option to convert into common shares.",
		},
		example: [
			"Participating preferred with a cap (X2 in this example)",
			"Notwithstanding the provisions of Sections XXX, in the event a holder of Preferred Stock would receive pursuant to Sections XXX an aggregate amount per share of Preferred Stock that exceeds twice the relevant Issue Price for such share, such holder shall not be entitled to receive any amount in excess of twice the relevant Issue Price pursuant to Sections XXX with respect to such shares of Preferred Stock.",
		],
		selectExample: [
			[
				"The cap multiple includes interest (X2 in this example).",
				"1. …such holder shall not be entitled to receive any amount in excess of twice the relevant Issue Price plus cumulative interest and all declared and unpaid dividends.",
			],
			[
				"An option to convert into common shares.",
				"1. …if, as a result of an actual conversion, such holder would receive, in the aggregate, an amount greater than the amount that would be distributed to such holder if such holder did not convert such series of Preferred Stock into shares of Common Stock.",
			],
		],
	},
	[PayoffSelectionOptions.NoCap]: {
		header: {
			title: 'The preferred share class is fully participating with no cap ("Double dip")',
			info: "They receive a liquidation preference (typically their initial investment plus interest), and their pro-rata share of the remaining proceeds of the company.",
		},
		example: [
			"Fully participating preferred",
			"First, the holders of Preferred XXX Shares shall be entitled to receive , on a pro rata basis among themselves (on an as converted basis), for each Preferred XXX Share held by them, prior and in preference to the YYY Shares and the ZZZ Shares, by reason of their ownership thereof, an amount per each Preferred XXX Share equal to the Preferred XXX Original Issue Price of such Preferred XXX Share plus cumulative interest thereon at the rate of XYZ% per annum, compounded annually, from the date on which the Original Issue Price of such Preferred XXX Share has been received by the Company and until the date of the distribution, plus an amount equal to all declared and unpaid dividends.",
		],
	},
	[PayoffSelectionOptions.Convert]: {
		header: {
			title: "At a certain business value the preferred share class must convert into common shares",
			info: "A particular company value in a liquidation event situation may cause forced exchange of preference shares to common shares.",
		},
		example: [
			"Automatic conversion in a certain business value",
			"…automatically be converted into shares of Common Stock at the Conversion Rate at the time in effect for such series of Preferred Stock immediately upon the closing of this corporation’s sale of its Common Stock in a firm commitment underwritten public offering, as amended, where the gross proceeds to the corporation from such public offering price prior to deduction of underwriter commissions is not less than $XYM (a “Qualified Public Offering”).",
		],
	},
	[PayoffSelectionOptions.ProRata]: {
		header: {
			title: "The preferred share class has a cap on the pro-rata payoff, beyond which conversion is required",
			info: "The pro-rata includes any sum distributed to preference share holder beyond the liquidation preference sum.",
		},
		example: [
			"Automatic conversion at a pro-rata amount",
			"Notwithstanding the above, for purposes of determining the amount each holder of shares of Preferred Stock is entitled to receive with respect to a Liquidation Event, each such holder of shares of a series of Preferred Stock shall be deemed to have converted (regardless of whether such holder actually converted) such holder’s shares of such series into shares of Common Stock immediately prior to the Liquidation Event if such holder would receive, on its pro-rata basis, an amount greater than the amount of $XYM that would be distributed to such holder if such holder did not convert such series of Preferred Stock into shares of Common Stock.",
		],
	},
};
