import { observer } from 'mobx-react-lite';
import { IOrder, OrderType } from '../../../../Models/App/EquityPlans/Order';
// import { IGrantCancelation } from "../../../../Models/App/EquityPlans/Grants";
import { IGrantCancelation } from '../../../../Models/App/EquityPlans/GrantCancelations';

//import Input from "../../../../Shared/Components/Input/Input";
import Select from '../../../../Shared/Components/Select/Select';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import Drawer from '../../General/Drawer/Drawer';
import BeneficiaryDetails from '../Beneficiaries/BeneficiaryDetails/BeneficiaryDetails';
import GrantCardStyles from './GrantCard.style';
import GrantDetails from './GrantDetails/GrantDetails';
import VestingTable from './VestsTable/VestingTable';
import useEsopModals from '../../useEsopModals';
import { IBaseGrant } from '../../../../Models/App/EquityPlans/GrantEvents';
import { cancelationReason, cancelationType, orderTypes } from '../../../../Shared/StaticData/equityPlans';
import { formatDate, formatNumber, isString } from '../../../../Shared/Utilities';
import TableDrawer from '../../General/TableDrawer/TableDrawer';
import Menu from '../../../../Shared/Components/Menu';
import { IC_ADD_CERTIFICATE, IC_EYE_ACTION, IC_LETTER_CANCELLED, IC_LETTER_READY, IC_LETTER_SENT, IC_LETTER_SIGNED } from '../../../../Assets';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { TemplateTypeEnum } from '../../../../Models/API/Document/template-type-enum';
import Button from '../../../../Shared/Components/Button/Button';
import useEffectOnce from '../../../../Shared/Hooks/useEffectOnce';
import Image from '../../../../Shared/Components/Image';
import { ElectronicSignatureStatus } from '../../../../Models/API/Document/eletronic-signature-status-enum';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useTemplate from '../../../Documents/helpers/hooks/useTemplate';
import useDocument from '../../../Documents/helpers/hooks/useDocument';

interface OrdersTable {
	orderId: number;
	orderType: string;
	orderDate: Date;
	amount: number;
}

interface CancellationsTable {
	cancelId: number;
	date: Date;
	reason: string;
	cancelType: string;
	grace: string;
}

interface GrantCardProps {
	isEdit?: boolean;
}

const GrantCard = (props: GrantCardProps) => {
	const { equityPlansStore, documentsStore } = useRootStore();
	const { newOrderHandler, showCancelationModal } = useEsopModals();
	const [isAdd, setIsAdd] = useState<boolean>(false);
	const [isAdding, setIsAdding] = useState<boolean>(false);
	const [grantLetterTemplateId, setGrantLetterTemplateId] = useState<string>();
	const { previewDocument } = useDocument();
	const { showErrorModal } = useGeneralModal();

	useEffectOnce(
		() => {
			if (!isAdd) return;

			documentsStore.getDocuments();
		},
		[isAdd],
		isAdd
	);

	const { grantId, beneficiaryId, documentDetailsDto } = equityPlansStore.beneficiaryGrantData;

	const ordersTableColumns: TableColumn<IOrder>[] = [
		{
			label: 'Order number',
			name: 'orderNumber',
		},
		{
			label: 'Grant number',
			name: 'grantNumber',
		},
		// TODO: ADD This

		// {
		// 	label: "Grant type",
		// 	name: "grantType",
		// 	format: (val) => grantTypes[val]?.label,
		// 	sortable: false,
		// 	style: { flex: 1.5 }
		// },
		{
			label: 'Order type',
			name: 'orderType',
			format(val) {
				return orderTypes[val]?.label;
			},
		},
		{
			label: 'Order date',
			name: 'date',
			format(val) {
				return formatDate(val);
			},
		},
		{
			label: 'Amount',
			name: 'amount',
			format: (val) => formatNumber(val),
		},
		{
			label: 'Sale Price',
			name: 'sellPrice',
			format(val, obj) {
				return obj?.orderType === OrderType.Exercise ? '' : val;
			},
		},
		{
			label: 'Exercise price',
			name: 'exercisePrice',
		},
	];

	const vestColumns: TableColumn<IBaseGrant>[] = [
		{
			name: 'date',
			label: 'Date',
			format(val) {
				return formatDate(val);
			},
		},
		{
			name: 'granted',
			label: 'Granted',
			format: (val) => formatNumber(val),
		},
		{
			name: 'vested',
			label: 'Vested',
			format: (val) => formatNumber(val),
		},
		// {
		//     name: "exercised",
		//     label: "Exercised",
		//     format: (val) => formatNumber(val),
		// },
		// {
		//     name: "sellable",
		//     label: "Shares",
		//     format: (val) => formatNumber(val),
		// },
		// {
		//     name: "outstanding",
		//     label: "Outstanding",
		//     format: (val) => formatNumber(val),
		// },
	];

	const cancelationsTableColumns: TableColumn<IGrantCancelation>[] = [
		{
			label: 'Cancelation number',
			name: 'grantCancelationId',
		},
		{
			label: 'Cancel type',
			name: 'cancelType',
			format(val, obj) {
				if (!obj) return;
				return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
			},
		},
		{
			label: 'Cancel date',
			name: 'cancelationDate',
			format(val) {
				return formatDate(val);
			},
		},
		{
			label: 'Cancel in',
			name: 'gracePeriodNum',
		},
		{
			label: 'Cancel reason',
			name: 'cancelationReason',
			format(val, obj) {
				if (!obj) return;
				return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
			},
		},
		{
			label: 'Amount',
			name: 'amountToCancel',
			format: (val) => formatNumber(val),
		},
	];

	const getDrawerHeader = (title: string, postfix: string, total: number = 0) => {
		return (
			<div className={`${GrantCardStyles}__drawer-header`}>
				<span className="bold">{title}</span>
				<span className="header-comment">
					({total} {postfix})
				</span>
			</div>
		);
	};

	return (
		<>
			<div className={GrantCardStyles}>
				<GrantDetails isEdit={props.isEdit} />

				{documentsStore.isModuleActive && (
					<div className={`${GrantCardStyles}__docs`}>
						<div className="info">
							<span className="title bold">Documents</span>
							<div className="menu-container">
								{documentDetailsDto?.map((doc) => (
									<Image
										className="clickable"
										key={doc.documentId}
										onClick={() => previewDocument(doc.documentId, doc.templateId)}
										src={
											doc.isCancelled
												? IC_LETTER_CANCELLED
												: doc.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
												? IC_LETTER_READY
												: doc.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
												? IC_LETTER_SENT
												: doc.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
												? IC_LETTER_SIGNED
												: undefined
										}
									/>
								))}
								<Menu
									className="menu"
									isHorizontal
									position={{ top: 'calc(100% + 10px)' }}
									items={[
										{
											qaid: 'GrantCard.Button.CreateGrantLetter',
											icon: IC_EYE_ACTION,
											label: 'View grant letter',
											props: { width: '3rem' },
										},
										{
											qaid: 'GrantCard.Button.CreateGrantLetter',
											icon: IC_ADD_CERTIFICATE,
											label: documentDetailsDto?.some((doc) => !doc.isCancelled) ? 'Grant letter already created' : 'Create grant letter',
											props: { width: '3rem' },
											disabled: documentDetailsDto?.some((doc) => !doc.isCancelled),
											onClick: () => setIsAdd((prev) => !prev),
										},
									]}
								/>
							</div>
						</div>
						<Collapse in={isAdd} unmountOnExit>
							<div className="add-grant-letter">
								<Select
									qaid="GrantCard.Select.Templates"
									label="Grant Letter template"
									options={documentsStore.getTemplatesOptionsByType(TemplateTypeEnum.GrantLetter)}
									value={grantLetterTemplateId}
									onChange={(value) => isString(value) && setGrantLetterTemplateId(value)}
									required
								/>
								<Button
									qaid="GrantCard.Button.AddTemplate"
									label="Add"
									position="end"
									isLoading={isAdding}
									onClick={async () => {
										if (!grantId || !grantLetterTemplateId) return;
										setIsAdding(true);
										const res = await equityPlansStore.createGrantLetter(
											grantId,
											grantLetterTemplateId,
											documentsStore.templates?.find((t) => t.templateId === grantLetterTemplateId)?.variablesCollection
										);
										if (res.isSuccess) {
											await equityPlansStore.GetSingleGrant(grantId);
											equityPlansStore.GetGrantsForCompany();
										} else {
											showErrorModal(res.errorMessage);
										}
										setIsAdd(false);
										setIsAdding(false);
									}}
								/>
							</div>
						</Collapse>
					</div>
				)}

				<Table
					columns={vestColumns}
					headerSize={1.4}
					rowSize={1.4}
					rows={equityPlansStore.originalGrantEvents}
					scrollAfterRows={6}
					customHeaderRender={<div className="bold">Vesting schedule</div>}
				/>
				{/* Orders */}

				<TableDrawer
					tableColumns={ordersTableColumns}
					tableData={equityPlansStore.grantOrders}
					actionBtn={{ desc: 'Add new order', func: newOrderHandler }}
					// header={`Grant Orders (${equityPlansStore.grantOrders ? equityPlansStore.grantOrders.length : 0} Orders)`}
					header={getDrawerHeader(
						'Grant orders',
						(equityPlansStore.grantOrders?.length ?? 0) > 1 ? 'orders' : 'order',
						equityPlansStore.grantOrders?.length
					)}
				/>

				{/* Cancelations */}

				<TableDrawer
					tableColumns={cancelationsTableColumns}
					tableData={equityPlansStore.grantCancelations}
					actionBtn={{ desc: 'Add new grant cancelation', func: () => showCancelationModal() }}
					// header={`Grant Cancelations   (${equityPlansStore.grantCancelations ? equityPlansStore.grantCancelations.length : 0} Cancelations)`}
					header={getDrawerHeader('Grant cancelations', 'cancelations', equityPlansStore.grantCancelations?.length)}
				/>
			</div>
		</>
	);
};

export default observer(GrantCard);

// <div className="header">Grant Details</div>
//                 <div className="container">
//                     <div className="column">
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                                 label="Award Type"
//                                 value={equityPlansStore.grant?.grantType}
//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                                 datePicker
//                                 label="Grant Date"
//                                 value={equityPlansStore.grant?.grantDate.toISOString()}

//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                                 label="Vest. Begin Date"
//                                 datePicker
//                                 value={equityPlansStore.grant?.vestStartDate.toISOString()}
//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                                 label="Granted"
//                                 number
//                                 value={equityPlansStore.grant?.granted}
//                             />
//                         </div>
//                         <div className="input">
//                             <Select
//                                 onChange={() => { }}
//                                 options={[{ value: 0, label: "Balh" }, { value: 0, label: "Balh" }, { value: 0, label: "Balh" }]}
//                                 qaid=""
//                                 label="Blah Selector"
//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                             />
//                         </div>
//                     </div>
//                     <div className="column">
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                                 datePicker
//                             />
//                         </div>
//                         <div className="input">
//                             <Input
//                                 qaid=""
//                             />
//                         </div>
//                     </div>
//                 </div>
