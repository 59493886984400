import { Tooltip as MaterialTooltip } from "@mui/material";
import classNames from "classnames";
import React, { CSSProperties, ReactNode, isValidElement, useState } from "react";
import { IC_QUESTION_MARK_ORANGE, IC_QUESTION_MARK_ORANGE_FILL } from "../../../Assets";
import Image from "../Image";
import { isNullOrUndefined } from "../../Utilities";
import { css } from "@emotion/css";

interface Props {
    className?: string;
    style?: CSSProperties;
    title: ReactNode | undefined;
    disabled?: boolean;
    children?: React.ReactChild | React.ReactChild[] | false | Element;
    showIcon?: boolean;
}

const Style = css({
    label: "Tooltip",
    width: "1.8rem",
});

const Tooltip: React.FC<Props> = ({ title, className = "", children, showIcon = false }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    if (isNullOrUndefined(title)) return isValidElement(children) ? children : <></>;

    if (children) {
        return (
            <MaterialTooltip
                title={title}
                enterTouchDelay={0}
            >
                {isValidElement(children) ? (
                    showIcon ? (
                        <div
                            className="pointer"
                            onMouseEnter={() => setIsHovering(true)}
                            onMouseLeave={() => setIsHovering(false)}
                        >
                            {children}
                            {showIcon && (
                                <Image
                                    style={{ marginLeft: "0.8rem" }}
                                    pointer
                                    className={classNames("question-mark", { [className]: !!className })}
                                    src={isHovering ? IC_QUESTION_MARK_ORANGE_FILL : IC_QUESTION_MARK_ORANGE}
                                    alt=""
                                />
                            )}
                        </div>
                    ) : (
                        children
                    )
                ) : (
                    <span
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        className="pointer"
                    >
                        {children}{" "}
                        {showIcon && (
                            <Image
                                style={{ marginLeft: "0.8rem" }}
                                pointer
                                className={classNames("question-mark", { [className]: !!className })}
                                src={isHovering ? IC_QUESTION_MARK_ORANGE_FILL : IC_QUESTION_MARK_ORANGE}
                                alt=""
                            />
                        )}
                    </span>
                )}
            </MaterialTooltip>
        );
    }

    if (showIcon) {
        return (
            <div>
                {children}
                <Image
                    style={{ marginLeft: "0.8rem" }}
                    pointer
                    className={classNames("question-mark", { [className]: !!className })}
                    src={IC_QUESTION_MARK_ORANGE}
                    srcHover={IC_QUESTION_MARK_ORANGE_FILL}
                    alt=""
                    tooltip={title}
                />
            </div>
        );
    }

    return (
        <Image
            pointer
            className={classNames(Style, "question-mark", { [className]: !!className })}
            src={IC_QUESTION_MARK_ORANGE}
            srcHover={IC_QUESTION_MARK_ORANGE_FILL}
            alt=""
            tooltip={title}
        />
    );
};

export default Tooltip;
