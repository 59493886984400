import { css } from "@emotion/css";
import { breakpoints } from "../../../../../../config/style/theme";

const PreferencesTermsStyle = css({
	label: "PreferencesTerms",
	display: "flex",
	flexDirection: 'column',
	// maxWidth: "168rem",
	// marginInline: 'auto',
	width: "100%",
	"&__add-class": {
		display: "grid",
		gridGap: "10rem",
		gridTemplateAreas: "'extra main'",
		gridTemplateColumns: "20rem 1fr",
		width: "100%",
		maxWidth: "138rem",
		marginInline: "auto",
		minHeight: "27rem",
		transition: 'transform .3s',
		[`@media screen and (min-width: ${breakpoints.lg})`]: {
			transform: "translate(-15rem, 0)",
		}
	},
	".class-right-edit": {
        background: "#d8d8d8 !important",
        opacity: 0.5
    }
});

export default PreferencesTermsStyle;
