import styled from "@emotion/styled";
import appConfig from "../../../config/config";
import { ModalProps } from "./types";
import { css } from "@emotion/css";

const Wrapper = styled.div(
    {
        label: "ModalContainer",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        // height: "100vh",
        display: "flex",
        padding: "4rem",
        animation: "fadeIn 0.25s linear",
        "@media screen and (max-width: 768px)": {
            padding: "1rem",
        },
    },
    ({
        zIndex,
        shake,
        hidden,
        relative,
        background,
        isFirst,
    }: {
        zIndex?: number;
        shake?: boolean;
        hidden?: boolean;
        relative?: boolean;
        background?: string;
        isFirst?: boolean;
    }) => ({
        zIndex,
        background: isFirst ? background || appConfig.style.colors.background2Opacity70 : "transparent",
        opacity: hidden ? 0 : 1,
        // height: relative ? "100%" : "100vh",
        height: "100%",
    })
);

const Modal = styled.div(
    {
        label: "Modal",
        margin: "auto",
        background: "#ffffff",
        boxShadow: appConfig.style.boxShadow.card1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        transition: "all .5s",
        borderRadius: 16,
        "@media screen and (max-width: 768px)": {
            width: "100%",
        },
    },
    ({
        width,
        height,
        fullscreen,
        type,
        maxWidth = "100%",
        maxHeight = "100%",
        allowScrolling = true,
    }: Pick<ModalProps, "width" | "height" | "fullscreen" | "type" | "maxHeight" | "maxWidth" | "allowScrolling">) => ({
        ...(width === "auto" ? {} : { width: fullscreen ? "100%" : width || "40%" }),
        ...(height === "auto" ? {} : { height: fullscreen ? "100%" : height || "fit-content" }),
        textAlign: type === "dialog" ? "inherit" : "center",
        // minWidth: "40%",
        // minHeight: "18%",
        maxWidth,
        maxHeight,
        overflow: allowScrolling ? "auto" : "unset",
    })
);

const Body = styled.div(
    {
        label: "Modal-Body",
        height: "100%",
        padding: "2rem",
        borderRadius: 16,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
    },
    ({ center = false, allowScrolling = true }: { center?: boolean; allowScrolling?: boolean }) => ({
        ...(center
            ? {
                  padding: "5rem 7rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
              }
            : {}),
        overflow: allowScrolling ? "auto" : "unset",
    })
);

const Close = styled.div({
    label: "Close",
    position: "absolute",
    top: 23,
    right: 20,
    fontWeight: "bold",
    fontSize: "1.6rem",
    cursor: "pointer",
    display: "flex",
    zIndex: 10,
});

const Back = styled.div({
    label: "Back",
    position: "absolute",
    top: 23,
    left: 14,
    fontWeight: "bold",
    fontSize: "1.6rem",
    cursor: "pointer",
    display: "flex",
    zIndex: 10,
});

const Backdrop = styled.div({
    label: "BackDrop",
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0.75,
    background: appConfig.style.colors.background1,
    borderRadius: 16,
    zIndex: 3,
});

const Actions = styled.div({
    display: "flex",
    marginTop: "auto",
    justifyContent: "center",
    gap: "1.2rem",
    padding: "1.6rem 2rem",
    paddingBottom: "5rem",
});

const ProgressBar = styled.div(
    {
        width: "100%",
    },
    ({ timeout = 0 }: Pick<ModalProps, "timeout">) => ({
        background: appConfig.style.colors.color1,
        height: 6,
        animation: `progressLeftRight ${timeout + 250}ms ease-out`, // 250ms of fade in
        borderBottomLeftRadius: 6,
    })
);

const Image = styled.img({
    label: "Image",
    width: "6.4rem",
    marginBottom: "1.6rem",
});

const Input = styled.input({
    label: "PromptInput",
    marginTop: "2rem",
    flexBasis: "3.6rem",
    maxWidth: "30rem",
});

const Container = {
    Wrapper,
    Modal,
    Body,
    Close,
    Actions,
    ProgressBar,
    Back,
    Backdrop,
    Image,
    Input,
};

export default Container;
