import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NumberOption } from "../../../../../../../Models/API/All/NumberOption";
import { ProjectsUserInfo } from "../../../../../../../Models/API/CapTable";
import { ProductTypeEnum } from "../../../../../../../Models/API/enums";
import Button from "../../../../../../../Shared/Components/Button/Button";
import Heading from "../../../../../../../Shared/Components/Heading/Heading";
import AutoCompleteInput from "../../../../../../../Shared/Components/Input/AutoCompleteInput";
import Flex from "../../../../../../../Shared/Components/Layout/Flex";
import RadioButton from "../../../../../../../Shared/Components/RadioButton/RadioButton";
import Select from "../../../../../../../Shared/Components/Select/Select";
import useRootStore from "../../../../../../../Shared/Hooks/useRootStore";
import { formatDate, isNumber } from "../../../../../../../Shared/Utilities";
import { colors } from "../../../../../../../config/style/lib/colors";
import { theme } from "../../../../../../../config/style/theme";
import { ModalContent } from "../CapTable.style";
import useGeneralModal from "../../../../../../../Shared/Hooks/useGeneralModal";

enum DraftSourcesEnum {
    capTable = 10,
    funding = 20,
}

export const draftSources: NumberOption[] = [
    { value: DraftSourcesEnum.capTable, label: "nav.capTable" },
    { value: DraftSourcesEnum.funding, label: "nav.funding" },
];

type ImportTypes = "importDraft" | "importPublishedCaptable";

const setInitialSourceType = (selectedProject: ProjectsUserInfo | undefined) => {
    if (!selectedProject) return undefined;

    if (selectedProject.isPublished === false) {
        return "importDraft";
    }

    if (selectedProject?.productType === ProductTypeEnum.CapTable && selectedProject.isPublished === true) {
        return "importPublishedCaptable";
    }
};

const setInitialDraftSourceType = (selectedProject: ProjectsUserInfo | undefined) => {
    if (!selectedProject) return undefined;

    if (selectedProject.productType === ProductTypeEnum.CapTable && selectedProject.isPublished === false) {
        return draftSources[0];
    }

    if (selectedProject.productType === ProductTypeEnum.NextRound && selectedProject.isPublished === false) {
        return draftSources[1];
    }
};

const setInitialDraftSource = (selectedProject: ProjectsUserInfo | undefined) => {
    if (!selectedProject) return undefined;

    if (selectedProject.projectID) {
        return selectedProject.projectID;
    }

    return undefined;
};

const SelectImportSource: React.FC = () => {
    const { companyStore, waterfallStore } = useRootStore();
    const { showErrorModal } = useGeneralModal();

    const resetSelection = () => {
        setSourceType(undefined);
        setDraftSourceType(undefined);
        setSpecificDraft(undefined);
    };

    useEffect(() => {
        if (waterfallStore.publishedCapTable?.updatedDate) {
            const formattedDate = formatDate(waterfallStore.publishedCapTable.updatedDate);
            formattedDate && setPublishedCapTableDate(formattedDate);
        } else {
            setSourceType("importDraft");
        }
    }, [waterfallStore.publishedCapTable]);

    useEffect(() => {
        const selectedCapTableBase = waterfallStore.selectedCapTableBase;
        if (selectedCapTableBase) {
            // Set the initial source type ('importDraft' or 'importPublishedCaptable')
            const initialSourceType = setInitialSourceType(selectedCapTableBase);
            setSourceType(initialSourceType);

            // Set the initial draft source type (based on DraftSourcesEnum)
            const initialDraftSourceType = setInitialDraftSourceType(selectedCapTableBase);
            setDraftSourceType(initialDraftSourceType);

            // Set the initial specific draft (projectID)
            const initialDraftSource = setInitialDraftSource(selectedCapTableBase);
            setSpecificDraft(initialDraftSource);
        }
    }, [waterfallStore.selectedCapTableBase]);

    // 1. the user selects whether to import a published cap table or a draft (radio button)
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sourceType, setSourceType] = useState<ImportTypes | undefined>(undefined);

    // 3. if the user selected "importDraft":
    //  A. the user selects the draft type (captable draft / funding draft )
    const [draftSourceType, setDraftSourceType] = useState<NumberOption | undefined>(undefined);
    //  B. the user selects a specific draft (dropdown)
    const [specificDraft, setSpecificDraft] = useState<number | undefined>(undefined);

    // 3. if user selects "importPublishedCaptable", we need to show the date of the published cap table:
    const [publishedCapTableDate, setPublishedCapTableDate] = useState<string | undefined>(undefined);

    const handleRadioChange = (value: ImportTypes) => {
        setSourceType(value);

        if (value === "importPublishedCaptable") {
            const base = waterfallStore.publishedCapTable;
            waterfallStore.setSelectedCapTableBase(base);
        }
    };

    useEffect(() => {
        if (waterfallStore.publishedCapTable) {
            waterfallStore.setSelectedCapTableBase(waterfallStore.publishedCapTable);
            // handleImportBase();
        }
    }, [waterfallStore.publishedCapTable]);

    const draftOptions = useMemo(() => {
        if (!draftSourceType || !waterfallStore.capTableDrafts || !waterfallStore.fundingDrafts) return [];
        if (draftSourceType.value === DraftSourcesEnum.capTable) {
            return waterfallStore.capTableDrafts.map((draft) => ({
                label: draft.projectName,
                value: draft.projectID,
            }));
        } else if (draftSourceType.value === DraftSourcesEnum.funding) {
            return waterfallStore.fundingDrafts.map((draft) => ({
                label: draft.projectName,
                value: draft.projectID,
            }));
        }
        return [];
    }, [waterfallStore.capTableDrafts, waterfallStore.fundingDrafts, draftSourceType]);

    const handleSelectBase = (projectID: number) => {
        const base = waterfallStore.capTableBases?.find((draft) => draft.projectID === projectID);
        if (!base) return;
        setSpecificDraft(base.projectID);
        waterfallStore.setSelectedCapTableBase(base);
    };

    const handleImportBase = async () => {
        setIsLoading(true);
        if (!waterfallStore.selectedCapTableBase) return;

        const res = await waterfallStore.getSingleCapTableData({
            companyId: companyStore.company.companyId,
            waterfallId: waterfallStore.waterfallId,
            projectId: waterfallStore.selectedCapTableBase.projectID,
        });

        setIsLoading(false);

        if (res?.error) {
            showErrorModal("An error occurred while trying to import cap. table data");
        } else {
            waterfallStore.setShowSelectImportSource(false);
        }
    };

    return (
        <ModalContent>
            <Heading
                ap={{
                    tag: "h5",
                    spacingBottom: "md",
                    isBold: true,
                }}
            >
                {t("waterfall.tableToImport")}
            </Heading>

            {!waterfallStore.capTableBases && (
                <Heading
                    ap={{
                        color: "error",
                        tag: "h3",
                        spacingBottom: "md",
                    }}
                >
                    {t("waterfall.noCapTableComment")}
                </Heading>
            )}

            <Flex
                justify="start"
                align="center"
                gap={theme.spacings.md}
                style={{ height: "3.6rem" }}
            >
                <RadioButton
                    autoTranslate
                    qaid="Import.CheckBox.PublishedCapTable"
                    name="SelectBaseType"
                    value="importPublishedCaptable"
                    onChange={(value) => handleRadioChange(value)}
                    checked={sourceType === "importPublishedCaptable"}
                    disabled={!waterfallStore.publishedCapTable}
                >
                    {waterfallStore.publishedCapTable ? (
                        <Trans
                            i18nKey="waterfall.publishedCapTable"
                            values={{ publishedCapTableDate }}
                            components={[<span className="bold ml-2" />]}
                        />
                    ) : (
                        <span style={{ color: colors.error }}>{t("waterfall.noPublishedCapTable")}</span>
                    )}
                </RadioButton>

                {sourceType !== "importPublishedCaptable" ||
                    (waterfallStore.publishedCapTable && (
                        <Button
                            disabled={
                                waterfallStore.selectedCapTableBase?.productType !== ProductTypeEnum["CapTable"] ||
                                waterfallStore.isViewMode
                            }
                            qaid="SelectImportSource.Button.ImportPubslishedCapTable"
                            onClick={() => {
                                waterfallStore.setSelectedCapTableBase(waterfallStore.publishedCapTable);
                                handleImportBase();
                            }}
                            label="waterfall.import"
                        />
                    ))}
            </Flex>

            <Flex
                justify="start"
                align="center"
                gap={theme.spacings.md}
                style={{ height: "auto" }}
            >
                <RadioButton
                    autoTranslate
                    qaid="Import.CheckBox.ImportDraft"
                    name="SelectBaseType"
                    value="importDraft"
                    onChange={(value) => handleRadioChange(value)}
                    checked={sourceType === "importDraft"}
                    disabled={!waterfallStore.drafts}
                >
                    {waterfallStore.drafts ? (
                        <div style={{ whiteSpace: "nowrap" }}>{t("waterfall.importFromDraft")}</div>
                    ) : (
                        <div style={{ color: colors.error }}>{t("waterfall.noSavedDrafts")}</div>
                    )}
                </RadioButton>

                {/* select draft type: */}

                <Select
                    style={{ marginBottom: 0, width: "22rem" }}
                    qaid="SelectImportSource.Select.SelectDraftType"
                    value={draftSourceType?.value}
                    options={draftSources}
                    onChange={(value) => {
                        setDraftSourceType(draftSources?.find((source) => source.value === value) || undefined);
                        handleRadioChange("importDraft");
                    }}
                />

                {/* select draft: */}

                <AutoCompleteInput
                    qaid="SelectImportSource.Input.SelectDraftType"
                    options={draftOptions}
                    value={specificDraft}
                    onChange={(value) => {
                        if (!isNumber(value)) return;
                        handleRadioChange("importDraft");
                        handleSelectBase(value);
                    }}
                    strict
                    containerStyle={{ margin: 0, minWidth: 220 }}
                />

                <Button
                    qaid="SelectImportSource.Button.ImportDraft"
                    onClick={handleImportBase}
                    isLoading={isLoading}
                    label="waterfall.import"
                    disabled={
                        !waterfallStore.selectedCapTableBase ||
                        waterfallStore.selectedCapTableBase.isPublished === true ||
                        waterfallStore.isViewMode
                    }
                />
            </Flex>
        </ModalContent>
    );
};

export default observer(SelectImportSource);
