import classNames from "classnames";
import { NumberOption } from "../../../Models/API/All/NumberOption";
import { TemplateTypeEnum } from "../../../Models/API/Document/template-type-enum";
import { CapTablePermission, OptionsPermission } from "../../../Models/API/UsersAndPermissions/permissions-enum";
import { UsersPermissionsByCompany } from "../../../Models/API/UsersAndPermissions/users-permissions-by-company";
import Image from "../../../Shared/Components/Image";
import { isNullOrUndefined } from "../../../Shared/Utilities";
import { InfoType } from "../Components/Preview";
import { css } from "@emotion/css";

export const getTemplateVariableName = (type?: TemplateTypeEnum): NumberOption | undefined => {
    if (isNullOrUndefined(type) || type === TemplateTypeEnum.Other) return;

    return {
        value: -1,
        label: type === TemplateTypeEnum.ShareCertificate ? "{Shareholder}" : "{Beneficiary}",
    };
};

export const isAdminOrEditor = (user: UsersPermissionsByCompany, type?: TemplateTypeEnum): boolean => {
    if (user.isAdmin) return true;
    switch (type) {
        case TemplateTypeEnum.GrantLetter:
        case TemplateTypeEnum.NoticeOfExercise:
            return user.optionPermission === OptionsPermission.admin || user.optionPermission === OptionsPermission.editor;
        case TemplateTypeEnum.ShareCertificate:
            return user.capTablePermission === CapTablePermission.admin || user.capTablePermission === CapTablePermission.editor;
        default:
            return false;
    }
};

export const renderInfo = ({ icon, text, title }: InfoType, className: string = "") => {
    const Style = css({
        label: "TooltipInfo",
        // margin: "1.2rem 0",
        display: "flex",
        alignItems: "flex-start",
        gap: "2rem",
        ".textual": {
            flex: "1",
            display: "flex",
            fontSize: "1.2rem",
            flexDirection: "column",
            "> span:first-child": {
                fontWeight: "500",
                marginTop: "1px",
                marginBottom: "0.2rem",
                color: "#ffffff",
            },
            "> span:not(:first-child)": {
                color: "#c0c0c0",
            },
        },
    });

    const LENGTH_TRESHTOLD = 20;

    return (
        <div
            className={classNames(Style, { [className]: !!className })}
            key={title}
        >
            <Image
                src={icon}
                alt={title}
                width="2.4rem"
            />
            <div className="textual">
                <span>{title}</span>
                {text.map((t, idx) => (
                    <span key={idx}>
                        {text.length > 1 ? `${idx + 1}. ` : ""}
                        {t.length > LENGTH_TRESHTOLD ? `${t.slice(0, LENGTH_TRESHTOLD)}...` : t}
                    </span>
                ))}
            </div>
        </div>
    );
};
