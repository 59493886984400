import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Separator from '../../../../../Shared/Components/Separator';
import ReportSettingsStyle from './index.style';
import { EditableGrantReport } from '../../../../../Models/App/Expensing/editable-grant-report';
import TextInput from '../../../../../Shared/Components/Input/TextInput';
import DatePickerInput from '../../../../../Shared/Components/Input/DatePickerInput';
import Select from '../../../../../Shared/Components/Select/Select';
import { useAppendState } from '../../../../../Shared/Hooks/useAppendState';
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import { ReportSettings } from '../../../../../Models/App/Expensing/settings';
import NumberInput from '../../../../../Shared/Components/Input/NumberInput';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import { NumberOption } from '../../../../../Models/API/All/NumberOption';
import { GrantReportPeriodEnum } from '../../../../../Models/API/Expensing/grant-report-period-enum';
import { addDaysToDate, addMonthsToDate, formatDate, getLastDayOfMonath, isNullOrUndefined, isNumber } from '../../../../../Shared/Utilities';
import { Collapse, Fade } from '@mui/material';
import { InputValidationRef, useFormValidation } from '../../../../../Shared/Hooks/useFormValidation';
import { commonValidators } from '../../../../../Shared/ObjectValidator';
import { ForwardedRefType } from '../../../../../Shared/Hooks/useMultiStepForm';
import CheckBox from '../../../../../Shared/Components/CheckBox/CheckBox';
import { GrantReportData } from '../../../../../Models/App/Expensing/report-data';
import { ExpenseReportStatus } from '../../../../../Models/API/Expensing/expense-report';
import { checkIfLastDayOfMonth } from '../../../helpers/util';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

type Props = {
	data?: {
		report: EditableGrantReport;
		settings: ReportSettings;
	};
	isEdit?: boolean;
};

const Report = forwardRef<ForwardedRefType<GrantReportData>, Props>(({ data, isEdit = false }, forwardedRef) => {
	const {
		expensingStore: { companyCurrency, expenseReports, selectedReport },
	} = useRootStore();
	const [report, setReport, onInputHandler] = useAppendState<EditableGrantReport>({ previousExpenseCompanyReportId: null });
	const { validateForm, inputRefs, formValidationState } = useFormValidation({
		form: report,
		schema: {
			expenseCompanyReportName: [commonValidators.required()],
			reportStartDate: [commonValidators.required()],
			period: [commonValidators.required()],
			reportEndDate: [commonValidators.required()],
			assetPrice: [commonValidators.required(), commonValidators.graterThan(0)],
			previousExpenseCompanyReportId: [commonValidators.customValidation(report.previousExpenseCompanyReportId !== 0, 'Required field')],
		},
	});
	const isViewOnly = isEdit && selectedReport?.status === ExpenseReportStatus.Publish;

	const [settings, setSettings, onSettingsInputHandler] = useAppendState<ReportSettings>({
		forfeitureRate: undefined,
	});

	const {
		formValidationState: settingsValidationState,
		inputRefs: settingsInputRefs,
		validateForm: settingsValidateForm,
	} = useFormValidation({
		form: settings,
		schema: {
			// forfeitureRate: [commonValidators.requiredIf((val) => val !== null)],
		},
	});

	useImperativeHandle(forwardedRef, () => ({
		async onValidate() {
			const isReportValid = validateForm();
			return settingsValidateForm() && isReportValid;
		},
		getData: () => ({
			settings,
			report,
		}),
	}));

	const periodOptions: NumberOption[] = useMemo(() => {
		let array = Object.keys(GrantReportPeriodEnum);
		array = array.slice(array.length / 2);
		return array.map((label, idx) => ({ value: idx, label }));
	}, []);

	useEffect(() => {
		if (!data) return;

		setReport(data.report);
		setSettings(data.settings);
	}, [data]);

	// useEffect(() => {
	//     setTimeout(() => {
	//         setReport((prevReport) => ({
	//             ...prevReport,
	//             reportStartDate: getStartDateByPeriod(prevReport.period, prevReport.reportEndDate),
	//         }));
	//     }, 100); // 100 for collapse
	// }, [report.period, report.reportEndDate]);

	const getStartDateByPeriod = (value?: GrantReportPeriodEnum, date?: Date): Date | undefined => {
		if (isNullOrUndefined(value) || isNullOrUndefined(date)) return;

		date = new Date(date);
		let d: Date;
		const isLastDay = checkIfLastDayOfMonth(date);

		switch (value) {
			case GrantReportPeriodEnum.Monthly:
				if (isLastDay) return new Date(date.getFullYear(), date.getMonth(), 1);

				d = addDaysToDate(addMonthsToDate(date, -1), 1);
				return new Date(d);
			case GrantReportPeriodEnum.Quarterly:
				d = addDaysToDate(addMonthsToDate(date, -3), 1);
				return isLastDay ? new Date(d.getFullYear(), d.getMonth(), 1) : d;
			case GrantReportPeriodEnum.Annualy:
				return addDaysToDate(addMonthsToDate(date, -12), 1);
			default:
				return undefined;
		}
	};

	const getEndDateByPeriod = (value?: GrantReportPeriodEnum, date?: Date): Date | undefined => {
		if (isNullOrUndefined(value) || isNullOrUndefined(date)) return;

		date = new Date(date);
		let d: Date;

		switch (value) {
			case GrantReportPeriodEnum.Monthly:
				return getLastDayOfMonath(date);
			case GrantReportPeriodEnum.Quarterly:
				d = addMonthsToDate(date, 2);

				return getLastDayOfMonath(d);
			case GrantReportPeriodEnum.Annualy:
				d = addMonthsToDate(date, 11);

				return getLastDayOfMonath(d);
			default:
				return undefined;
		}
	};

	const basedOnReport = expenseReports.find((r) => r.expenseCompanyReportId === report.previousExpenseCompanyReportId);

	return (
		<div className={ReportSettingsStyle}>
			<div className={`${ReportSettingsStyle}__section`}>
				<span className={`${ReportSettingsStyle}__label`}>Report settings</span>
				<Separator />
				<div className={`${ReportSettingsStyle}__form`}>
					<TextInput
						qaid="ReportSettings.Input.Name"
						label="Report name"
						name="expenseCompanyReportName"
						value={report?.expenseCompanyReportName}
						onChange={onInputHandler}
						required
						ref={(el: InputValidationRef) => (inputRefs.expenseCompanyReportName = el)}
						error={formValidationState?.expenseCompanyReportName?.message}
						isViewMode={isViewOnly}
					/>
					<Select
						qaid="ReportSettings.Select.Period"
						label="Report period"
						name="period"
						value={report.period}
						onChange={(period, name) => {
							if (!isNumber(period)) return;
							// const basedOnReport = expenseReports.find(
							//     (r) => r.expenseCompanyReportId === report.previousExpenseCompanyReportId
							// );
							if (!basedOnReport) {
								setReport((prevReport) => ({
									...prevReport,
									period,
									reportStartDate: getStartDateByPeriod(period, prevReport.reportEndDate),
								}));
								return;
							}
							const reportStartDate = addDaysToDate(basedOnReport.reportDateTo, 1);
							setReport((prevReport) => ({
								...prevReport,
								period,
								reportStartDate,
								reportEndDate: getEndDateByPeriod(period, reportStartDate),
							}));
						}}
						options={periodOptions}
						required
						ref={(el: InputValidationRef) => (inputRefs.period = el)}
						error={formValidationState?.period?.message}
						isViewMode={isViewOnly}
					/>
					<DatePickerInput
						qaid="ReportSettings.Input.EndDate"
						label="Report end date"
						name="reportEndDate"
						value={report?.reportEndDate}
						onChange={(reportEndDate) => {
							setReport((prevReport) => ({
								...prevReport,
								reportEndDate,
								reportStartDate:
									prevReport.period === GrantReportPeriodEnum.Custom
										? prevReport.reportStartDate
										: getStartDateByPeriod(prevReport.period, reportEndDate),
							}));
						}}
						required
						ref={(el: InputValidationRef) => (inputRefs.reportEndDate = el)}
						error={formValidationState?.reportEndDate?.message}
						isViewMode={isViewOnly}
						disabled={
							isNullOrUndefined(report.period) || (!!report.previousExpenseCompanyReportId && report.period !== GrantReportPeriodEnum.Custom)
						}
						info={
							!!report.previousExpenseCompanyReportId && report.period !== GrantReportPeriodEnum.Custom
								? `The report end date is calculated based on report - ${basedOnReport?.expenseCompanyReportName}`
								: undefined
						}
						minDate={report?.reportStartDate}
					/>
					<Collapse in={report.period === GrantReportPeriodEnum.Custom} unmountOnExit>
						<DatePickerInput
							qaid="ReportSettings.Input.StartDate"
							label="Report start date"
							name="reportStartDate"
							value={report?.reportStartDate}
							onChange={onInputHandler}
							required
							ref={(el: InputValidationRef) => (inputRefs.reportStartDate = el)}
							error={formValidationState?.reportStartDate?.message}
							isViewMode={isViewOnly}
							disabled={isNullOrUndefined(report.period) || !!report.previousExpenseCompanyReportId}
							info={
								!!report.previousExpenseCompanyReportId
									? `The report start date is calculated based on report - ${basedOnReport?.expenseCompanyReportName}`
									: undefined
							}
						/>
					</Collapse>
				</div>
			</div>
			<div className={`${ReportSettingsStyle}__section`}>
				<Separator />
				<div className={`${ReportSettingsStyle}__form`} style={{ height: '3.6rem' }}>
					<RadioButton
						qaid="ReportSettings.Radio.NewReport"
						label="New report"
						name="previousExpenseCompanyReportId"
						value={null}
						onChange={(previousExpenseCompanyReportId) =>
							setReport((prevReport) => ({
								...prevReport,
								previousExpenseCompanyReportId,
								reportEndDate: report.reportEndDate,
								reportStartDate: report.reportStartDate,
							}))
						}
						checked={report.previousExpenseCompanyReportId === null}
						disabled={isViewOnly}
					/>
					<RadioButton
						qaid="ReportSettings.Radio.ExistingReport"
						label="Based on report"
						name="previousExpenseCompanyReportId"
						value={0}
						onChange={onInputHandler}
						checked={isNumber(report.previousExpenseCompanyReportId)}
						disabled={isViewOnly}
					/>
					{isNumber(report.previousExpenseCompanyReportId) && (
						<Select
							qaid="ReportSettings.Select.BasedReport"
							defaultValue="Select a report..."
							value={report.previousExpenseCompanyReportId}
							onChange={(value, name) => {
								if (!isNumber(value)) return;

								const basedOnReport = expenseReports.find((r) => r.expenseCompanyReportId === value);
								if (!basedOnReport) return;
								const reportStartDate = addDaysToDate(basedOnReport.reportDateTo, 1);
								setReport((prevReport) => ({
									...prevReport,
									previousExpenseCompanyReportId: value,
									reportStartDate,
									reportEndDate: getEndDateByPeriod(report.period, reportStartDate),
								}));
							}}
							options={expenseReports
								.filter((report) => report.status === ExpenseReportStatus.Publish)
								.map((report) => ({
									label: report.expenseCompanyReportName,
									value: report.expenseCompanyReportId,
								}))}
							required
							ref={(el: InputValidationRef) => (inputRefs.previousExpenseCompanyReportId = el)}
							error={formValidationState?.previousExpenseCompanyReportId?.message}
							isViewMode={isViewOnly}
						/>
					)}
				</div>
				<Separator />
				<div className={`${ReportSettingsStyle}__form`}>
					{!isEdit && (
						<>
							<NumberInput
								qaid="ReportSettings.Input.ForfeitureRate"
								label="Forfeiture rate"
								placeholder="%"
								percentage
								name="forfeitureRate"
								value={settings.forfeitureRate || undefined}
								onChange={onSettingsInputHandler}
								// required={settings.forfeitureRate !== null}
								disabled={settings.forfeitureRate === null}
								ref={(el: InputValidationRef) => (settingsInputRefs.forfeitureRate = el)}
								error={settingsValidationState?.forfeitureRate?.message}
								isViewMode={isViewOnly}
							/>
							<div style={{ alignSelf: 'flex-end', height: '3.8rem' }}>
								<CheckBox
									qaid="ReportSettings.CheckBox.PerGrant"
									label="Customize per grant"
									onClick={(checked) => setSettings((prevState) => ({ ...prevState, forfeitureRate: checked ? null : undefined }))}
									isChecked={settings.forfeitureRate === null}
									disabled={isViewOnly}
								/>
							</div>
							<div></div>
						</>
					)}
					<NumberInput
						qaid="ReportSettings.Input.AssetValue"
						label="Asset value for disclosure report"
						placeholder={companyCurrency?.symbol}
						name="assetPrice"
						value={report?.assetPrice}
						onChange={onInputHandler}
						required
						ref={(el: InputValidationRef) => (inputRefs.assetPrice = el)}
						error={formValidationState?.assetPrice?.message}
						isViewMode={isViewOnly}
						prependText={companyCurrency?.symbol}
						number="float"
					/>
				</div>
			</div>
		</div>
	);
});

export default observer(Report);
