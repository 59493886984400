import { EditablePariPassu } from "../../../../../../../Models/API/Waterfall/IForm";
import CheckBox from "../../../../../../../Shared/Components/CheckBox/CheckBox";
import { css } from "@emotion/css";
import appConfig from "../../../../../../../config/config";
import classNames from "classnames";

interface Props {
	pariPassu: EditablePariPassu[];
	isRoot: boolean;
	isCreate?: boolean;
	onChange: (pariPassuId: number, isSelected: boolean) => void;
}

const style = css({
	label: "PariPassuContainer",
	// display: "flex",
	flexDirection: "column",
	maxWidth: "calc(100% - 30.2rem)",
	// borderRight: `1px solid ${appConfig.style.colors.table}`,
	paddingTop: 0,
	fontFamily: appConfig.style.fonts.assistant,
	marginLeft: "18.3rem",
	"&__top-content": {
		// marginBottom: 10,
	},
	"&__label": {
		fontWeight: "bold",
		fontSize: "1.4rem",
		marginBottom: "0.4rem"
	},
	"&__note": {
		color: appConfig.style.colors.color1,
		fontSize: "1.2rem",
	},
	"&__pari-passu-list": {
		display: "flex",
		gap: "1.5rem",
	},
	"&__inline-label": {
		display: "flex",
		alignSelf: "center",
	},
	"&.padding": {
		padding: "1.2rem",
	},
});

const PariPassuSelection = ({ pariPassu, isCreate, onChange, isRoot }: Props) => {
	const Note = () => {
		return (
			<div className={`${style}__note`} style={{ marginBlock: 5 }}>
				<span className="bold">Note</span>:{" "}
				{pariPassu.length
					? "To remove this class from the Pari Passu group, edit the properties of the most senior class in the group."
					: "Share class cannot initiate Pari Passu group. There are no lower-seniority classes with liquidation preferences available below it to form a group."}
			</div>
		);
	};

	return (
		<div className={classNames(style, { padding: !isCreate })}>
			{isCreate && pariPassu.length > 0 && (
				<div className={`${style}__top-content`}>
					<div className={`${style}__label`}>Select Classes</div>
				</div>
			)}
			<div className={`${style}__pari-passu-list`}>
				{!isCreate && <div className={`${style}__inline-label`}>Linked class(es) of Pari Passu:</div>}
				{pariPassu.map((p) => (
					<CheckBox
						qaid={`ShareClassRights.CheckBox.PariPassu`}
						key={p.shareClassId}
						isChecked={p.isSelected}
						disabled={!p.isEditable}
						label={p.shareClassName}
						onClick={(value) => onChange(p.shareClassId, value as boolean)}
						disableBoxSpacing
					/>
				))}
			</div>
			{!isCreate && !isRoot && <Note />}
		</div>
	);
};

export default PariPassuSelection;
