import { css } from "@emotion/css";
import { theme } from "../../../../../../config/style/theme";

export const FORM_ROW_MAX_HEIGHT = "21.5rem";
const FORM_FIELD_DESCRIPTION_WIDTH = "28.7rem";

const StyledScenarios = css({
    label: "Scenarios",

    ".scenario-description": {
        // marginLeft: "6rem",
        "& textarea": {
            width: "100%",
            [`@media (min-width: ${theme.breakpoints.md})`]: {
                minWidth: FORM_FIELD_DESCRIPTION_WIDTH,
            },
        },
    },

    [`@media (min-width: ${theme.breakpoints.sm})`]: {
        display: "grid",
        gridTemplateAreas:
            '"scenario-name scenario-name scenario-name scenario-description" "scenario-valuation-date scenario-time-to-exit scenario-effective-time-to-exit scenario-description" "scenario-exit-amount scenario-exit-amount scenario-exit-amount scenario-description"',
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "space-between",
        columnGap: theme.spacings.xl,
        rowGap: theme.spacings.md,

        ".scenario-name": {
            gridArea: "scenario-name",
        },

        ".scenario-valuation-date": {
            gridArea: "scenario-valuation-date",
        },

        ".scenario-time-to-exit": {
            gridArea: "scenario-time-to-exit",
        },

        ".scenario-effective-time-to-exit": {
            gridArea: "scenario-effective-time-to-exit",
            input: {
                cursor: "not-allowed",
            },
        },

        ".scenario-exit-amount": {
            gridArea: "scenario-exit-amount",
        },

        ".scenario-description": {
            gridArea: "scenario-description",
            display: "flex",
            flexDirection: "column",

            "& textarea": {
                maxHeight: FORM_ROW_MAX_HEIGHT,
            },
        },
    },
});

export default StyledScenarios;
