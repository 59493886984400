import { observer } from "mobx-react-lite";
import React, { useEffect, useImperativeHandle, useState } from "react";
import Spinner from "../../../../../../Shared/Components/Spinner/Spinner";
import useGeneralModal from "../../../../../../Shared/Hooks/useGeneralModal";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { WaterfallStep } from "../../AddEditWaterfall.Style";
import SelectImportSource from "./InnerSteps/SelectImportSource";
import WaterfallTable from "./InnerSteps/WaterfallTable";

interface CapTableProps {}

const CapTable = React.forwardRef<any, CapTableProps>((props, forwardedRef) => {
    const { waterfallStore, companyStore } = useRootStore();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { showErrorModal } = useGeneralModal();

    useImperativeHandle(forwardedRef, () => ({
        async onValidate() {
            return Boolean(waterfallStore.singleCapTableData);
        },
    }));

    useEffect(() => {
        const fetchData = async () => {
            const { companyId } = companyStore.company;
            if (!companyId || companyStore.isCreatingCompanyFromCompany) return;

            setIsLoading(true);
            const res = await waterfallStore.getCapTableBases(companyId);
            setIsLoading(false);

            if (res.error) {
                const errorMsg = "Failed to fetch Cap Table Bases";
                setErrorMessage(errorMsg);
                showErrorModal(errorMessage, "Error");
            }
        };

        fetchData();
    }, [companyStore.company.companyId]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const res = await waterfallStore.getSavedCapTableBase(companyStore.companyId, waterfallStore.waterfallId);
            if (!res.isSuccess) {
                const errorMsg = "Failed to fetch Saved Cap Table Base";
                setErrorMessage(errorMsg);
                showErrorModal(errorMessage, "Error");
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    return (
        <WaterfallStep ref={forwardedRef}>
            {isLoading ? (
                <Spinner
                    incorporated
                    center
                />
            ) : waterfallStore.showSelectImportSource ? (
                <SelectImportSource />
            ) : (
                <WaterfallTable />
            )}
        </WaterfallStep>
    );
});

export default observer(CapTable);
