import { ElectronicSignatureStatus } from "../../API/Document/eletronic-signature-status-enum";
import { TemplateTypeEnum } from "../../API/Document/template-type-enum";
import { TypeDto } from "./General";

export interface IGrant {
    grantId: number;
    beneficiaryId: number;
    employeeNumber: string;
    grantType: number;
    grantDate: Date;
    stockPrice: number;
    experationDate: Date;
    upcomingVestingDate: Date;
    upcomingVesting: number;
    granted: number;
    vested: number;
    grantTemplateId: number;
    isSigned: true;
    grantNumber: string;
    planId: number;
    planName: string;
    exercisePrice: number;
    exercisePriceCurrency: number;
    vestStartDate: Date;
    vestSchedule: string;
    taxTrack: number;
    site: number;
    department: number;
    level: string;
    unvested: number;
    exercised: number;
    sellable: number;
    exercisable: number;
    sold: number;
    canceled: number;
    outstanding: number;
    vestingId: number;
    originalExpirationDate: Date;
    currency: number;
    documentId: number;
    fullName: {
        firstName: string;
        lastName: string;
    };
    companyName: string;
    isLegalEntity: boolean;
    retentionDate: Date;
    isSignedLetterForEdit: boolean;
    siteDto: TypeDto;
    departmentDto: TypeDto;
    documentDetailsDto: GrantDocumentDetails[] | null;
}

export interface IBaseGrant {
    companyId: number;
    planId: number;
    contactId: number;

    grantNumber: string;
    awardType: GrantTypes;
    taxTrack: TaxTracks;
    grantDate: Date | string;
    granted: number;
    vestStartDate: Date | string;
    vestingId: number;
    currency?: number;
}

export interface IGrantRSU extends IBaseGrant { }

export interface IGrantESOP extends IBaseGrant {
    exercisePrice: number;
    expirationDate: Date | string;
}

export interface IGrantAGA extends IBaseGrant {
    retantionDate: Date | string;
}
export interface BenGrant {
    companyId: number;
    planId: number;
    contactId: number;

    grantNumber: string;
    awardType: GrantTypes;
    taxTrack: TaxTracks;
    grantDate: Date | string;
    granted: number;
    exersicePrice: number;
    vestStartDate: Date | string;
    vestingId: number;
    expirationDate?: Date | string;
    boardDepositDate?: Date | string;
    currency?: number;
    retentionDate?: Date | string;

    site?: {
        key: number;
        value: string;
        companyId: number;
    };
    department?: string;
    level?: string;
}

export interface GrantTable {
    grantId: number;
    planId: number;
    beneficiaryId: number;
    grantTemplateId: number;

    fullName: {
        firstName: string;
        lastName: string;
    };
    employeeNumber: string;

    planName: string;
    grantType: number;
    grantDate: Date;
    experationDate: Date;
    granted: number;
    vested: number;
    isSigned: boolean;
    isSignedLetterForEdit: boolean;
    grantNumber: string;
    exercisePrice: number;
    vestStartDate: Date;
    vestSchedule: string;
    taxTrack: number;
    unvested: number;
    exercised: number;
    sellable: number;
    exercisable: number;
    sold: number;
    canceled: number;
    outstanding: number;
    originalExpirationDate: Date;
    exercisePriceCurrency: number;
    retentionDate: Date;
    documentDetailsDto: GrantDocumentDetails[] | null;
    sourceContactId: number;

    // stockPrice: number;
    // upcomingVestingDate: Date | string;
    // upcomingVesting: number;
    // site: string;
    // department: string;
    // level: string;
    // vestingId: number;
    // documentId: number;
    // companyName: string;
    // isLegalEntity: true;
    // isSignedLetterForEdit: true;
    // siteDto: {
    //   key: number;
    //   value: string;
    //   companyId: number
    // };
    // departmentDto: {
    //   departmentType: number;
    //   key: number;
    //   value: string;
    //   companyId: number
    // };
    // subDepartmentDto: {
    //   departmentType: number;
    //   key: number;
    //   value: string;
    //   companyId: number;
    // };
}

export type GrantDocumentDetails = {
    documentId: string;
    documentName: string;
    electronicSignatureStatus: ElectronicSignatureStatus;
    isCancelled: boolean;
    isDeleted: boolean;
    recordId: number;
    type: TemplateTypeEnum;
    templateId: string;
};

export interface IOrder {
    orderId: number;
    orderNumber: string;
    grantId: number;
    contactId: number;
    orderType: number;
    date: Date | string;
    grantNumber: string;
    amount: number;
    currency: number;
    isSecondary: boolean;

    buyerFirstName?: string;
    buyerLastName?: string;
    buyerEmail?: string;
    sellPrice?: number;
    exercisePrice?: number;
    note?: string;
}

export interface IGrantCancelation {
    contactId: number;
    cancelationDate: Date | string;
    grantId: number;
    grantNumber: number;
    grantCancelationId: number;
    amountToCancel: number;
    cancelationReason: number;
    gracePeroidType: number;
    gracePeroidNum: number;
    cancelType: number;
}

//        *********      Enums       **********
//             *****                ******
//                         *

export enum GrantTypes {
    Options,
    RSU,
    BSPCE,
    AGA,
}

export enum TaxTracks {
    None,
    ISO,
    NSO,
    CapitalGainTranch_102,
    OrdinaryIncomeTranch_102,
    CSOP,
    EMI,
    Other,
    AGA,
    BSPCE,
    T3
}

export enum CancelType {
    Vested,
    Unvested,
    VestedAndUnvested,
    VestedByAmount,
}

export enum CancelDateType {
    Days,
    Months,
    Years,
}

export enum CancelationReason {
    Default,
    Termination,
    Resignation,
    Death,
    Retirment,
    EndOfGPT,
    GrantExpiration,
    DueToExercise,
    Waiver,
}
