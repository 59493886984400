import { toJS } from "mobx";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AwardType } from "../../../../../Models/API/enums";
import { GrantTypes, IGrant } from "../../../../../Models/App/EquityPlans/Grants";
import { InputRefs, InputValidationRef, useFormValidation } from "../../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { commonValidators } from "../../../../../Shared/ObjectValidator";
import { NumberOption } from "../../../../../Models/API/All/NumberOption";
import { isNullOrUndefined } from "../../../../../Shared/Utilities";

interface ApiError {
	GrantId: string;
	GrantNumber: string;
	Granted: string;
	GrantDate: string;
	VestStartDate: string;
	VestingId: string;
	originalExpirationDate: string;
	RetentionDate: string;
}

type ApiErrorState = { [key in keyof ApiError]: string };

const useGrant = (key: "newGrant" | "beneficiaryGrantData", benReq?: boolean) => {
	const { equityPlansStore, currenciesOptions } = useRootStore();
	const [apiErrors, setApiErrors] = useState<ApiErrorState>({} as ApiErrorState);

	const grant: Partial<IGrant> = equityPlansStore[key];
	const [isGrantNumberExists, setIsGrantNumberExists] = useState<boolean>(false);
	const [grantTypes, setGrantTypes] = useState<NumberOption[]>([]);
	const inputRefs = useRef<InputRefs<Partial<IGrant>>>({} as InputRefs<Partial<IGrant>>);
	const grantNumberRef = useRef<string>();
	const [grantMinDate, setGrantMinDate] = useState<Date>()
	const { t } = useTranslation();

	const onInputHandler = (value: string | number, name: string) => {
		equityPlansStore[key] = {
			...equityPlansStore[key],
			[name]: value,
		};
	};

	useEffect(() => {
		setAvialableGrantTypes();
	}, [grant.planId]);

	const setAvialableGrantTypes = () => {
		const options = equityPlansStore.companyData?.plans?.filter((pln) => pln.planId === grant.planId);
		let newOptions: NumberOption[] = [];
		options?.forEach((opt) => {
			if (opt.allowRsu) newOptions.push({ label: "RSU", value: GrantTypes.RSU });
			if (opt.allowSop) newOptions.push({ label: "Options", value: GrantTypes.Options });
		});
		setGrantTypes(newOptions);
	};

	const { validateForm, formValidationState, clearErrors } = useFormValidation({
		form: grant,
		schema: {
			beneficiaryId: [commonValidators.requiredIf(() => !!benReq)],
			planId: [commonValidators.required()],
			grantType: [commonValidators.required()],
			grantDate: [commonValidators.required(), commonValidators.customValidation(!apiErrors?.GrantDate, apiErrors.GrantDate)],
			grantNumber: [
				commonValidators.required(),
				commonValidators.customValidation(!isGrantNumberExists, "Grant number already exists"),
				commonValidators.customValidation(!apiErrors?.GrantNumber, apiErrors.GrantNumber),
			],
			granted: [
				commonValidators.required(),
				commonValidators.range(1, 9999999999, t("validations.pleaseEnterAValidNumber")),
				commonValidators.customValidation(!apiErrors?.Granted, apiErrors.Granted),
			],
			vestStartDate: [commonValidators.required(), commonValidators.customValidation(!apiErrors?.VestStartDate, apiErrors.VestStartDate)],
			originalExpirationDate: [
				commonValidators.requiredIf(() => grant.grantType === AwardType.Options),
				commonValidators.customValidation(!apiErrors?.originalExpirationDate, apiErrors.originalExpirationDate),
			],
			exercisePrice: [
				commonValidators.requiredIf(() => grant.grantType === AwardType.Options || grant.grantType === AwardType.BSPCE),
				commonValidators.maxLength(10, t("validations.pleaseEnterAValidNumber")),
				commonValidators.range(0, 9999999999, t("validations.pleaseEnterAValidNumber")),
			],
			// currency: [commonValidators.requiredIf(() => grant.grantType === AwardType.Options)],
			exercisePriceCurrency: [commonValidators.requiredIf(() => grant.grantType === AwardType.Options)],
			vestingId: [commonValidators.required(), commonValidators.customValidation(!apiErrors?.VestingId, apiErrors.VestingId)],
			taxTrack: [commonValidators.required()],
		},
		refs: inputRefs.current,
		deps: [isGrantNumberExists, apiErrors],
		isEdit: key === "beneficiaryGrantData",
	});

	const onValidateGrantNumber = async (e: any) => {
		if (grantNumberRef.current === grant.grantNumber) return;

		const res = await equityPlansStore.CheckIfGrantNumberExists();
		setIsGrantNumberExists(res.data);
		grantNumberRef.current = undefined;
	};

	const plans = useMemo(
		() =>
			equityPlansStore.companyData?.plans?.map((pln) => {
				return { label: pln.name, value: pln.planId };
			}) || [],
		[equityPlansStore.companyData]
	);

	const vests = useMemo(
		() =>
			equityPlansStore.companyData?.vestings?.map((vst) => {
				return { label: vst.vestingName, value: vst.vestingId };
			}) || [],
		[equityPlansStore.companyData]
	);

	const planChangeHandler = (selectedPlanId?: number) => {
		if (!selectedPlanId && !grant.planId) return
		if (!selectedPlanId) selectedPlanId = grant.planId
		const plan = equityPlansStore.companyData?.plans.filter((pln) => pln.planId === selectedPlanId)[0];
		if (isNullOrUndefined(plan?.planApprovalDateByBoard)) return;
		const minDate = plan?.planApprovalDateByBoard;
		setGrantMinDate(minDate);
		console.log("minDate", minDate);
	};

	useEffect(() => {
		planChangeHandler()
	}, [grant.planId])

	return {
		plans,
		vests,
		validateForm,
		formValidationState,
		currenciesOptions,
		grantNumberRef,
		onInputHandler,
		inputRefs,
		onValidateGrantNumber,
		grantTypes,
		grant,
		setApiErrors,
		clearErrors,
		grantMinDate
	};
};

export default useGrant;
