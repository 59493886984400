import { DetailedHTMLProps, ImgHTMLAttributes, ReactElement, ReactNode, useEffect, useState } from "react";
import { isNullOrUndefined } from "../../Utilities";
import classNames from "classnames";
import { IconButton, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
// import Tooltip from "../Tooltip/Tooltip";

export interface ImageProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    srcHover?: string;
    altHover?: string;
    extension?: string;
    pointer?: boolean;
    tooltip?: ReactNode;
}

const ImageStyle = styled.img({}, ({ width }: { width?: number | string }) => (width ? { width } : {}));

const Image = ({ src, alt, srcHover, altHover = alt, extension = "svg", pointer, tooltip, width, ...props }: ImageProps) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const imageEl = (
        <ImageStyle
            {...props}
            src={isHovering ? srcHover : src}
            alt={isHovering ? altHover : alt || ""}
            onMouseEnter={() => srcHover && setIsHovering(true)}
            onMouseLeave={() => srcHover && setIsHovering(false)}
            className={classNames(props.className, { pointer })}
            width={width}
        />
    );

    if (tooltip) {
        return (
            <Tooltip
                title={tooltip}
                enterTouchDelay={0}
            >
                {imageEl}
            </Tooltip>
        );
    }

    return imageEl;
};

export default Image;
