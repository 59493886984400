import { css } from '@emotion/css';
import Title from './Layout/Title';
import { ModalBodyProps } from './Modal/types';
import Select from './Select/Select';
import Button from './Button/Button';
import appConfig from '../../config/config';
import useRootStore from '../Hooks/useRootStore';
import { useEffect, useRef, useState } from 'react';
import { AddOnPlan, AddOnPlanReponse, AddOnsEnum } from '../../Models/App/PaymentModels';
import { NumberOption } from '../../Models/API/All/NumberOption';
import { isNullOrUndefined, isNumber } from '../Utilities';
import useModal from '../Hooks/useModal';
import { toJS } from 'mobx';
import { PaymentPlanEnum } from '../../Models/API/enums';
import useRecaptcha from '../Hooks/useRecaptcha';
import { observer } from 'mobx-react-lite';

const Style = css({
	label: 'AddStakeholders',
	display: 'flex',
	flexDirection: 'column',
	'&__actions': {
		display: 'flex',
		justifyContent: 'space-between',
		gap: '8rem',
		marginTop: '2rem',
	},
	'.note': {
		color: appConfig.style.colors.text4,
	},
});

interface Props extends ModalBodyProps {}

const AddStakeholders = ({ removeModal, onConfirmHandler }: Props) => {
	const { showModal } = useModal();
	const { paymentStore } = useRootStore();
	const { execute } = useRecaptcha();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [options, setOptions] = useState<NumberOption[]>();
	const [selectedQuantity, setSelectedQuantity] = useState<number>();
	const addOnRef = useRef<AddOnPlanReponse>();

	useEffect(() => {
		let type: AddOnsEnum | undefined;

		(async () => {
			const planRes = await paymentStore.getCurrentPlan();
			const plan = planRes.data;
			switch (plan?.code) {
				case PaymentPlanEnum.Growth:
					type = AddOnsEnum.GrowthStakeholders;
					break;
				case PaymentPlanEnum.Scale:
					type = AddOnsEnum.ScaleStakeholders;
					break;
				case PaymentPlanEnum.Enterprise:
					type = AddOnsEnum.EnterpriseStakeholders;
					break;
				default:
					break;
			}
			if (type === undefined) return;

			const res = await paymentStore.getAddOnsPlans(type);
			if (!res.data?.data) return;

			addOnRef.current = res.data.data;
			const opts: NumberOption[] = Array.from({ length: res.data.data.quantity }).map((_, idx) => ({
				label: (
					<div>
						<span className="bold">{res.data.data.base * (idx + 1)} stakeholders</span> - ${res.data.data.price * (idx + 1)}/month
					</div>
				),
				value: idx + 1,
			}));
			setOptions(opts);
		})();
	}, []);

	return (
		<div className={Style}>
			<Title type="secondary" className="mb-2">
				Add stakeholders
			</Title>
			<span>Please select number of stakeholders from dropdown menu.</span>
			<span className="note">
				Note: You will not be charged now. The annual payment will be calculated & proceed automatically on the next payment due date
			</span>
			<div className={`${Style}__actions`}>
				<Select
					qaid="AddStakeholders.Select.Amount"
					options={options}
					isLoading={!options}
					value={selectedQuantity}
					onChange={(val) => isNumber(val) && setSelectedQuantity(val)}
				/>
				<Button
					qaid="AddStakeholders.Button.Proceed"
					label="Proceed"
					disabled={isNullOrUndefined(selectedQuantity)}
					isLoading={isLoading}
					onClick={async () => {
						if (isLoading || isNullOrUndefined(selectedQuantity)) return;

						setIsLoading(true);

						execute(async (token) => {
							if (isNullOrUndefined(addOnRef.current)) return setIsLoading(false);
							const res = await paymentStore.purchaseAddon(addOnRef.current.addonId, selectedQuantity, token);
							setIsLoading(false);
							if (!res.isSuccess) return;

							removeModal?.();
							showModal({
								type: 'success',
								body: () => (
									<div className="mt-4">
										<div className="bold">Your current plan is extended successfully.</div>
										<div>The annual payment will be calculated & proceed automatically on the next payment due date.</div>
									</div>
								),
								qaid: 'AddStafkeholders.Modal.Success',
								width: '60.5rem',
								showProgressBar: false,
								timeout: 1500,
							});
						});
					}}
				/>
			</div>
		</div>
	);
};
export default observer(AddStakeholders);
