import { useEffect, useState } from "react";
import CircularSwitchToggle from "../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle";
import DoughnutChart from "../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import Widget from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/Widget";
import { WidgetBody } from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/Widget.style";
import WidgetTitle from "../../../Shared/Components/WidgetDashboard/shared/components/Widget/WidgetTitle/WidgetTitle";
import WidgetDashboard from "../../../Shared/Components/WidgetDashboard/shared/components/WidgetDashboard/WidgetDashboard";
import GrantsTable from "./GrantsTable/GrantsTable";
import BeneficiariesTable from "./BeneficiariesTable/BeneficiariesTable";

import { equityDashboardStyle } from "./EquityDashboard.style";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import Flex from "../../../Shared/Components/Layout/Flex";
import { IDashboardData } from "../../../Models/App/EquityPlans/General";
import ChartLegend from "../../../Shared/Components/WidgetDashboard/shared/components/ChartLegend/ChartLegend";
import { IC_BENEFICIARIES, IC_EXERCISED, IC_GRANTS, IC_USER } from "../../../Assets";
import { formatDecimal, formatNumber, getSum } from "../../../Shared/Utilities";
import WithToggle from "../../../Shared/Components/WidgetDashboard/shared/components/WithToggle/WithToggle";
import NoData from "../../../Shared/Components/WidgetDashboard/CaptableDashboard/dependencies/components/SwitchSelectChartWidget/NoData/NoData";
import classNames from "classnames";
import DashboardText from "../../../Shared/Components/Dashboard/DashboardText";
import DashboardCard from "../../../Shared/Components/Dashboard/DashboardCard";

const EquityDashboard = () => {
	const { equityPlansStore } = useRootStore();
	// const [data, setData] = useState<IDashboardData>();

	const [renderPie, setRenderPie] = useState<boolean>();
	const [isDashboardOpen, setIsDashboardOpen] = useState<boolean>(true);

	const data = equityPlansStore.equityDashboard;

	const [byBen, setByBen] = useState(true);

	const labels = ["Outstanding", "Exercised", "Sold", "Canceled", "Unallocated"];
	const total = data.granted + data.unAllocated;
	// const total = getSum(data?.outstanding, data?.exercised, data?.sold, data?.canceled);
	const figures = data
		? [
			formatDecimal((data.outstanding / total) * 100),
			formatDecimal((data.exercised / total) * 100),
			formatDecimal((data.sold / total) * 100),
			formatDecimal((data.canceled / total) * 100),
			formatDecimal((data.unAllocated / total) * 100),
		]
		: [];

	const tableHeader = (
		<CircularSwitchToggle
			value={!byBen}
			actions={[
				{ value: true, label: "Grants" },
				{ value: false, label: "Beneficiaries" },
			]}
			onChange={(value) => setByBen(value)}
			type="secondary"
			size="md"
		/>
	);

	const renderPieChecker = () => {
		const { outstanding, exercised, sold, canceled } = data;

		if (outstanding > 0 || exercised > 0 || sold > 0 || canceled > 0) {
			setRenderPie(true);
		} else {
			setRenderPie(false);
		}
	};

	useEffect(() => {
		renderPieChecker();
	}, [data]);

	useEffect(() => {
		equityPlansStore.GetDashboardData();
	}, []);

	return (
		<>
			<div className={equityDashboardStyle}>
				{/* <ActionsMenu /> */}

				{!!data && (
					<WithToggle onToggle={(val) => setIsDashboardOpen(!val)}>
						<div className={`${equityDashboardStyle}__dashboard`}>
							<DashboardCard className="card1">
								{renderPie ? (
									<>
										<DoughnutChart
											ap={{
												figures,
												labels,
											}}
										/>
										<ChartLegend labels={labels} figures={figures} />
									</>
								) : (
									<NoData content="Data will be presented once grants allocation is started" />
								)}
							</DashboardCard>
							<DashboardCard className="card2" img={IC_GRANTS}>
								<DashboardText title="Granted" text={formatNumber(data.granted)} />
								<DashboardText title="Outstanding" text={formatNumber(data.outstanding)} />
								<DashboardText title="Exercised" text={formatNumber(data.exercised)} />
								<DashboardText title="Sold" text={formatNumber(data.sold)} />
								<DashboardText title="Canceled" text={formatNumber(data.canceled)} />
								<DashboardText title="Unallocated" text={formatNumber(data.unAllocated)} />
							</DashboardCard>
							<DashboardCard className="card3" img={IC_EXERCISED}>
								<DashboardText title="Exercisable" text={formatNumber(data.exercisable)} />
								<DashboardText title="Saleable" text={formatNumber(data.saleableShares)} />
							</DashboardCard>
							<DashboardCard className="card4" img={IC_BENEFICIARIES}>
								<DashboardText title="Active beneficiaries" text={formatNumber(data.activeBeneficiaries)} />
								<DashboardText title="Inactive beneficiaries" text={formatNumber(data.inactiveBeneficiaries)} />
							</DashboardCard>
						</div>
					</WithToggle>
				)}

				<div className={`${equityDashboardStyle}__table-container`}>
					{
						!byBen ?
							<BeneficiariesTable header={tableHeader} expand={!isDashboardOpen} />
							: <GrantsTable header={tableHeader} expand={!isDashboardOpen} />
					}
				</div>
			</div>
		</>
	);
};
export default observer(EquityDashboard);
