import { css } from "@emotion/css";
import appConfig from "../../config/config";

export const WATERFALL_WRAPPER_WIDTH = "120rem";

const ActionsStyle = css({
	label: "actions",
	display: "flex",
	padding: "0 !important",
	textAlign: "center",
	alignItems: "center",
	"> div": {
		flex: 1,
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		"&.open, &.summary": {
			borderRight: `1px solid ${appConfig.style.colors.color1Secondary}`,
		},
		"&.summary": {
			flex: 1,
			color: appConfig.style.colors.color1,
			textDecoration: "underline",
			".clickable": {
				cursor: "pointer",
			},
		},
		"&.open, &.delete": {
			flexBasis: 50,
			maxWidth: 50,
			img: {
				transform: "scale(0.5)",
				maxWidth: "100%",
				width: "auto",
				height: "100%",
				objectFit: "contain",
			},
		},
		"> *": {
			cursor: "pointer",
		},
	},
});


export const WaterfallIndexStyle = css({
	label: "WaterfallIndexStyle",
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(0deg,#bcc2f1 -23.09%,#f0f1ff 61.7%,hsla(0,0%,100%,0) 118.58%)",
    height: "100%",
    justifyContent: "center",
	"&__container":  {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		maxWidth: "68rem",
		marginBottom: "10rem",
		".analysis-img": {
			width: "10.5rem",
		},
		".analysis-text": {
			textAlign: "center",
			margin: "4.4rem 0",
			lineHeight: "35px",
			fontSize: "2.5rem",
			fontWeight: 700,
		},
		".btn-create-analysis": {
			fontSize: "1.6rem",
			fontWeight: 500,
			width: "23.3rem",
			height: "5rem",
			letterSpacing: "0.32px",
		}
	}		
})

export default ActionsStyle;
