import { NumberOption } from "../All/NumberOption";

export enum TemplateTypeEnum {
    GrantLetter,
    ShareCertificate,
    NoticeOfExercise,
    Other,
}

export const templateTypeOptions: NumberOption[] = [
    {
        label: "Grant Letter",
        value: TemplateTypeEnum.GrantLetter,
    },
    {
        label: "Share Certificate",
        value: TemplateTypeEnum.ShareCertificate,
    },
    {
        label: "Notice of Exercise",
        value: TemplateTypeEnum.NoticeOfExercise,
    },
    {
        label: "Other",
        value: TemplateTypeEnum.Other,
    },
];
