import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IC_EYE_ACTION } from "../../../../../../Assets";
import { TemplateTypeEnum } from "../../../../../../Models/API/Document/template-type-enum";
import { GrantTypes } from "../../../../../../Models/App/EquityPlans/Grants";
import AddButton from "../../../../../../Shared/Components/Button/AddButton";
import Button from "../../../../../../Shared/Components/Button/Button";
import CheckBox from "../../../../../../Shared/Components/CheckBox/CheckBox";
import Clickable from "../../../../../../Shared/Components/Clickable/Clickable";
import Image from "../../../../../../Shared/Components/Image";
import DatePickerInput from "../../../../../../Shared/Components/Input/DatePickerInput";
import NumberInput from "../../../../../../Shared/Components/Input/NumberInput";
import TextInput from "../../../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import Select from "../../../../../../Shared/Components/Select/Select";
import Spinner from "../../../../../../Shared/Components/Spinner/Spinner";
import { InputValidationRef } from "../../../../../../Shared/Hooks/useFormValidation";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { addDaysToDate, isNullOrUndefined, isNumber, isString } from "../../../../../../Shared/Utilities";
import useTemplate from "../../../../../Documents/helpers/hooks/useTemplate";
import useGrant from "../useGrant";
import NewGrantDetailsStyle from "./NewGrantDetails.style";

// import { commonValidators } from "../../../../../../Shared/ObjectValidator";
// import useModal from "../../../../../../Shared/Hooks/useModal";
// import CCDInput from "../../../../General/CCDInput/CCDInput";
// import { EntityTrackHistoryType } from "../../../../../../Models/App/EquityPlans/General";

interface NewGrantProps {
    onSave: (templateId?: string) => Promise<void>;
    onAddBen?: () => unknown;
    selectBen?: boolean;
    disabled?: boolean;
    benId?: number;
}

const NewGrantDetails = (props: NewGrantProps) => {
    const { equityPlansStore, documentsStore } = useRootStore();
    // const { newBeneficiaryModalHandler } = useEsopModals();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isGrantLetter, setIsGrantLetter] = useState<boolean>(false);
    const [grantLetterTemplateId, setGrantLetterTemplateId] = useState<string>();
    const [grantMinDate, setGrantMinDate] = useState<Date>();
    const [expMinDate, setExpMinDate] = useState<Date>();
    const { loading, previewTemplate } = useTemplate();

    const {
        currenciesOptions,
        formValidationState,
        grantNumberRef,
        onInputHandler,
        onValidateGrantNumber,
        plans,
        validateForm,
        grantTypes,
        inputRefs,
        vests,
        clearErrors,
        grant,
    } = useGrant("newGrant", props.selectBen);
    // const { t } = useTranslation();
    // const inputRefs = useRef<(InputValidationRef | null)[]>([]);

    useEffect(() => {
        documentsStore.getTemplates();

        return () => {
            equityPlansStore.ResetGrant();
            setIsLoading(false);
        };
    }, []);

    const onAddGrant = async () => {
        if (!validateForm()) return;
        setIsLoading(true);
        await props.onSave(grantLetterTemplateId);

        clearErrors();
        setIsLoading(false);
    };

    const planChangeHandler = (selectedPlanId: number) => {
        const plan = equityPlansStore.companyData?.plans.filter((pln) => pln.planId === selectedPlanId)[0];
        if (isNullOrUndefined(plan?.planApprovalDateByBoard)) return;
        const minDate = plan?.planApprovalDateByBoard;
        setGrantMinDate(minDate);
    };

    const validateExpirationDate = async () => {
        // console.log('validating  exp date', grant.granted, grant.grantDate, grant.vestStartDate, grant.vestingId)
        if (!grant.granted || !grant.grantDate || !grant.vestStartDate || !grant.vestingId) return;
        const expDate = await equityPlansStore.ValidateExpDate(grant.granted, grant.grantDate, grant.vestStartDate, grant.vestingId);
        setExpMinDate(expDate.data?.date);
    };

    useEffect(() => {
        validateExpirationDate();
    }, [grant.granted, grant.grantDate, grant.vestStartDate, grant.vestingId]);

    return (
        <>
            {loading && <Spinner />}
            <div className={classNames(NewGrantDetailsStyle, { standalone: !props.onAddBen })}>
                {!props.onAddBen && <div className={`${NewGrantDetailsStyle}__title`}>Add New Grant</div>}
                <div className={`${NewGrantDetailsStyle}__main-section`}>
                    <div className={classNames(`${NewGrantDetailsStyle}__inputs-container`, { 'single-form': !documentsStore.isModuleActive })}>
                        {props.selectBen === true && (
                            <>
                                <Select
                                    // style={{ flex: 2 }}
                                    label="Beneficiary"
                                    options={equityPlansStore.beneficiariesList ?? []}
                                    value={grant.beneficiaryId}
                                    onChange={(val) => (grant.beneficiaryId = val as number)}
                                    qaid="AddGrant.Select.Beneficiary"
                                    disabled={props.disabled || !!props.benId}
                                    style={{ gridArea: '1 / 1 / 1 / 3' }}
                                    error={formValidationState?.beneficiaryId?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.beneficiaryId = el)}
                                    required
                                />
                                <Flex justify="start">
                                    <AddButton className={`${NewGrantDetailsStyle}__add-ben-btn`} onClick={props.onAddBen} qaid="" label="Add New" />
                                </Flex>
                                <div></div>
                                {/* <Flex gap="2.4rem">
									<AddButton className={`${NewGrantDetailsStyle}__add-ben-btn`} onClick={props.onAddBen} qaid="" label="Add New" />
								</Flex> */}
                            </>
                        )}

                        {/* Plan */}
                        <Select
                            label="Equity plan"
                            qaid="AddGrant.Select.EquityPlan"
                            options={plans}
                            name="planId"
                            onChange={(value, name) => {
                                if (isNumber(value)) {
                                    onInputHandler(value, name!);
                                    planChangeHandler(value);
                                }
                            }}
                            value={grant.planId}
                            error={formValidationState?.planId?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.planId = el)}
                            disabled={props.disabled}
                            required
                            className="mb-0"
                        />

                        {/* Grant Type */}
                        <Select
                            label="Award type"
                            qaid="AddGrant.Select.AwardType"
                            options={grantTypes}
                            name="grantType"
                            onChange={(value, name) => {
                                if (isNumber(value)) onInputHandler(value, name!);
                            }}
                            value={grant.grantType}
                            error={formValidationState?.grantType?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.grantType = el)}
                            disabled={props.disabled}
                            required
                            className="mb-0"
                        />

                        {/* Grant Date */}
                        <DatePickerInput
                            label="Grant date"
                            qaid="AddGrant.Input.GrantDate"
                            name="grantDate"
                            minDate={grantMinDate}
                            maxDate={new Date()}
                            value={grant?.grantDate?.toString()}
                            onChange={(value, name) => value && onInputHandler(value.toISOString(), name!)}
                            error={formValidationState?.grantDate?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.grantDate = el)}
                            disabled={props.disabled}
                            required
                            containerClassName="mb-0"
                        />

                        {/* Grant Number */}
                        <TextInput
                            label="Grant number"
                            qaid="AddGrant.Input.GrantNumber"
                            name="grantNumber"
                            value={grant?.grantNumber}
                            onChange={onInputHandler}
                            onBlur={onValidateGrantNumber}
                            onFocus={() => (grantNumberRef.current = grant?.grantNumber)}
                            error={formValidationState?.grantNumber?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.grantNumber = el)}
                            disabled={props.disabled}
                            required
                            containerClassName="mb-0"
                        />

                        {/* Granted */}
                        <NumberInput
                            label="Amount granted"
                            qaid="AddGrant.Input.GrantNumber"
                            name="granted"
                            value={grant?.granted}
                            onChange={(value, name) => {
                                if (isNumber(value)) onInputHandler(value, name!);
                            }}
                            error={formValidationState?.granted?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.granted = el)}
                            disabled={props.disabled}
                            required
                            containerClassName="mb-0"
                        />

                        {/* Vest Schedj */}
                        <Select
                            label="Vesting schedule"
                            qaid="AddGrant.Input.VestingSchedule"
                            options={equityPlansStore.companyActiveVests ?? []}
                            name="vestingId"
                            onChange={(value, name) => {
                                if (isNumber(value)) onInputHandler(value, name!);
                            }}
                            value={grant.vestingId}
                            error={formValidationState?.vestingId?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.vestingId = el)}
                            disabled={props.disabled}
                            required
                        />

                        {/* Vest Start date */}
                        <DatePickerInput
                            label="Vesting start date"
                            qaid="AddGrant.Input.VestingStartDate"
                            name="vestStartDate"
                            minDate={grantMinDate}
                            // maxDate={grant.grantDate}
                            value={grant?.vestStartDate?.toString()}
                            onChange={(value, name) => value && onInputHandler(value.toISOString(), name!)}
                            error={formValidationState?.vestStartDate?.message}
                            ref={(el: InputValidationRef) => (inputRefs.current.vestStartDate = el)}
                            disabled={props.disabled}
                            required
                            containerClassName="mb-0"
                        />

                        {grant.grantType === GrantTypes.Options && (
                            <>
                                {/* Exp, Date */}
                                <DatePickerInput
                                    label="Expiration date"
                                    qaid="AddGrant.Input.ExpirationDate"
                                    name="originalExpirationDate"
                                    minDate={
                                        expMinDate
                                            ? addDaysToDate(expMinDate, 1)
                                            : grant.grantDate
                                                ? addDaysToDate(grant.grantDate, 1)
                                                : undefined
                                    }
                                    value={grant?.originalExpirationDate}
                                    onChange={(value, name) => {
                                        if (!value) return;
                                        onInputHandler(value.toISOString(), name!);
                                        onInputHandler(value.toISOString(), "expirationDate");
                                    }}
                                    error={formValidationState?.originalExpirationDate?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.originalExpirationDate = el)}
                                    disabled={props.disabled}
                                    required
                                    containerClassName="mb-0"
                                />

                                {/* Ex. Price */}
                                <NumberInput
                                    qaid="AddGrant.Input.ExPrice"
                                    label="Exercise price"
                                    value={grant?.exercisePrice}
                                    name="exercisePrice"
                                    onChange={(value, name) => {
                                        if (isNumber(value)) onInputHandler(value, name!);
                                    }}
                                    number="float"
                                    error={formValidationState?.exercisePrice?.message}
                                    ref={(el: InputValidationRef) => (inputRefs.current.exercisePrice = el)}
                                    options={currenciesOptions}
                                    selectedValue={grant?.exercisePriceCurrency}
                                    onOptionSelect={(value) => {
                                        if (!isNumber(value)) return;
                                        onInputHandler(value, 'exercisePriceCurrency');
                                        onInputHandler(value, 'currency');
                                    }}
                                    disabled={props.disabled}
                                    required={grant.grantType === GrantTypes.Options || grant.grantType === GrantTypes.BSPCE}
                                    containerClassName="mb-0"
                                    ratio={0.6}
                                />
                            </>
                        )}

                        {/* Tax Track */}
                        <Select
                            label="Tax track"
                            options={equityPlansStore.taxTracks}
                            // onChange={(e) => (grant.taxTrack = e as number)}
                            onChange={(value, name) => {
                                if (isNumber(value)) onInputHandler(value, name!);
                            }}
                            name="taxTrack"
                            value={grant.taxTrack}
                            qaid="AddGrant.Input.TaxTrack"
                            disabled={props.disabled}
                            ref={(el: InputValidationRef) => (inputRefs.current.taxTrack = el)}
                            error={formValidationState?.taxTrack?.message}
                            required
                            className="mb-0"
                        />

                        {/* Department */}
                        <Select
                            label="Department"
                            options={equityPlansStore.departments}
                            onChange={(e) => (grant.department = e as number)}
                            value={grant.department}
                            qaid="AddGrant.Input.Department"
                            disabled={props.disabled}
                            className="mb-0"
                        />

                        {/* Site */}
                        <Select
                            label="Site"
                            options={equityPlansStore.sites}
                            onChange={(e) => (grant.site = e as number)}
                            value={grant.site}
                            qaid="AddGrant.Input.Site"
                            disabled={props.disabled}
                            className="mb-0"
                        />

                        {/* <NumberInput
							qaid="AddGrant.Input.TaxTrack"
							label="Tax Track"
							value={grant?.taxTrack}
							name="taxTrack"
							onChange={(value, name) => {
								if (isNumber(value)) onInputHandler(value, name!);
							}}
						/> */}
                    </div>

                    {
                        documentsStore.isModuleActive && (
                            <div className={`${NewGrantDetailsStyle}__grant-letter`}>
                                <div className="label">
                                    <span>Grant Letter</span>
                                </div>
                                <div className={`${NewGrantDetailsStyle}__inputs-container`}>
                                    <div className="flex" style={{ gridArea: '1 / 3 / 1 / 1', gap: '6.4rem' }}>
                                        <div style={{ height: '3.6rem', marginTop: !isGrantLetter ? '2.35rem' : 'auto', flexGrow: 1, flexShrink: 0 }}>
                                            <CheckBox
                                                qaid=""
                                                isChecked={isGrantLetter}
                                                onClick={() => {
                                                    setIsGrantLetter((prev) => !prev);
                                                    setGrantLetterTemplateId(undefined);
                                                }}
                                                label="Create grant letter"
                                            />
                                        </div>
                                        {isGrantLetter && (
                                            <Select
                                                qaid=""
                                                options={documentsStore.getTemplatesOptionsByType(TemplateTypeEnum.GrantLetter)}
                                                onChange={(value) => isString(value) && setGrantLetterTemplateId(value)}
                                                required
                                                value={grantLetterTemplateId}
                                                label="Template"
                                            />
                                        )}
                                    </div>
                                    {!!grantLetterTemplateId && (
                                        <div style={{ height: '3.6rem', marginTop: 'auto', display: 'flex', alignItems: 'center' }}>
                                            <Clickable
                                                qaid="AddGrant.Button.PreviewTemplate"
                                                onClick={async () => {
                                                    if (!grantLetterTemplateId) return;
                                                    previewTemplate(grantLetterTemplateId);
                                                }}
                                                flex={0}
                                            >
                                                <Image width="2.5rem" src={IC_EYE_ACTION} tooltip="Preview" />
                                            </Clickable>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }

                </div>
                <Button qaid="AddGrant.Button.AddGrant" label="Add" onClick={onAddGrant} isLoading={isLoading} disabled={props.disabled} position="end" />
            </div>
        </>
    );
};
export default observer(NewGrantDetails);
