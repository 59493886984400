import { IOrder } from "../../../../Models/App/EquityPlans/Order";
import Button from "../../../../Shared/Components/Button/Button";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import SectionStyles from "../../General/styles/SectionStyles";
import { useEffect } from "react";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";
import useEsopModals from "../../useEsopModals";
import { orderTypes } from "../../../../Shared/StaticData/equityPlans";
import Flex from "../../../../Shared/Components/Layout/Flex";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../../Shared/Utilities";

// import OrderRow from "./OrderRow/OrderRow";
// import { toJS } from "mobx";
// import NewOrder from "./NewOrder/NewOrder";
// import useModal from "../../../../Shared/Hooks/useModal";

interface OrderProps {
    rtd: () => void;
}

const Orders = (props: OrderProps) => {
    const history = useHistory();
    const { equityPlansStore } = useRootStore();
    const { newOrderHandler } = useEsopModals();

    useEffect(() => {
        equityPlansStore.GetDashboardOrders();
    }, []);

    const tableColumns: TableColumn<IOrder>[] = [
        { label: "Order number", name: "orderNumber" },
        { label: "Grant number", name: "grantNumber" },
        { label: "Employee number", name: "employeeNumber" },
        {
            label: "Full name",
            name: "fullName",
            format(val) {
                return `${val.firstName} ${val.lastName}`;
            },
        },
        {
            label: "Order type",
            name: "orderType",
            format(val) {
                return orderTypes[val]?.label;
            },
        },
        {
            label: "Order date",
            name: "date",
            format(val) {
                return formatDate(val);
            },
        },
        { label: "Amount", name: "amount" },
        { label: "Price", name: "sellPrice" },
    ];

    const multiDeleteHandler = () => {
        console.log("deleting...");
    };

    // const openOrderModal = (row: IOrder) => {
    // 	// equityPlansStore.GetGrantsForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetOriginalGrantEvents()
    // 	// equityPlansStore.GetModifiedGrantEvents()

    // 	// equityPlansStore.GetOrdersForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetCancelationsForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetBeneficiary(135, row.beneficiaryId)
    // 	// handleBeneficiaryModal()
    // 	showModal({
    // 		title: `Order # ${row?.grantNumber}`,
    // 		// body: <BeneficiaryCard
    // 		//     btnFunc={showCreateGrantModal}
    // 		//     openGrantModal={showGrantModal}
    // 		//     openOrderModal={showOrderModal}
    // 		//     openCancelationModal={showCancelationModal}
    // 		// />,
    // 		height: "100%",
    // 		width: "70%",
    // 		isMust: true,
    // 	});
    // };

    // const openOrderModal = (row: IOrder) => {
    // 	// equityPlansStore.GetGrantsForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetOriginalGrantEvents()
    // 	// equityPlansStore.GetModifiedGrantEvents()

    // 	// equityPlansStore.GetOrdersForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetCancelationsForBeneficiary(row.beneficiaryId)
    // 	// equityPlansStore.GetBeneficiary(135, row.beneficiaryId)
    // 	// handleBeneficiaryModal()
    // 	showModal({
    // 		title: `Order # ${row?.grantNumber}`,
    // 		// body: <BeneficiaryCard
    // 		//     btnFunc={showCreateGrantModal}
    // 		//     openGrantModal={showGrantModal}
    // 		//     openOrderModal={showOrderModal}
    // 		//     openCancelationModal={showCancelationModal}
    // 		// />,
    // 		height: "100%",
    // 		width: "70%",
    // 		isMust: true,
    // 	});
    // };

    useEffect(() => {
        equityPlansStore.GetDashboardOrders();
    }, [equityPlansStore.dataAsOfDate, equityPlansStore.selectedPlan]);

    return (
        <div className={SectionStyles}>
            <div className="section-header">
                <Button qaid="" inverse onClick={() => history.push("/ESOP/dashboard")}>
                    Go back
                </Button>
                {/* <h1>Orders</h1> */}
            </div>
            <div className="section-table">
                {/* <div className="table-actions">
					<Button qaid="" inverse label="Create New Order" onClick={() => newOrderHandler(true, true)} />
				</div> */}

                <Table
                    columns={tableColumns}
                    rows={equityPlansStore.companyOrders}
                    // color="secondary"
                    scrollAfterRows={13}
                    selectBy="orderId"
                    // onRowSelect={() => { }}
                    // onExpandRender={(row) => {
                    // 	equityPlansStore.GetBeneficiaryOrder(row.orderId);
                    // 	return <OrderRow row={row} />;
                    // }}
                    // actions={[
                    // 	{
                    // 		label: 'Delete',
                    // 		onClick: multiDeleteHandler,
                    // 		qaid: "Order.Button.Delete"
                    // 	}
                    // ]}
                    showTotal={false}
                    // onRowSelect={(row) => {
                    //     return <>{row.}</>
                    //     // setInvitationArr(invi)
                    //     // console.log(toJs(row))
                    // }}
                    // oneditrender
                    filterBy={tableColumns.filter((col) => col.name !== "actions").map((col) => col.name as keyof IOrder)}
                    searchBy={["orderNumber"]}
                    exportToExcel={{ fileName: "Orders", sheetName: "Orders" }}
                    fullscreen
                    renderBottom={
                        <Flex align="center" justify="start">
                            <AddButton onClick={() => newOrderHandler(true, true)} qaid="" label="Add new order" />
                        </Flex>
                    }
                />
            </div>
        </div>
    );
};

export default observer(Orders);
