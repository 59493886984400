import { css } from "@emotion/css";
import appConfig from "../../../../../../../config/config";
import { COLOR_BLUE_HAZE, COLOR_SANTAS_GRAY, COLOR_TITAN_WHITE, COLOR_TUNDORA } from "../../../../../../../config/style/lib/colors";
import { theme } from "../../../../../../../config/style/theme";
import { last } from "lodash";

export const ReviewersStyles = css({
	label: "Reviewers",
	".title": {
		fontSize: "1.4rem",
		marginBottom: "0.5rem",
		color: COLOR_SANTAS_GRAY,
		fontWeight: "normal",
		letterSpacing: "0.1rem",
	},
	".selection-list-wrapper": {
		display: "flex",
		boxShadow: "3px 3px 3px 0px rgba(201, 202, 208, 0.40)",
		border: `1px solid ${appConfig.style.colors.color1Secondary}`,
		borderRadius: theme.borderRadiuses.medium,
		maxHeight: 344,
	},
});

export const SelectionListStyle = css({
	flexGrow: 1,

	"& + &": {
		borderLeft: `3px solid ${COLOR_BLUE_HAZE}`,
	},

	"&__Header": {
		alignItems: "center",
		maxHeight: 60,
		paddingInline: 29,
		paddingBlock: 25,
		fontSize: "1.4rem",
		fontFamily: appConfig.style.fonts.assistant,
		fontWeight: 700,
		display: "flex",
		borderBottom: `3px solid ${COLOR_BLUE_HAZE}`,
		backgroundColor: COLOR_TITAN_WHITE,
		color: COLOR_TUNDORA,
		columnGap: theme.spacings.xs,

		".selection-list--selectable &": {
			borderTopLeftRadius: theme.borderRadiuses.medium,
		},

		".selection-list--selected &": {
			borderTopRightRadius: theme.borderRadiuses.medium,
		},
	},

	"&__Items": {
		display: "flex",
		flexGrow: 1,
		width: "100%",
		flexDirection: "column",
		overflowX: "hidden",
		overflowY: "auto",
		position: "relative",
		listStyle: "none",
		margin: 0,
		padding: 0,
		maxHeight: "16rem",

		"&::-webkit-scrollbar-thumb": {
			background: appConfig.style.colors.color1,
			"&:hover": {
				background: appConfig.style.colors.darkBlue,
			},
		},
	},

	"&__Item": {
		minHeight: "4rem",
		paddingInline: "2.9rem",
		display: "flex",
		alignItems: "center",

		".selection-list--selectable & > div": {
			flexDirection: "row-reverse",
			flexGrow: 1,
		},

		".selection-list--selectable & > div > div:first-of-type": {
			flexGrow: 1,
		},

		".selection-list--selectable &::after": {
			content: "''",
			height: "1px",
			backgroundColor: COLOR_BLUE_HAZE,
			position: "absolute",
			bottom: 0,
			width: "90%",
			paddingInline: "2.3rem",
			marginInline: "auto",
		},

		".selection-list--selected &": {
			color: appConfig.style.colors.text2,
		},
	},
});
