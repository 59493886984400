import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const SectionStyles = css({
	display: "flex",
	flexDirection: "column",
	padding: "1rem",
	// "boxShadow": appConfig.style.boxShadow.card1,

	".section-header": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "flex-start",
		padding: "3rem",
	},

	".section-table": {
		display: "flex",
		flexDirection: "column",
		margin: "1rem",
		// backgroundColor: "yellow"
	},

	".table-actions": {
		display: "flex",
		flexDirection: "row",
		// backgroundColor: "red"
	},
});

export default SectionStyles;
