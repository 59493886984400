import { CompanyRegistration } from '../../Models/API/Auth';
import { DocumentTemplateData } from '../../Models/API/Document/template-data';
import { PaymentPlanEnum } from '../../Models/API/enums';
import { LimitCodesEnum } from '../../Models/App/PaymentModels';
import AddNewCompany from '../../Screens/Account/AddEditNewCompany/AddNewCompany';
import EditCompany from '../../Screens/Account/AddEditNewCompany/EditCompany';
import ContactUs from '../../Screens/Account/ContactUs';
import Preview, { InfoProps, InfoType } from '../../Screens/Documents/Components/Preview';
import SignDocument, { SignDocumentProps } from '../../Screens/Documents/Components/SignDocument';
import AddStakeholders from '../Components/AddStakeholders';
import AppInfo from '../Components/AppInfo';
import Limitation from '../Components/Limitation';
import UpgradePlan from '../Components/UpgradePlan';
import YouTube from '../Components/YouTube';
import useModal from './useModal';
import useRootStore from './useRootStore';

const useGeneralModal = () => {
	const { showModal } = useModal();
	const { companyStore, paymentStore, contactStore, appState } = useRootStore();

	const onAddNewCompany = () => {
		showModal({
			body: <AddNewCompany />,
			width: '90%',
			height: '90%',
			maxWidth: '140rem',
			maxHeight: '95rem',
			isFrameless: true,
			isMust: true,
		});
	};

	const onEditCompany = (company: CompanyRegistration) => {
		showModal({
			body: <EditCompany company={company} />,
			width: '90%',
			height: '90%',
			maxWidth: '140rem',
			maxHeight: '95rem',
			isFrameless: true,
			isMust: true,
		});
	};

	const onContactUs = () => {
		showModal({
			body: <ContactUs />,
			width: '90%',
			maxWidth: '54.7rem',
			isFrameless: true,
			isMust: true,
		});
	};

	const onReachedLimitation = () => {
		const admin = contactStore.companyAdmin;

		const limitCode =
			companyStore.planLimitation.limitCode === LimitCodesEnum.Stakeholders && paymentStore.currentPlan.code === PaymentPlanEnum.Starter
				? null
				: companyStore.planLimitation.limitCode;

		showModal({
			body: <Limitation limitCode={limitCode} admin={admin.isAdmin || { fullName: `${admin.firstName} ${admin.lastName}`, email: admin.email }} />,
			onModalClose: companyStore.resetPlanLimitation,
			width: '50rem',
		});
	};

	const onUpgradePlan = () => {
		const admin = contactStore.companyAdmin;

		showModal({
			body: <UpgradePlan admin={admin.isAdmin || { fullName: `${admin.firstName} ${admin.lastName}`, email: admin.email }} />,
			onModalClose: companyStore.resetPlanLimitation,
			width: '50rem',
		});
	};

	const onShowGeneralInfo = () => {
		if (appState.isProd) return;

		showModal({
			body: <AppInfo />,
			maxWidth: '80rem',
			width: '100%',
		});
	};

	const showErrorModal = (text = 'Something went wrong', title: string = 'Error') => {
		showModal({
			title,
			body: <>{text}</>,
			type: 'error',
			cancelButton: {
				label: 'Close',
			},
		});
	};

	const showWarningModal = (text = 'Attention', title: string = 'Warning!') => {
		showModal({
			title,
			body: <>{text}</>,
			type: 'warning',
		});
	};

	const showYouTubeModal = (src: string) => {
		showModal({
			body: <YouTube src={src} />,
			isFrameless: true,
			width: '100%',
			height: `100%`,
			maxWidth: '132.2rem',
			maxHeight: '74.4rem',
			showClose: false,
			className: 'youtube-modal',
		});
	};

	const showDocumentModal = (data: DocumentTemplateData, info: InfoProps, onClose?: () => void) => {
		showModal({
			body: ({ removeModal }) => (
				<Preview
					onClose={() => {
						removeModal?.();
						onClose?.();
					}}
					fileBase64={data.fileBase64}
					fileName={data.fileName}
					extension={info.isPreview ? 'docx' : 'pdf'}
					info={info}
				/>
			),
			isStandalone: true,
		});
	};

	const showSignDocumentModal = (props: SignDocumentProps) => {
		showModal({
			body: <SignDocument {...props} />,
			isStandalone: true,
		});
	};

	const showAddStakeholders = () => {
		showModal({
			body: <AddStakeholders />,
			width: '70rem',
			height: 'auto',
			allowScrolling: false,
		});
	};

	return {
		showWarningModal,
		showErrorModal,
		onAddNewCompany,
		onEditCompany,
		onContactUs,
		onReachedLimitation,
		onShowGeneralInfo,
		showYouTubeModal,
		showDocumentModal,
		showSignDocumentModal,
		showAddStakeholders,
		onUpgradePlan,
	};
};

export default useGeneralModal;
