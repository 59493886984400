import React from 'react'
import useRootStore from '../../../../../Shared/Hooks/useRootStore'
import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton'
import useEsopModals from '../../../useEsopModals'
import Login from '../../../../CapTable/Components/General/OptionsSync/Pivotal/Login'
import { IPivotalCompany } from '../../../../../Models/App'
import { ReqTypePivotal } from '../../../../../Services/TrusteeService'

import DataSourceStyle from './SelectDataSource.module.css'
import Button from '../../../../../Shared/Components/Button/Button'
import useModal from '../../../../../Shared/Hooks/useModal'
import useTrusteeModals from '../../../../TrusteeEquity/useTrusteeModals'



const SelectDataSource = (props: { initialRegistrartion?: boolean }) => {

    const { companyStore, trusteeStore, auth } = useRootStore()
    const { showChangeDataSource } = useEsopModals()
    //const { showModal } = useModal()
    //const { showPivotalRegistration } = useTrusteeModals()

    const equityTxt = 'Create & manage your data actively using Equity Plans product'
    const trusteeTxt = 'All the equity plans data is synchronized from altshare trustee service'



    //const pivotalRegistrationHandler = () => {
    // Pivotal managment API:
    // 1. get GUID
    // 2. sent OTP + GUID --> GET Token
    // 3. PUT Company

    // showModal({
    //     body: <Login onCompanySelect={registerUserToPivotal} />,
    //     isMust: true,
    // });
    // showPivotalRegistration()
    //};

    const poolSummaryAndAutorization = async () => {
        //const summary = await 
        trusteeStore.GetPoolSummary();

        // if (!summary.data) {
        //     pivotalRegistrationHandler();
        // }
    };

    const equitySourceSelectorHandler = (fromPivotal: boolean) => {
        if (fromPivotal === companyStore.company.pivotalEquityPlans) return;

        showChangeDataSource(fromPivotal);

        if (fromPivotal) {
            console.log('companyStore.company.pivotalEquityPlans', companyStore.company.pivotalEquityPlans)
            poolSummaryAndAutorization();
        }
    };

    return (
        <div style={DataSourceStyle} className={DataSourceStyle.container}>
            <div
                className={DataSourceStyle.sectionHeader}
                style={{ borderTop: "none" }}
            >
                <h2 style={{ fontSize: "2.2rem", fontWeight: 600 }}>Equity plans data source</h2>
            </div>

            <div className={DataSourceStyle.section}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "60%",
                    }}
                >
                    <p style={{ fontWeight: "600", fontSize: "2rem", margin: 0 }}>Choose import from: </p>
                    <RadioButton
                        name="dataFrom"
                        qaid=""
                        value={!companyStore.company.pivotalEquityPlans}
                        checked={!companyStore.company.pivotalEquityPlans}
                        label="Equity plans product"
                        onChange={() => equitySourceSelectorHandler(false)}
                    />
                    <RadioButton
                        name="dataFrom"
                        qaid=""
                        value={companyStore.company.pivotalEquityPlans}
                        checked={companyStore.company.pivotalEquityPlans}
                        label="altshare trustee data"
                        onChange={() => equitySourceSelectorHandler(true)}
                    />
                </div>
                <p style={{ marginTop: "4rem", color: "#979DB5" }}>
                    {companyStore.company.pivotalEquityPlans ? trusteeTxt : equityTxt}
                </p>
            </div>

            {/* <Button
                qaid=""
                label="Register User"
                onClick={pivotalRegistrationHandler}
            /> */}
        </div>
    )
}

export default SelectDataSource















// const registerUserToPivotal = async (rcpToken: string, cmp: IPivotalCompany): Promise<boolean> => {

//     if (!auth.pivotalToken) {
//         return false
//     }

//     const obj: ReqTypePivotal = {
//         companyId: trusteeStore.companyId,
//         productsSchema: 1,
//         reCaptchaToken: rcpToken,
//         token: auth.pivotalToken,
//         trusteeCompanyId: cmp.companyId,
//     };

//     const res = await trusteeStore.SetUserCompanyToken(obj)

//     if (res.data?.companyId) {
//         await trusteeStore.GetPoolSummary()
//         return true
//     }
//     return false
// }