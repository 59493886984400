import { css } from "@emotion/css";
import { observer } from "mobx-react-lite";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { rootStore } from "../../../..";
import { IC_DUPLICATE2, IC_EXCEL_EXPORT } from "../../../../Assets";
import { Routes } from "../../../../Routes";
import ActionsMenu from "../../../../Shared/Components/ActionsMenu/ActionsMenu";
import Button from "../../../../Shared/Components/Button/Button";
import TextInput from "../../../../Shared/Components/Input/TextInput";
import Flex from "../../../../Shared/Components/Layout/Flex";
import Text from "../../../../Shared/Components/Layout/Text";
import useModal from "../../../../Shared/Hooks/useModal";
import useMultiStepForm, { MultiStepFormItem, ForwardedRef } from "../../../../Shared/Hooks/useMultiStepForm";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../../Shared/Utilities";
import { WaterfallFormSteps } from "../../../../Store/WaterfallStore";
import WaterfallHeader from "../WaterfallHeader/WaterfallHeader";
import { ActionsMenuStyle } from "../WaterfallHeader/WaterfallHeader.style";
import Styles, { WaterfallFooter } from "./AddEditWaterfall.Style";
import CapTable from "./Steps/CapTable/CapTable";
import EquityPlansImport from "./Steps/EquityPlansImport/EquityPlansImport";
import GeneralInfo from "./Steps/GeneralInfo/GeneralInfo";
import LiquidationPreferences from "./Steps/LiquidationPreferences/LiquidationPreferences";
import PreferencesTerms from "./Steps/PreferencesTerms/PreferencesTerms";
import Summary from "./Steps/Summary";
import useExportSummary from "./Steps/Summary/deps/useExportSummary";
import ProgressBar from "../../../../Shared/Components/ProgressBar";
import appConfig from "../../../../config/config";
import { MenuActionType } from "../../../../Models/App/action-menu-type";

export type RouteParams = {
    step: string;
    waterfallId: string;
};

const AddEditWaterfall = () => {
    const params = useParams<RouteParams>();
    const {
        waterfallStore,
        appState,
        companyStore: { companyId },
    } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentStepValid, setCurrentStepValid] = useState<boolean>(false);
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState<boolean>(false);
    const { showModal } = useModal();
    const history = useHistory();
    const ref = createRef<ForwardedRef>();
    const nextRef = createRef<HTMLDivElement | null>();
    const { t } = useTranslation();

    const { onExportSummary } = useExportSummary({
        data: waterfallStore.destrcturedValuationData(waterfallStore.scenariosForm.exitValue ?? 0),
        exitValue: waterfallStore.scenariosForm.exitValue,
        projectName: waterfallStore.projectName,
    });

    const onSaveHandler = () => {
        showModal({
            body: <SaveAsModal goTo={goTo} />,
            width: "55%",
        });
    };

    const onSubmit = async (e?: any, isStepClick?: boolean, step?: WaterfallFormSteps) => {
        e?.preventDefault();
        if (isLoading) return;

        const isValid = waterfallStore.isViewMode || (await isCurrenetStepValid());

        if (!isValid) return;

        if (+params.step === waterfallStore.lastAllowedStep) {
            return;
        }

        if (currentStepIdx === WaterfallFormSteps.capTable && !waterfallStore.isOptionsAllowed && !isStepClick) {
            return goTo(Math.min(waterfallStore.lastAllowedStep, WaterfallFormSteps.shareClasses));
        }

        if (!isLastStep) {
            return step && isStepAllowed(step) ? goTo(step) : onNextStep(); // If not last step, continue to next step
        }

        // Otherwise submit
        // alert("Saved!");
        history.push(Routes.waterfall.index);
        setIsLoading(false);
    };

    const isCurrenetStepValid = async (): Promise<boolean> => {
        setIsLoading(true);
        const isValid = await ref.current?.onValidate();
        isValid ? setCurrentStepValid(true) : setCurrentStepValid(false);
        setIsLoading(false);
        return !!isValid;
    };

    const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
        {
            caption: "generalInfo",
            element: <GeneralInfo ref={ref} />,
        },
        {
            caption: "capTableImport",
            element: <CapTable ref={ref} />,
        },
        {
            caption: "equityPlansImport",
            element: <EquityPlansImport ref={ref} />,
            isRequired: false,
        },
        {
            caption: "liquidationPreferences",
            element: <LiquidationPreferences ref={ref} />,
        },
        {
            caption: "preferencesTerms",
            element: <PreferencesTerms ref={ref} />,
        },
        {
            caption: "waterfallSummary",
            element: <Summary ref={ref} />,
        },
    ]);

    const menuActions: MenuActionType[] = [
        {
            label: "Save as draft",
            icon: IC_DUPLICATE2,
            onClick: onSaveHandler,
            qaid: "ActionsMenu.Button.Duplicate",
        },
        {
            label: "Export to Excel",
            icon: IC_EXCEL_EXPORT,
            // separator: project.isPublished,
            onClick: onExportSummary,
            qaid: "ActionsMenu.Button.Export",
            disabled: currentStepIdx !== WaterfallFormSteps.summary,
        },
    ];

    useEffect(() => {
        const waterfallId = +params.waterfallId;
        const isDifferent = waterfallId && waterfallStore.currentWaterfallId && waterfallId !== waterfallStore.currentWaterfallId;
        if (isNullOrUndefined(waterfallStore.isViewMode) || isDifferent) {
            waterfallStore.resetForm();
            return history.push(Routes.waterfall.index); // If the user didn't come from the waterfall table
        }
        const step = +params.step;
        waterfallStore.currentWaterfallId = waterfallId;

        if (!isNaN(step)) goTo(step);

        return () => {
            // history.push(Routes.waterfall.index);
            waterfallStore.clearProjects();
            waterfallStore.resetWaterfallProject();
        };
    }, []);

    const isNextStep = (targetStepIdx: number): boolean => {
        return targetStepIdx - 1 === currentStepIdx;
    };

    const isStepAllowed = (targetStepIdx: number): boolean => {
        return targetStepIdx <= waterfallStore.lastAllowedStep;
    };

    useEffect(() => {
        if (!waterfallStore.waterfallId) return;
        history.replace(`${Routes.waterfall.index}/${waterfallStore.waterfallId}/${currentStepIdx}`);
    }, [currentStepIdx, waterfallStore.waterfallId]);

    const onStepClickHandler = async (targetStepIdx: number) => {
        if (isFirstStep && isStepAllowed(targetStepIdx)) {
            return onSubmit(null, true, targetStepIdx);
        }

        if (isNextStep(targetStepIdx)) {
            return onSubmit(null, true);
        }
        if (isStepAllowed(targetStepIdx)) {
            return goTo(targetStepIdx);
        }
        const stepsBetween = steps.slice(currentStepIdx + 1, targetStepIdx);
        const isSkippable = stepsBetween.every((step) => step.isRequired === false); // If all between steps are not mandatory, skip

        if (isSkippable) {
            await onSubmit();
            return goTo(targetStepIdx);
        }
    };

    const onQuitHandler = async () => {
        if (!waterfallStore.isViewMode && waterfallStore.currentWaterfallId) {
            await waterfallStore.updateLastModified({
                waterfallId: waterfallStore.waterfallId,
                companyId,
            });
        }

        history.push(Routes.waterfall.index);

        // showModal({
        // 	type: "confirm",
        // 	title: t("waterfall.saveChanges"),
        // 	body: t("waterfall.quitFromProject", { projectName: waterfallStore.projectName }),
        // 	isMust: true,
        // 	onCancel: async () => {
        // 		// const isValid = await ref.current?.onValidate();
        // 		// !isValid &&
        // 		await waterfallStore.updateLastModified({
        // 			waterfallId: waterfallStore.waterfallId,
        // 			companyId,
        // 		});
        // 		history.push(Routes.waterfall.index);
        // 	},
        // });
    };

    return (
        <section className={Styles}>
            <ProgressBar
                pageCaptions={steps.map((step: MultiStepFormItem) => t(`waterfall.${step.caption}`))}
                onStepClickHandler={onStepClickHandler}
                currentStepIdx={currentStepIdx}
                title={
                    <>
                        <span style={{ color: appConfig.style.colors.text2 }}>Analysis name:</span>{" "}
                        {waterfallStore.projectName || t("waterfall.createNewProject")}
                    </>
                }
                lastCompletedIdx={waterfallStore.lastAllowedStep - 1}
                actions={
                    <div className={ActionsMenuStyle}>
                        {/* <Button qaid="ProgressBar.Button.Save" onClick={onSaveHandler} inverse label="Save as" className="button--waterfall" /> */}
                        <Button
                            qaid="ProgressBar.Button.Quit"
                            onClick={onQuitHandler}
                            label="Save & Quit"
                            cancel
                            className="button--waterfall"
                        />

                        <ActionsMenu actions={menuActions} />
                    </div>
                }
                isLastStepHighlighed
            />

            {step}

            <WaterfallFooter>
                {!isFirstStep && (
                    <Button
                        qaid="AddEditWaterfall.Button.Back"
                        type="button"
                        inverse
                        onClick={onPrevStep}
                        label="general.back"
                    />
                )}

                <Button
                    style={{ marginLeft: "auto" }}
                    qaid="AddEditWaterfall.Button.Next"
                    ref={nextRef}
                    isLoading={isLoading}
                    onClick={onSubmit}
                    label={
                        isLastStep
                            ? "general.finish"
                            : +params.step === WaterfallFormSteps.preferencesTerms
                            ? "waterfall.evaluateAndSummarize"
                            : "general.next"
                    }
                />
            </WaterfallFooter>
        </section>
    );
};

const SaveAsModal = (props: { goTo: (step: number) => void }) => {
    const [name, setName] = useState<string>("");
    // const [reviewers, setReviewers] = useState<Reviewer[] | undefined>(undefined);
    const [error, setError] = useState<string>();
    const { waterfallStore, auth } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { clearModals } = useModal();
    const history = useHistory();

    useEffect(() => {
        const isMatch = /^[a-zA-Z0-9 \-&:+@()]*$/.test(name);
        setError(isMatch ? "" : 'Only letters, numbers, spaces or "- & : + @ ( )" allowed');
    }, [name]);

    const modalCss = css({
        maxWidth: 500,
    });

    const onSaveHandler = async () => {
        if (!name || error) return;
        setIsLoading(true);
        setError(undefined);
        const res = await waterfallStore.duplicateProject({
            companyId: rootStore.companyStore.companyId,
            waterfallId: waterfallStore.waterfallId,
            projectName: name,
        });
        if (isNullOrUndefined(res.data)) {
            setIsLoading(false);
            setError(res.errorMessage);
            return;
        }
        const projectRes = await waterfallStore.getProject(res.data);
        if (isNullOrUndefined(projectRes.data)) return;

        const path = `${Routes.waterfall.index}/${projectRes.data.waterfallId}${
            waterfallStore.lastAllowedStep ? `/${waterfallStore.lastAllowedStep}` : ""
        }`;
        waterfallStore.setProjectName(projectRes.data.projectName);
        waterfallStore.isNewProject = false;
        // to check if owner
        waterfallStore.setProjectViewMode(false);
        history.push(path);
        props.goTo(waterfallStore.lastAllowedStep);
    };

    return (
        <Flex
            direction="column"
            className={modalCss}
            margin="auto"
        >
            <Text
                family="alt"
                fontWeight={700}
                color="primary"
                size={18}
                className="mb-5"
            >
                Create a copy of analysis project
            </Text>
            <TextInput
                label="New analysis project name"
                required
                value={name}
                onEnter={onSaveHandler}
                error={error}
                onChange={(val) => setName(val)}
                qaid="SaveAsDialog.Input.ProjectName"
            />
            <span style={{ maxWidth: "19.5rem", fontSize: "1.1rem", color: "#ACB2CC", marginTop: "0.5rem" }}>
                Note: Valuation project name should be unique among your company Waterfall analysis. The new name should differ from the
                original Waterfall project's name.
            </span>

            {/* <Text family="assistant.bold">Valuation Reviewers:</Text>
      {reviewers ? (
        <Flex direction="column" align="start">
          {reviewers.map((reviewer) => (
            <span style={{ color: appConfig.style.colors.text2 }} key={reviewer.userId}>{`${reviewer.name} (${
              reviewer.isOwner ? "Editor" : "Viewer"
            })`}</span>
          ))}
        </Flex>
      ) : (
        <Flex direction="column" width="100%">
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Flex>
      )} */}

            <Flex
                justify="center"
                gap={20}
                margin="40px 0 0 0"
            >
                <Button
                    qaid="SaveAsDialog.Button.Quit"
                    label="general.quit"
                    cancel
                    onClick={clearModals}
                />
                <Button
                    isLoading={isLoading}
                    qaid="SaveAsDialog.Button.Save"
                    label="general.save"
                    disabled={!!error || !name}
                    onClick={onSaveHandler}
                />
            </Flex>
        </Flex>
    );
};

export default observer(AddEditWaterfall);
