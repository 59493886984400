import styled from "@emotion/styled";
import { theme } from "../../../../../../config/style/theme";

export const CustomRadioBtn = styled.label`
	display: flex;
	align-items: center;

	input[type="radio"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		border: 2px solid ${theme.colors.primary};
		outline: none;
		transition: border-color 0.2s;
		background-color: white;
		margin-right: 0.5rem;
	}

	input[type="radio"]:checked {
		border-color: ${theme.colors.primary};
		background-color: ${theme.colors.primary};
		box-shadow: inset 0 0 0 2px #ffffff;
	}

	input[type="radio"]:focus {
		border-color: ${theme.colors.primary};
	}

	input[type="radio"]:disabled {
		opacity: 0.25;
	}
`;

export const ModalContent = styled.div`
	height: 100%;
	& > div {
		margin-bottom: ${theme.spacings.md};
	}
`;
