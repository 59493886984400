import { css } from "@emotion/css";
import appConfig from "../../../../../../config/config";

const IntroStyle = css({
	label: "Intro",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "6rem 16.5rem",

	".title": {
		margin: "5rem 0 1rem 0",
		fontSize: "2.5rem",
		fontWeight: "700",
		color: appConfig.style.colors.color1,
		width: "fit-content",
	},

	".description": {
		color: appConfig.style.colors.background2,
		width: "fit-content",
		fontSize: "1.4rem",
	},

	".button": {
		marginTop: "8rem",
	},
});

export default IntroStyle;
