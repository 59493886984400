import { css } from "@emotion/css";
import appConfig from "../../../config/config";
import { breakpoints } from "../../../config/style/theme";

export const equityDashboardStyle = css({
	label: "EquityDashboard",
	display: "flex",
	flexDirection: "column",
	height: '100%',
	"&__dashboard": {
		display: "grid",
		padding: "1.6rem 3.8rem",
		background: appConfig.style.colors.background1,
		gridTemplateColumns: "repeat(9, 1fr)",
		gridTemplateRows: "repeat(2, 1fr)",
		gridGap: "2.4rem",
		".card1": {
			gridArea: "1 / 1 / 3 / 4",
			minWidth: "45rem",
		},
		".card2": {
			gridArea: "1 / 4 / 2 / 10",
			display: "flex",
		},
		".card3": {
			gridArea: "2 / 4 / 3 / 7",
		},
		".card4": {
			gridArea: "2 / 7 / 3 / 10",
		},
	},
    "&__table-container": {
        padding: "4rem",
    },
});
