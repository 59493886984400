import { SyntheticEvent, createRef, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../Routes";
import Button from "../../../../Shared/Components/Button/Button";
import ProgressBar from "../../../../Shared/Components/ProgressBar";
import useMultiStepForm, { MultiStepFormItem, ForwardedRef } from "../../../../Shared/Hooks/useMultiStepForm";
import { ImportContext, ImportProvider } from "./ImportContext";
import AddShareClasses from "./Steps/AddShareClasses";
import CommonShares from "./Steps/CommonShares";
import ImportCapTable from "./Steps/ImportCapTable";
import ShareClasses from "./Steps/ShareClasses";
import Shareholders from "./Steps/Shareholders";
import { CapTableImportStyle, ImportCapTableFooter } from "./index.style";
import useModal from "../../../../Shared/Hooks/useModal";
import NewDraft from "../MainMenu/Modals/NewDraft";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import { formatDate } from "../../../../Shared/Utilities";

enum ImportSteps {
    import,
    shareholders,
    commonShares,
    shareClasses,
    addShareClasses,
}

const CapTableExcelImport = () => {
    return (
        <ImportProvider>
            <ImportContainer />
        </ImportProvider>
    );
};

const ImportContainer = () => {
    const { capTableStore } = useRootStore();
    const ref = createRef<ForwardedRef>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showModal } = useModal();
    const { currentStepIdx, goTo, onNextStep, onPrevStep, isLastStep, step, steps } = useMultiStepForm([
        {
            caption: "Import Cap Table",
            element: <ImportCapTable ref={ref} />,
        },
        {
            caption: "Shareholder names",
            element: <Shareholders ref={ref} />,
        },
        {
            caption: "Common / Ordinary shares",
            element: <CommonShares ref={ref} />,
        },
        {
            caption: "Share classes name",
            element: <ShareClasses ref={ref} />,
        },
        {
            caption: "Share classes data",
            element: <AddShareClasses ref={ref} />,
        },
    ]);

    useEffect(() => {
        window.onbeforeunload = () => true;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const isNextStep = (targetStepIdx: ImportSteps): boolean => {
        return targetStepIdx - 1 === currentStepIdx;
    };

    const isStepAllowed = (targetStepIdx: ImportSteps): boolean => {
        return targetStepIdx <= currentStepIdx;
    };

    const onStepClickHandler = (targetStepIdx: ImportSteps) => {
        if (isNextStep(targetStepIdx)) {
            return onSubmit(undefined, true);
        }
        if (isStepAllowed(targetStepIdx)) {
            return goTo(targetStepIdx);
        }
    };

    const createProject = () => {
        showModal({
            body: (
                <NewDraft
                    onSave={async (name) => {
                        await capTableStore.createNewProject(name || `Autosave ${formatDate(new Date())}`, new Date());
                        history.push(`${Routes.capTable.index}?load=false`);
                    }}
                />
            ),
            isFrameless: true,
            width: "44.2rem",
            maxWidth: "44.2rem",
            height: "42.8rem",
        });
    };

    const onSubmit = async (e?: SyntheticEvent, isStepClick?: boolean) => {
        e?.preventDefault();
        if (isLoading) return;

        const isValid = await isCurrenetStepValid();
        if (!isValid) return;

        if (!isLastStep) {
            return onNextStep();
        }

        alert("Done!");
        setIsLoading(false);
    };

    const isCurrenetStepValid = async () => {
        setIsLoading(true);
        const isValid = await ref.current?.onValidate();
        setIsLoading(false);
        return !!isValid;
    };

    const onQuitHandler = () => {
        if (currentStepIdx === ImportSteps.import || currentStepIdx === ImportSteps.addShareClasses) {
            return history.push(Routes.capTable.index);
        }

        showModal({
            type: "warning",
            title: "Are you sure?",
            body: "Please note, that leaving the Cap table onboarding process, will not save the data.",
            onConfirm: () => history.push(Routes.capTable.index),
            confirmButton: {
                inverse: true,
                label: "Continue",
            },
            cancelButton: {
                label: "Cancel",
                inverse: false,
                cancel: false,
            },
        });
    };

    return (
        <section className={CapTableImportStyle}>
            <ProgressBar
                pageCaptions={steps.map((step: MultiStepFormItem) => step.caption || "")}
                onStepClickHandler={onStepClickHandler}
                currentStepIdx={currentStepIdx}
                lastCompletedIdx={currentStepIdx - 1}
                actions={
                    <Button
                        qaid="ProgressBar.Button.Quit"
                        onClick={onQuitHandler}
                        label="Quit"
                        cancel
                    />
                }
                size="md"
            />
            {step}
            <ImportCapTableFooter>
                <span className="note">
                    Note: if for some reason, the presented cap table data is corrupted, please{" "}
                    <span
                        className="link"
                        onClick={createProject}
                    >
                        click here
                    </span>{" "}
                    to continue manually
                </span>
                <Button
                    qaid="CapTableExcelImport.Button.Back"
                    type="button"
                    inverse
                    onClick={onPrevStep}
                    disabled={isLoading}
                    label="general.back"
                />
                {!isLastStep && (
                    <Button
                        style={{ marginLeft: "auto" }}
                        qaid="CapTableExcelImport.Button.Next"
                        // ref={nextRef}
                        disabled={isLoading}
                        onClick={onSubmit}
                        label="general.next"
                    />
                )}
            </ImportCapTableFooter>
        </section>
    );
};

export default CapTableExcelImport;
