import React, { forwardRef, useEffect, useState } from "react";
import Flex from "../../../../../../Shared/Components/Layout/Flex";
import Button from "../../../../../../Shared/Components/Button/Button";
import { StepDivider, StepTitle } from "../../Registration.Style";
import { MultiStepElementProps, ForwardedRef } from "../../../../../../Shared/Hooks/useMultiStepForm";
import { CompanyRegistrationEnum } from "../../Registration";
import EntitiesFactory from "../../../Settings/EntitiesFactory/EntitiesFactory";
import { CompanyDataStyle } from "./CompanyData.Style";
import { ModalBodyProps } from "../../../../../../Shared/Components/Modal/types";
import useRootStore from "../../../../../../Shared/Hooks/useRootStore";
import { Comment } from "../../../../../../Shared/Components/Input/Input.Style";

interface IProps extends MultiStepElementProps, ModalBodyProps {}

const CompanyData = forwardRef<ForwardedRef, IProps>((props, forwardedRef) => {
	const { equityPlansStore } = useRootStore();
	const [error, setError] = useState<string>();
	useEffect(() => {
		if (!equityPlansStore.companyData?.taxTracks?.length) return;
		setError(undefined);
	}, [equityPlansStore.companyData?.taxTracks]);
	return (
		<div className={CompanyDataStyle}>
			<span className={StepTitle}>Company Data</span>
			<hr className={StepDivider} />
			<EntitiesFactory isRegistration />
			<div className="buttonsContainer">
				<Flex justify="end" gap="1rem" margin="5rem 0 0 0" className="innerButtons">
					{!!error && (
						<Comment className={`${CompanyDataStyle}__error`} error absolute={false}>
							{error}
						</Comment>
					)}
					<Button
						qaid="Registration.Button.Back"
						label="Back"
						onClick={() => props.goTo?.(CompanyRegistrationEnum.vestingScheduleTemplates)}
						cancel
					/>

					<Button
						qaid="Registration.Button.Next"
						onClick={() => {
							if (!equityPlansStore.companyData?.taxTracks?.length) {
								return setError("Tax tracks are required");
							}
							equityPlansStore.isRegistrationCompleted = true;
							props.removeModal?.();
						}}
						label="Finish"
					/>
				</Flex>
			</div>
		</div>
	);
});

export default CompanyData;
