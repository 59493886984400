import { css } from "@emotion/css";
import { breakpoints } from "../../../config/style/theme";
import appConfig from "../../../config/config";
import OverflowText from "../OverflowText";
import { ReactElement } from "react";

export const Style = css({
    label: "DashboardText",
    display: "flex",
    flexDirection: "column",
    paddingRight: "1rem",
    flex: 1,
    // [`@media (min-width: ${breakpoints.xl})`]: {
    //     "&:not(:last-child)": {
    //         borderRight: `1px solid ${appConfig.style.colors.field}`,
    //     },
    // },
    ".title": {
        fontWeight: 700,
    },
    ".text": {
        // fontWeight: 500,
        fontSize: "1.8rem",
        whiteSpace: "nowrap",
    },
});

const DashboardText = ({ title, text }: { title: string; text?: string | ReactElement }) => {
    return (
        <div className={Style}>
            <span className="title text-ellipsis">{title}</span>
            <span className="text">{text ?? "---"}</span>
        </div>
    );
};

export default DashboardText;
