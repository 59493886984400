import { useState } from "react";
import Clickable from "../../../../../Shared/Components/Clickable/Clickable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Fade } from "@mui/material";
import appConfig from "../../../../../config/config";
import { css } from "@emotion/css";
import NewBeneficiaryDetails from "../../../Sections/Beneficiaries/NewBeneficiary/NewBeneficiaryDetails/NewBeneficiaryDetails";
import NewGrantDetails from "../../../Sections/Grants/GrantDetails/NewGrant/NewGrantDetails";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { observer } from "mobx-react-lite";

const Style = css({
    label: "Granticiary",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // overflow: "auto",
    padding: "7rem 8rem 4rem",
    "&__title": {
        fontSize: "2.5rem",
        marginBottom: "4.8rem",
        fontWeight: 500,
    },
});

interface GranticiaryProps {
	onSave: (templateId?: string) => Promise<void>;
}
const Granticiary = (props: GranticiaryProps) => {
    const { equityPlansStore } = useRootStore();

    const [showAddBnficry, setShowAddBnficry] = useState(false);
    // const [bnficryId, setBnficryId] = useState<number>()

    const handleBenCreate = async (id: number) => {
        equityPlansStore.newGrant.beneficiaryId = id;
        setShowAddBnficry(false);
        // setBnficryId(id)
        // setTimeout(() => {
        //     console.log('benId', equityPlansStore.newGrant.beneficiaryId)
        // }, 100);
    };

    return (
        <div className={Style}>
            <div className={`${Style}__title`}>Add New Grant</div>
            {showAddBnficry && <NewBeneficiaryDetails isFromGrant onCancel={() => setShowAddBnficry(false)} onSave={handleBenCreate} />}
            <NewGrantDetails
                onSave={props.onSave}
                selectBen
                onAddBen={() => setShowAddBnficry(true)}
                disabled={showAddBnficry}
                // benId={bnficryId}
            />
        </div>
    );
};
export default observer(Granticiary);
